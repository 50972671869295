import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { Subscription } from 'rxjs';
import { ConfiguracoesUsuario } from '../../../../models/usuario/ConfiguracoesUsuario';
import { Comentario } from '../../../../models/Comentario';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioComentariosService } from '../../../../services/data-services/usuario.comentarios.service';
import { SimNaoDialogComponent } from '../../../dialogs/sim-nao-dialog/sim-nao-dialog.component';

@Component({
  selector: 'app-editor-comentario',
  templateUrl: './editor-comentario.component.html',
  styleUrls: ['./editor-comentario.component.scss']
})
export class EditorComentarioComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = []
  private corComentario: string;
  private corMnemonico: string;
  private cancelled = false
  private limiteCaracteresComentario = 3000
  private limiteCaracteresMnemonico = 30

  private _texto = ''
  // texto: string = ''
  comentario: Comentario;
  editando: boolean;
  novo: boolean;
  maxWidth: number;

  @Input() set comentarioCriar(comentarioCriar: Comentario) {
    this.comentario = comentarioCriar
    this.editando = true;
    this.novo = true;
  }

  @Input() set comentarioEditar(comentarioEditar: Comentario) {
    this.comentario = comentarioEditar
    this._texto = this.comentario.texto;
    this.editando = false;
    this.novo = false;
  }

  private editorElement: HTMLDivElement = null
  @ViewChild('editor', { static: false }) set editor(editor: ElementRef) {
    this.editorElement = editor ? <HTMLDivElement>editor.nativeElement : null
    if (this.editorElement)
      // this.editorElement.focus()
      this.placeCaretAtEnd(this.editorElement)
    else
      this.editorElement = null
  }

  private viewerElement: HTMLSpanElement = null
  @ViewChild('viewer', { static: false }) set viewer(viewer: ElementRef) {
    this.viewerElement = viewer ? <HTMLSpanElement>viewer.nativeElement : null
    if (this.viewerElement) { }
    else
      this.viewerElement = null
  }

  @Output('commit') onCommited = new EventEmitter()
  @Output('cancel') onCancel = new EventEmitter()

  constructor(
    private preferenciasService: UsuarioPreferenciasService,
    private usuarioComentariosService: UsuarioComentariosService,
    private changeDetector: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  get mnemonico() {
    if (this.comentario)
      return this.comentario.mnemonico

    return null
  }

  get charLimit() {
    return this.mnemonico ? this.limiteCaracteresMnemonico : this.limiteCaracteresComentario
  }

  get color() {
    return this.mnemonico ? this.corMnemonico : this.corComentario
  }

  get textoPlaceholder() {
    return !this.mnemonico ? 'Insira o comentário' : 'Insira o mnemônico'
  }

  placeCaretAtEnd(el) {
    el.focus();
    if (typeof window.getSelection != "undefined"
      && typeof document.createRange != "undefined") {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  key_pressed(e: KeyboardEvent) {
    const ignoredKeycodes = [8, 46, 37, 38, 39, 40]
    if (!ignoredKeycodes.includes(e.keyCode) && this.tamanhoTexto >= this.charLimit) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.changeDetector.detectChanges()
  }

  enter_pressed(e: KeyboardEvent) {
    if (this.mnemonico) {
      e.preventDefault();
      e.stopPropagation();
      this.editorElement.blur()
    }
  }

  esc_pressed(e: KeyboardEvent) {
    this.cancelled = true;
    this.editorElement.blur()
  }

  ngOnInit(): void {
    this.subscriptions.push(this.preferenciasService.$Configuracoes.subscribe(c => this.configuracoes_changed(c)));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe()
    })

    this.subscriptions = []
  }

  on_paste(e: ClipboardEvent) {
    e.preventDefault()
    let clipboardData = e.clipboardData.getData('text/plain')

    if (this.mnemonico) {
      clipboardData = clipboardData.replace(/\n|\r/g, "")
    }

    this.comentario.texto += clipboardData
    this.comentario.texto = this.comentario.texto.substr(0, !this.mnemonico ? this.limiteCaracteresComentario : this.limiteCaracteresMnemonico)

    // document.execCommand('inserttext', false, clipboardData)
  }

  on_blur() {
    this.cancelled = this.cancelled || this.editorElement.innerHTML.length == 0 || (this.editorElement.innerHTML == this._texto)

    if (!this.novo)
      this.toggle_edicao()

    if (this.cancelled)
      this.cancel()
    else
      this.commit()
  }

  mouseup_edicao() {
    this.toggle_edicao()
  }

  toggle_edicao() {
    if (!this.editando)
      this.cancelled = false

    this.editando = !this.editando;
  }

  get tamanhoTexto() {
    if (!this.editorElement || !this.editorElement.innerHTML)
      return 0

    let t = this.editorElement.innerText.length
    return t
  }

  private configuracoes_changed(c: ConfiguracoesUsuario): void {
    this.corComentario = c.parametrosCaneta.corTagComentario
    this.corMnemonico = c.parametrosCaneta.corTagMnemonico
  }

  private commit() {
    if (this.editorElement) {
      const innerHTML = this.editorElement.innerHTML
      let textData = innerHTML

      this.comentario.texto = textData

      // 4 = padding atual definido no css
      this.comentario.alturaExibicao = this.editorElement.clientHeight - 4;
      this.comentario.larguraExibicao = this.editorElement.clientWidth - 4;
    }

    this.onCommited.emit(this.comentario)
  }

  private cancel() {
    this.onCancel.emit()
  }

  public confirmarDelecaoComentario(event: MouseEvent, comentario: Comentario) {
    event.preventDefault()
    event.stopPropagation()

    const tipoComentario = comentario.mnemonico ? 'mnemonico' : 'comentário'
    const dialogRef = this.dialog.open(SimNaoDialogComponent, { width: '250px', data: 'Deseja mesmo excluir o ' + tipoComentario + '?' })
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.usuarioComentariosService.remover(comentario)
      }
    });
  }
}
