import { EntitiesHelper } from '../helpers/entities.helper';
import { TipoSelecao } from '../services/selection.service';
import { BaseObj } from './UserdataSync';

export class Grifo extends BaseObj {
    id: string;
    idConjunto: string;
    idLei: string;
    idItem: string;
    idImportacao: number;
    inicio: number;
    termino: number;
    tipo: TipoSelecao;
    marcacaoDesatualizada: boolean;

    constructor() {
        super();

        this.id = EntitiesHelper.generateGuid();
    }
}