import { RangeSelecao } from './RangeSelecao';
import { EntitiesHelper } from '../helpers/entities.helper';
import { TipoSelecao } from '../services/selection.service';
import { BaseObj } from './UserdataSync';

export class Comentario extends BaseObj {
    id: string;
    idConjunto: string;
    idLei: string;
    texto: string;
    range: RangeSelecao;
    mnemonico = false;
    tipo: TipoSelecao;
    alturaExibicao: number;
    larguraExibicao: number;

    constructor() {
        super();

        this.id = EntitiesHelper.generateGuid();
        this.range = new RangeSelecao();
        this.tipo = TipoSelecao.Comentario;
    }
}
