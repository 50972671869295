import { Injectable } from '@angular/core';
import { Comentario } from '../../models/Comentario';
import { StorageHelper } from '../../helpers/storage.helper';
import { Subject } from 'rxjs';
import { SignalrService, OperacaoSignalR, EnumTipoObjeto } from '../signalr.service';
import { LoggingService } from '../logging.service';

@Injectable()
export class UsuarioComentariosService {
    readonly databaseName = 'userdata';
    readonly collectionName = 'comentarios';

    private _modificado = new Subject<Comentario>();
    public $modificado = this._modificado.asObservable();

    constructor(
        private signalrService: SignalrService,
        private loggingService: LoggingService
    ) {
        signalrService.$Mensagem.subscribe(msg => this.onMessageReceived(msg));
    }

    private onMessageReceived(msg: OperacaoSignalR): void {
        if (msg && msg.tipoObjeto === EnumTipoObjeto.Comentarios) {
            this.atualizar(msg.dados, false);
        }
    }

    public fromNuvem(comentarios: Comentario[]): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.upsertMany(comentarios, this.databaseName, this.collectionName, false).then(() => {
                onsuccess();
            }).catch(err => {
                throw err;
            });
        });
    }

    public atualizar(comentario: Comentario, sync = true): Promise<Comentario> {
        return new Promise((onsuccess, onerror) => {

            if (sync) {
                const mensagem = new OperacaoSignalR();
                mensagem.dados = comentario;
                this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Comentarios);
            }

            StorageHelper.upsert(comentario, this.databaseName, this.collectionName).then(comentario => {
                const novo = !comentario.dataHoraModificacao;

                this._modificado.next(comentario);
                onsuccess(comentario);

                // if (novo)
                this.loggingService.LogEvent((comentario.mnemonico) ? "Leitor - Comentar (mnemônico)" : "Leitor - Comentar", comentario.idLei, comentario.id);
            }).catch(err => {
                throw err;
            });
        });
    }

    public remover(comentario: Comentario): Promise<Comentario> {
        comentario.removido = true;
        return this.atualizar(comentario);
    }

    public buscarLei(idLei: string): Promise<Comentario[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Comentario>(this.databaseName, this.collectionName, ((m) => {
                return m.idLei === idLei;
            })).then(comentarios => {
                onsuccess(comentarios);
            }).catch(err => {
                throw err;
            });
        });
    }

    public buscarLinhaVersao(idLinha: string, versaoLinha: number): Promise<Comentario[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Comentario>(this.databaseName, this.collectionName, ((c) => {
                c.range.idItens.forEach(id => {
                    if (id.idItem === idLinha && id.idImportacao === versaoLinha) {
                        return true;
                    }
                });

                return false;
            })).then(marcacoes => {
                onsuccess(marcacoes);
            }).catch(err => {
                throw err;
            });
        });
    }

    public buscarLinha(idLinha: string): Promise<Comentario[]> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<Comentario>(this.databaseName, this.collectionName, ((c) => {
                c.range.idItens.forEach(id => {
                    if (id.idItem === idLinha) {
                        return true;
                    }
                });

                return false;
            })).then(marcacoes => {
                onsuccess(marcacoes);
            }).catch(err => {
                throw err;
            });
        });
    }
}
