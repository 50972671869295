import { LeiStorage } from "./lei.storage"

export class LeiConteudo {
    id: string
    dados: string

    static fromStorage(lei: LeiStorage): LeiConteudo {
        return {
            id: lei.id,
            dados: lei.dados
        }
    }
}