import { Component, OnInit, OnDestroy, Input, ɵConsole } from '@angular/core';
import { Subscription, Observable, BehaviorSubject, fromEventPattern } from 'rxjs';
import { LawArticleService, ModosOrdenacao } from './law-article.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Guia } from '../../../models/Guia';
import { Lei } from '../../../models/Lei';
import { StatusService } from '../../../services/status.service';
import { ConfiguracoesUsuario } from '../../../models/usuario/ConfiguracoesUsuario';
import { LeiLookup } from '../../../models/lei/lei.lookup';
import { UsuarioEstatisticasService } from '../../../services/data-services/usuario.estatisticas.service';
import { UsuarioGuiasService } from '../../../services/data-services/usuario.guias.service';
import { UsuarioPreferenciasService } from '../../../services/data-services/usuario.preferencias.service';
import { ItemLookupOrdenacaoNovaGuia } from '../../../models/lei/item.lookup.ordenacao.nova.guia';
import { Conteudo } from '../../../models/pagina/conteudo';
import { BuscaService } from '../../../services/busca.service';
import { BuscaPanelParameters } from '../../leitor-content-panelbusca/busca-panel.parameters';
import { ListArticlesComponent } from '../../../components/popups/list-articles/list-articles.component';
import { ReadingSpeedComponent } from '../../../components/popups/reading-speed/reading-speed.component';
import { TextoPagina, TextoPaginaFunctions } from '../../../models/pagina/TextoPagina';
import { Datasource } from 'ngx-ui-scroll';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { debounceTime, delay, tap, filter, map, takeUntil, ignoreElements } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../../dialogs/alert-dialog/alert-dialog.component';
import { ListArticleService } from "../../popups/list-articles/list-article.service";
import { EstatisticasLeitura } from "../../../models/usuario/EstatisticasLeitura";
import { LocalDataService, LocalSettings } from '../../../services/data-services/local.data.service';
import { DadosService } from '../../../services/dados.service';
import { AuthService } from '../../../modules/shared/services/auth.service';
@Component({
  selector: '[app-law-article]',
  templateUrl: './law-article.component.html',
  styleUrls: ['./law-article.component.scss']
})
export class LawArticleComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public weeks = ["domingo","segunda","terca","quarta","quinta","sexta","sabado"];
  public apiData;
  public planName = '';
  public planNameError = false;
  public documentError = false;
  public startTime;
  public endTime;
  public startDate;
  public endDate = ((new Date().getDate() <= 9) ? '0' + new Date().getDate()  : new Date().getDate())  + '/' + ((new Date().getMonth() <= 9) ? '0' + (new Date().getMonth() + 1)  : (new Date().getMonth() + 1)) + '/' + new Date().getFullYear();
  public reading_speed_btn_text = "Calcule minha velocidade de leitura";
  public show_reading_bar = false;
  public listFilterCtrl: FormControl = new FormControl();
  public listCtrl: FormControl = new FormControl();
  public listSelected = "";
  public searching: boolean = false;
  public total_custom_number_words = 0;
  public total_custom_number_lines = 0;
  public total_custom_read_lines = 0;
  public total_number_words = 0;
  public total_schedule_hours = 0;
  public total_per_day_hours = [2,2.30,3,0,5,4,2.45];
  protected _onDestroy = new Subject<void>();
  public filteredlists: ReplaySubject<LeiLookup[]> = new ReplaySubject<LeiLookup[]>(1);
  public id = '';   
  public documentlist:any =[];
  public customSelectionDocumentlist:any =[];
  isCheckboxSelected:boolean=false;
  public subTitle = '';
  public isCustom = false;
  localData: LocalSettings;

  @Input() linha: TextoPagina;

  public hours = [];
  public lastAddedTimeLog = {"domingo" :[],"segunda" :[],"terca" :[],"quarta" :[],"quinta" :[],"sexta" :[],"sabado" :[]};
  public reverseAddedTimeLog = {"domingo" :[],"segunda" :[],"terca" :[],"quarta" :[],"quinta" :[],"sexta" :[],"sabado" :[]};
  public lastAddedIndex = {"domingo" :-1,"segunda" :-1,"terca" :-1,"quarta" :-1,"quinta" :-1,"sexta" :-1,"sabado" :-1};
  public time_log: any = {
    "domingo": [],
    "segunda": [],
    "terca": [],
    "quarta": [],
    "quinta": [],
    "sexta": [],
    "sabado": []
  };
  date = new FormControl(new Date());
  serializedDate = new FormControl((new Date()).toISOString());
  linhasLoading: number;
  configuracoes: ConfiguracoesUsuario
  guiaAtiva: Guia;
  leiAtiva: Lei;
  opcoesModoOrdenacao = ModosOrdenacao
  Leis: Lei[] =[];
  listaLeis: Observable<LeiLookup[]>
  filtrarFavoritos: Observable<boolean>;
  modoOrdenacao: Observable<ItemLookupOrdenacaoNovaGuia>;
  painelBuscaAberto: boolean;
  carregando: boolean;

  txtBusca: string
  modoOffline: boolean;
  selectArticleRefDialogo: MatDialogRef<ListArticlesComponent>;
  readingSpeedRefDialogo: MatDialogRef<ReadingSpeedComponent>;
  public conteudo: Conteudo;
  public indexPrimeiroItem: number;
  public utlimoItemFocoId: string;
  public ultimoTitulo: string;
  private quantidadeItensBuffer = 35;
  public per_min_words = 80;
  public reading_step = 1;
  public RevogadoEstatisticas = false;
  public readingSpeedData:any = [];
  public studyPlanData:any = [];
  public docReadCount:any = [];
  public documentDetails:any = [];


  constructor(
    private authenticationService: AuthService,
    private lawArticleService: LawArticleService,
    private statusService: StatusService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioGuiasService: UsuarioGuiasService,
    private ListArticleService: ListArticleService,
    private buscaService: BuscaService,
    public selectArticledialog: MatDialog,
    public readingSpeeddialog: MatDialog,
    private dialog: MatDialog,
    private localDataService: LocalDataService,
    private dadosService: DadosService,
  ) {
      const configuracoes = this.usuarioPreferenciasService.Configuracoes;
      this.per_min_words = configuracoes.palavrasMinuto;      
      var time = [];
      var mins = ['00','15','30','45']
      for(var i = 0; i <= 23; i++) {
        for(var j = 0; j< mins.length; j++) {
          var concat_time = '';
          if(i <= 9) {
            concat_time = '0' + i + ':' + mins[j];
          } else {
            concat_time = i + ':' + mins[j];
          }
          time.push(concat_time)
        }
      }
      this.hours = time;
  }

  addTimelog(dayName) {
    var flg = 0;
    for(var i = 0; i < this.time_log[dayName].length; i++) {
      if(this.time_log[dayName][i]['t1'] == '' || this.time_log[dayName][i]['t2'] == '') {
        flg = 1;
      }
    }
    if(flg == 0) {
      if(this.time_log[dayName].length == 0) {
        this.time_log[dayName][this.time_log[dayName].length] = {"id" : '', "t1":"","t2":"", "total_mins": ""};      
      } else {
        this.time_log[dayName][this.time_log[dayName].length] = {"id" : '', "t1":"","t2":"", "total_mins": ""};      
      }
      if(this.lastAddedTimeLog[dayName].length <= 1) {        
        this.lastAddedTimeLog[dayName].push({"t1" : -1,"t2":-1});       
      } 
      this.reverseAddedTimeLog[dayName].push({"t1" : this.hours.length,"t2":this.hours.length});
    } else {
      const alertDialog = this.dialog.open(AlertDialogComponent, {
        data: {
          title: '',
          message: 'Por favor, preencha o intervalo de tempo vazio para ' + dayName 
        }
      });
    }
  }

  timeChange(e, dayName, i, field) {
    this.time_log[dayName][i][field] = e.target.value;
    var time_logs = this.time_log;  
    time_logs['domingo'].forEach((ele, index) => {
        var s_time = ele['t1'];
        var e_time = ele['t2'];        
        if(ele['t2'] == "00:00" || ele['t2'] == "") {
          e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
          time_logs['domingo'][index]['t2'] = e_time;
        }
        time_logs['domingo'][index]['total_mins'] = this.getAllMins(s_time, e_time);
        if(this.lastAddedTimeLog['domingo'][index]["t1"]  == -1) {
          this.lastAddedTimeLog['domingo'][index]["t2"] = this.hours.indexOf(ele['t1']);
        } else {
          this.lastAddedTimeLog['domingo'][index]["t2"] = this.lastAddedTimeLog['domingo'][index]["t1"] + 1;
          // this.lastAddedTimeLog['domingo'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
        }
        if(ele['t2'] == "00:00" || ele['t2'] == "") {
          this.lastAddedTimeLog['domingo'][index + 1] = {"t1" : this.lastAddedTimeLog['domingo'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['domingo'][index]["t2"] + 1};  
        } else {
          this.lastAddedTimeLog['domingo'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
        }
        if((time_logs['domingo'].length - 1) === index) {
          this.reverseAddedTimeLog['domingo'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
        } else {
          this.reverseAddedTimeLog['domingo'][index] = ({"t1" : this.hours.indexOf(time_logs['domingo'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['domingo'][index + 1]['t1'])});
        }
    });    
    time_logs['segunda'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['segunda'][index]['t2'] = e_time;
      }
      time_logs['segunda'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['segunda'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['segunda'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['segunda'][index]["t2"] = this.lastAddedTimeLog['segunda'][index]["t1"] + 1;
        // this.lastAddedTimeLog['segunda'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
        
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['segunda'][index + 1] = {"t1" : this.lastAddedTimeLog['segunda'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['segunda'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['segunda'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      }
      if((time_logs['segunda'].length - 1) === index) {
        this.reverseAddedTimeLog['segunda'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['segunda'][index] = ({"t1" : (time_logs['segunda'][index + 1]['t1'] == '') ? this.hours.length : (this.hours.indexOf(time_logs['segunda'][index + 1]['t1'])), "t2" : (time_logs['segunda'][index + 1]['t1'] == '') ? this.hours.length : (this.hours.indexOf(time_logs['segunda'][index + 1]['t1'])) });
      }
    });
    time_logs['terca'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['terca'][index]['t2'] = e_time;
      }
      time_logs['terca'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['terca'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['terca'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['terca'][index]["t2"] = this.lastAddedTimeLog['terca'][index]["t1"] + 1;
        // this.lastAddedTimeLog['terca'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['terca'][index + 1] = {"t1" : this.lastAddedTimeLog['terca'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['terca'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['terca'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      } 
      if((time_logs['terca'].length - 1) === index) {
        this.reverseAddedTimeLog['terca'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['terca'][index] = ({"t1" : this.hours.indexOf(time_logs['terca'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['terca'][index + 1]['t1'])});
      } 
    });
    time_logs['quarta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['quarta'][index]['t2'] = e_time; 
      }
      time_logs['quarta'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['quarta'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['quarta'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['quarta'][index]["t2"] = this.lastAddedTimeLog['quarta'][index]["t1"] + 1;
        // this.lastAddedTimeLog['quarta'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['quarta'][index + 1] = {"t1" : this.lastAddedTimeLog['quarta'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['quarta'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['quarta'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      }
      if((time_logs['quarta'].length - 1) === index) {
        this.reverseAddedTimeLog['quarta'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['quarta'][index] = ({"t1" : this.hours.indexOf(time_logs['quarta'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['quarta'][index + 1]['t1'])});
      }
    });
    time_logs['quinta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['quinta'][index]['t2'] = e_time;
      }
      time_logs['quinta'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['quinta'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['quinta'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['quinta'][index]["t2"] = this.lastAddedTimeLog['quinta'][index]["t1"] + 1;
        // this.lastAddedTimeLog['quinta'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['quinta'][index + 1] = {"t1" : this.lastAddedTimeLog['quinta'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['quinta'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['quinta'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      }

      if((time_logs['quinta'].length - 1) === index) {
        this.reverseAddedTimeLog['quinta'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['quinta'][index] = ({"t1" : this.hours.indexOf(time_logs['quinta'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['quinta'][index + 1]['t1'])});
      }

    });
    time_logs['sexta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['sexta'][index]['t2'] = e_time;
      }
      time_logs['sexta'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['sexta'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['sexta'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['sexta'][index]["t2"] = this.lastAddedTimeLog['sexta'][index]["t1"] + 1;
        // this.lastAddedTimeLog['sexta'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['sexta'][index + 1] = {"t1" : this.lastAddedTimeLog['sexta'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['sexta'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['sexta'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      }
      if((time_logs['sexta'].length - 1) === index) {
        this.reverseAddedTimeLog['sexta'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['sexta'][index] = ({"t1" : this.hours.indexOf(time_logs['sexta'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['sexta'][index + 1]['t1'])});
      }

    });
    time_logs['sabado'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        e_time = this.hours[this.hours.indexOf(ele['t1']) + 1];        
        time_logs['sabado'][index]['t2'] = e_time;
      }
      time_logs['sabado'][index]['total_mins'] = this.getAllMins(s_time, e_time);
      if(this.lastAddedTimeLog['sabado'][index]["t1"]  == -1) {
        this.lastAddedTimeLog['sabado'][index]["t2"] = this.hours.indexOf(ele['t1']);
      } else {
        this.lastAddedTimeLog['sabado'][index]["t2"] = this.lastAddedTimeLog['sabado'][index]["t1"] + 1;
        // this.lastAddedTimeLog['sabado'][index]["t2"] = this.hours.indexOf(ele['t1']) + 1;
      }
      if(ele['t2'] == "00:00" || ele['t2'] == "") {
        this.lastAddedTimeLog['sabado'][index + 1] = {"t1" : this.lastAddedTimeLog['sabado'][index]["t2"] + 1, "t2" : this.lastAddedTimeLog['sabado'][index]["t2"] + 1};  
      } else {
        this.lastAddedTimeLog['sabado'][index + 1] = {"t1" : this.hours.indexOf(ele['t2']), "t2" : this.hours.indexOf(ele['t2'])};
      }

      if((time_logs['sabado'].length - 1) === index) {
        this.reverseAddedTimeLog['sabado'][index] = ({"t1" : this.hours.length, "t2" : this.hours.length});
      } else {
        this.reverseAddedTimeLog['sabado'][index] = ({"t1" : this.hours.indexOf(time_logs['sabado'][index + 1]['t1']), "t2" : this.hours.indexOf(time_logs['sabado'][index + 1]['t1'])});
      }
    });
    this.time_log = time_logs;
    this.finalDateCalc();  
  }

  finalDateCalc() {
    var plus_days = -1;
    var d_list = this.documentlist;
    const configuracoes = this.usuarioPreferenciasService.Configuracoes;
    const palavrasMinuto = configuracoes.palavrasMinuto;        
    var total_doc_rem_mins = 0;
    var total_doc_sec = 0;
    if(d_list.length > 0 ){
      d_list.forEach((e,i) => {
        var calculateResultChild;
        var calculateResultChildHr;
        var read_cnt = 0;
        if(d_list[i].id == null) {
          if(d_list[i].EstudoParcial && !d_list[i].c_flag) {
            read_cnt = 0;
          } else {
            read_cnt = d_list[i].totalMarcaLinhas;
          }
        } else {
          if(d_list[i].EstudoParcial && !d_list[i].c_flag) {
            read_cnt = 0;
          } else {
            read_cnt = d_list[i].totalMarcaLinhas;
          }
        }
        calculateResultChild = EstatisticasLeitura.CalcularProgresso(d_list[i].totalLinhas, read_cnt);
        calculateResultChildHr = EstatisticasLeitura.CalcularTempoRestante( palavrasMinuto, calculateResultChild,d_list[i].totaisPalavras);
        total_doc_rem_mins = total_doc_rem_mins + calculateResultChildHr['minutos'];
        total_doc_rem_mins = total_doc_rem_mins + (calculateResultChildHr['horas'] * 60);
        total_doc_sec = total_doc_sec + calculateResultChildHr['segundos'];
      });
    }
    // total_doc_rem_mins = total_doc_rem_mins + 1
    plus_days = this.recursiveTimeCheck(-1, total_doc_rem_mins, 0);
    var e_date = new Date(this.serializedDate.value);
    this.date = new FormControl(new Date(this.serializedDate.value));
    this.serializedDate = new FormControl(new Date(this.serializedDate.value));
    if(plus_days == -1) {
      plus_days = 0;
    }
    e_date.setDate(e_date.getDate() + plus_days)
    this.endDate = ((e_date.getDate() <= 9) ? '0' + e_date.getDate()  : e_date.getDate())  + '/' + (((e_date.getMonth() + 1) <= 9) ? '0' + (e_date.getMonth() + 1)  : (e_date.getMonth() + 1)) + '/' + e_date.getFullYear();
  }

  recursiveTimeCheck(plus_days = -1, total_doc_rem_mins, flg = 0) {    
    var time_logs= this.time_log;
    var in_input = this.timelogValidation();
    var e_date = new Date(this.serializedDate.value);
    var day = e_date.getDay();
    var start_day_array = [];
    for(var i = day - 1; i <= 6; i++) {
      start_day_array.push(i);
    }
    var day_arr = ["domingo","segunda","terca","quarta","quinta","sexta","sabado"];
    for(var i = 0; i < day - 1; i++) {
      start_day_array.push(i);
    }
    start_day_array.forEach(element => {
      if(total_doc_rem_mins > 0) {
        var day_sum_mins = 0;
        var day_name = day_arr[element];
        time_logs[day_name].forEach((ele, index) => {
          day_sum_mins = day_sum_mins + ele['total_mins'];
        });
        if(day_sum_mins > 0) {          
          total_doc_rem_mins = total_doc_rem_mins - day_sum_mins
        }
        if(total_doc_rem_mins > 0 && in_input != false) {
          plus_days++;
        }
      }
    });    
    if(total_doc_rem_mins != 0 && total_doc_rem_mins > -1) {                        
      if(plus_days == -1) {
        plus_days = 0;
        return plus_days;  
      } else {
        if(flg == 1) {
          total_doc_rem_mins = 0;
        }
        return this.recursiveTimeCheck(plus_days, total_doc_rem_mins, flg)
      }
    } else if(total_doc_rem_mins == -1) {
      return plus_days;
    } else {
      return plus_days;
    }

  }

  pickerChange(val) {
    var converted_date = val;      
    converted_date = converted_date.replace('/',"-");
    converted_date = converted_date.replace('/',"-");
    var newdate = converted_date.split("-").reverse().join("-");
    this.date = new FormControl(new Date(newdate));
    this.serializedDate = new FormControl((new Date(newdate)).toISOString());
    var tthis = this;
    setTimeout(function() {
      tthis.finalDateCalc();  
    }, 200);
    
  }
  
  getAllMins(t1, t2) {
    var timeStarthrs= (new Date("01/01/2007 " + t1).getHours()) * 60;
    var timeEndhrs = (new Date("01/01/2007 " + t2).getHours()) * 60;  
    var timeStartmins = new Date("01/01/2007 " + t1).getMinutes();  
    var timeEndmins = new Date("01/01/2007 " + t2).getMinutes(); 
    var start_total_mins  = timeStarthrs + timeStartmins;
    var end_total_mins  = timeEndhrs + timeEndmins;
    var diff_mins = 0;
    if(end_total_mins <= 0) {
     diff_mins = start_total_mins;
    } else if(start_total_mins <= 0) {
      diff_mins = end_total_mins;
    } else {
      diff_mins = end_total_mins - start_total_mins;
    }
    return diff_mins;
  }

  removeTimelog(dayName,i) {
    this.time_log[dayName].splice(i, 1);    
    this.lastAddedTimeLog[dayName].splice(i, 1);
    var min_i = i - 1;
    if(min_i > 0) {      
      this.reverseAddedTimeLog[dayName].splice(min_i, 1);
    } else {
      this.lastAddedTimeLog[dayName][0]['t1'] = -1;
      this.reverseAddedTimeLog[dayName].splice(0, 1);
    }
    this.finalDateCalc();
  }

  removeDocument(documentObj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: '',
        message: 'Tem certeza de que deseja remover um documento? ' 
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.documentlist.forEach((eled, indexd) => {
          if(eled.idLei === documentObj) {
            this.documentlist.splice( indexd, 1 );
            var tthis = this;
            setTimeout(() => {
              tthis.finalDateCalc();  
            }, 100);
            
          }
        });
      }
    });
  }


  inputValidation() {
    if(this.planName.trim() == '') {
      this.planNameError = true;
    } else {
      this.planNameError = false;
    }
    
    if(this.listSelected == '') {
      this.documentError = true;
    } else {
      this.documentError = false;
    }
    // this.subTitle = '';
  }
  timelogValidation() {
    var time_logs = this.time_log;
    var in_input = true;
    if(time_logs['domingo'])
    time_logs['domingo'].forEach((ele, index) => {
        var s_time = ele['t1'];
        var e_time = ele['t2']; 
        if(s_time == '' || e_time == ''){
          in_input = false;
        }
    });    
    time_logs['segunda'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      }
    });
    time_logs['terca'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      } 
    });
    time_logs['quarta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      }
    });
    time_logs['quinta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      }

    });
    time_logs['sexta'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      }

    });
    time_logs['sabado'].forEach((ele, index) => {
      var s_time = ele['t1'];
      var e_time = ele['t2'];        
      if(s_time == '' || e_time == ''){
        in_input = false
      }
    });
    if(this.time_log['domingo'].length <= 0 && this.time_log['segunda'].length <= 0 && this.time_log['terca'].length <= 0 && this.time_log['quarta'].length <= 0 && this.time_log['quinta'].length <= 0 && this.time_log['sexta'].length <= 0 && this.time_log['sabado'].length <= 0) {
      in_input = false;
    }
    return in_input;
  }
  savePlanConfirm() {
    // (this.time_log['domingo'].length > 0 || this.time_log['segunda'].length > 0 || this.time_log['terca'].length > 0 || this.time_log['quarta'].length > 0 || this.time_log['quinta'].length > 0 || this.time_log['sexta'].length > 0 || this.time_log['sabado'].length > 0)
    var in_input = this.timelogValidation();
    if(this.planName.trim() != '' && this.documentlist.length > 0 && in_input) {
        const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: '',
            message: 'Os dados de leitura existentes das leis vinculadas à esse plano de estudo serão zeradas, deseja continuar? ' 
          }
        });
        confirmDialog.afterClosed().subscribe(result => {
          if (result === true) {
            this.RevogadoEstatisticas = true;
            this.saveStudyPlan();
          } else {
            this.RevogadoEstatisticas = false;
            var d_list = this.documentlist;
            if(d_list.length > 0 ){
              d_list.forEach((e,i) => {
                if(d_list[i].EstudoParcial) {
                  this.documentlist[i]['c_flag'] = true;
                } else {
                  this.documentlist[i]['c_flag'] = false;
                }
              });
            }
            this.finalDateCalc();
            this.saveStudyPlan();
          }
        });
    } else {
      if(this.planName.trim() == '') {
        this.planNameError = true;
      } else {
        this.planNameError = false;
      }
      
      if(this.listSelected == '') {
        this.documentError = true;
      } else {
        this.documentError = false;
      }

      if(this.documentlist.length <= 0) {
        const alertDialog = this.dialog.open(AlertDialogComponent, {
          data: {
            title: '',
            message: 'Selecione pelo menos um documento para salvar o plano de estudo.' 
          }
        });
      }
      // if((this.time_log['domingo'].length <= 0 || this.time_log['segunda'].length <= 0 || this.time_log['terca'].length <= 0 || this.time_log['quarta'].length <= 0 || this.time_log['quinta'].length <= 0 || this.time_log['sexta'].length <= 0 || this.time_log['sabado'].length <= 0)) {
      if(!in_input) {
        const alertDialog = this.dialog.open(AlertDialogComponent, {
          data: {
            title: '',
            message: 'O plano de estudos deve ter pelo menos um dia com intervalo de tempo, mas também permitir muitos dias com vários intervalos de tempo.' 
          }
        });
      }
    }
  }

  changeDropdownVal() {
    setTimeout(() => {
      var doc_details;
      var selected_leiItens = [];
      doc_details = this.ListArticleService.getlist(this.listSelected).then(
        listData => {                     
          if(listData.leiItens.length > 0) {         
            listData.leiItens.forEach((ele, index) => {
              selected_leiItens.push({"IdLeiItens" : ele.id, "totaisPalavras" : ele.totaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.artigoDe, 'artigoAte': ele.artigoAte});
              if(ele.leiItens.length > 0) {
                ele.leiItens.forEach((ele1, index1) => {
                  selected_leiItens.push({"IdLeiItens" : ele1.id, "totaisPalavras" : ele1.totaisPalavras, 'totalLinhas' : ele1.totalLinhas, 'artigoDe': (ele1.artigoDe == "") ? ele1.prefixo : ele1.artigoDe, 'artigoAte': ele1.prefixo});
                  if(ele1.leiItens.length > 0) {
                    ele1.leiItens.forEach((ele2, index2) => {
                      selected_leiItens.push({"IdLeiItens" : ele2.id, "totaisPalavras" : ele2.totaisPalavras, 'totalLinhas' : ele2.totalLinhas, 'artigoDe': (ele2.artigoDe == "") ? ele2.prefixo : ele2.artigoDe, 'artigoAte': ele2.prefixo});
                    });
                  }
                });
              }
            });
              var end_ele = '';
              var start_ele = '';
              selected_leiItens.forEach((ele,index) => {
                if(ele.artigoDe != '' && start_ele == '') {
                  start_ele = ele.artigoDe;
                }
              });
              selected_leiItens.forEach((ele,index) => {
                if(ele.artigoAte != '') {
                    end_ele = ele.artigoAte;
                }
              });
              if(start_ele == null) {
                start_ele = '';
              }
              if(end_ele == null) {
                end_ele = '';
              }
              if(listData.tipoDocumento == 1) {
                this.subTitle = '';
              } else {
                this.subTitle = 'Estudar de ' + start_ele + ' à ' + end_ele;
              }
              this.isCustom = false;
              // this.customSelectionDocumentlist = [];
          }
        },        
        err => console.log(err),
      );
    }, 100);
    
  }
  selectArticleDialog(event) {  
    console.log("clicked");
    
    if(this.listSelected != '') {
      this.isCustom = true;
      if(event.target.checked == true){
        this.selectArticleRefDialogo = this.selectArticledialog.open(ListArticlesComponent,{data:this.listSelected});

        this.selectArticleRefDialogo.afterClosed().subscribe(result => {
          if(result != '') {
            console.log(result);
            this.total_custom_number_words = result.totalWordCount;
            this.total_custom_number_lines = result.totalLineCount;
            this.total_custom_read_lines = result.totalMarcaLinhas;
            this.customSelectionDocumentlist = result.PlanodeeStudoLeiItens;
            if(this.customSelectionDocumentlist.length > 0) {
              var start_ele = '';
              this.customSelectionDocumentlist.forEach((ele,index) => {
                if(start_ele == '') {   
                  if(result.docType == 0) {
                    start_ele = ele.artigoDe;
                    if(start_ele == '' || start_ele == null) {
                      start_ele = ele.artigoAte;
                    }
                  } else {
                    start_ele = ele.artigoAte;
                    if(start_ele == '' || start_ele == null) {
                      start_ele = ele.artigoDe;
                    }
                  }
                  
                }
              });
              if(start_ele == '') {
                this.customSelectionDocumentlist.forEach((ele,index) => {
                  if(ele.artigoAte != '' && start_ele == '') {
                    start_ele = ele.artigoAte;
                  }
                });        
              }
              var end_ele = '';
              this.customSelectionDocumentlist.forEach((ele,index) => {
                if(ele.artigoAte != '') {
                    end_ele = ele.artigoAte;
                }
              });              
              if(end_ele == '') {
                this.customSelectionDocumentlist.forEach((ele,index) => {
                  if(ele.artigoDe != '') {
                      end_ele = ele.artigoDe;
                  }
                });
              }
              if(start_ele == null) {
                start_ele = '';
              }
              if(end_ele == null) {
                end_ele = '';
              }
              if(result.docType == 1) {
                this.subTitle = '';
              } else {
                this.subTitle = 'Estudar de ' + start_ele + ' à ' + end_ele;
              }
              
              var doc_details;
              if(this.customSelectionDocumentlist.length > 0) {
                var ids = [];
                this.customSelectionDocumentlist.forEach((es) => {
                  ids.push(es.IdLeiItens);
                });
                const userId = this.authenticationService.userID
                var request_data = {"id": this.listSelected, "UserId": userId, "Itens" : ids}
                doc_details = this.ListArticleService.getReadCount(request_data).then(
                  countData => {  
                    this.total_custom_number_words = countData.totaisPalavras;
                    this.total_custom_number_lines = countData.totalLinhas;
                    this.total_custom_read_lines = countData.totalMarcaLinhas;
                });
              }
            } else {
              this.subTitle = '';
            }
          } else {
            this.customSelectionDocumentlist = [];
            this.total_custom_number_words = 0;
            this.total_custom_number_lines = 0;
            this.total_custom_read_lines = 0;
          }
        });
      } else {
        // this.subTitle = '';
      }
    } else {
      this.isCustom = false;
      const alertDialog = this.dialog.open(AlertDialogComponent, {
        data: {
          title: '',
          message: 'Selecione um documento.' 
        }
      });
      setTimeout(() => {
        console.log("fsdfsd");
        event.target.checked = false;
        // (<HTMLInputElement>document.getElementById('study')).click();
        // document.getElementById('study').checked = false;
        // link.click();  
      }, 100);
      
     
  }
  }
  readingSpeedDialog() {    
    if(this.readingSpeedData != '') {
      this.readingSpeedRefDialogo = this.readingSpeeddialog.open(ReadingSpeedComponent, {
          width: "800px",
          data: this.readingSpeedData,
      });

      this.readingSpeedRefDialogo.afterClosed().subscribe(result => {
        if(result != '') {
          this.per_min_words = parseInt(result);
          // this.per_min_words = 80;
          this.show_reading_bar = true;      
          if(result <= 25) {
            this.reading_step = 0;
          } else if(result >= 26 && result <= 50) {
            this.reading_step = 1;
          } else if(result >= 51 && result <= 75) {
            this.reading_step = 2;
          } else if(result >= 76 && result <= 100) {
            this.reading_step = 3;
          } else if(result >= 101 && result <= 125) {
            this.reading_step = 4;
          } else {
            this.reading_step = 5;
          }
          this.reading_speed_btn_text = 'Recalcular';
          this.finalDateCalc();
        }
      });
    }
  }

  public ngOnInit(): void {
   
    this.lawArticleService.getDataFronApi().subscribe(data => {
          this.apiData = data;
    })
  
    // this.listCtrl.setValue(this.apiData[2]);
    this.listFilterCtrl.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(200),
        map(search => {
          if (!this.apiData) {
            return [];
          }
          var s_string = search;          
          var return_array = [];
          if(s_string === 'Decreto nº 592, de 06.07.1192 - Pacto Internacional sobre Direitos Civis e Políticos - Dec. Nº 592/1992') {
              s_string = 'Decreto nº 592, de 06.07.1192 - Pacto Internacional sobre Direitos Civis e Políticos - Dec. Nº 592/1992';
          }          
          s_string = s_string.toLowerCase();
          this.apiData.forEach(element => {
            var e = element['titulo'].toLowerCase();
            if(e.includes(s_string)) {
              return_array.push(element);
            }
          });
          return return_array;
        }),
        delay(500)
      )
      .subscribe(filteredlists => {
        this.searching = false;
        this.filteredlists.next(filteredlists);
      },
        error => {
         
          this.searching = false;
         
        });

    

    this.listaLeis = this.lawArticleService.Leis

    this.filtrarFavoritos = this.lawArticleService.FiltrarFavoritos
    this.modoOrdenacao = this.lawArticleService.ModoOrdenacao

    this.subscriptions.push(this.lawArticleService.EventosLoading.subscribe(c => { if (!c){ return; } this.carregando =  c.length > 0 }))
    // this.subscriptions.push(this.lawArticleService.Leis.subscribe(g => console.log(g)))
    this.subscriptions.push(this.usuarioGuiasService.$GuiaAtiva.subscribe(g => this.guiaAtiva = g))
    this.subscriptions.push(this.lawArticleService.TextoBusca.subscribe(txt => this.txtBusca = txt))
    this.subscriptions.push(this.statusService.$AppOffline.subscribe(offline => {
      this.modoOffline = offline
    }))

    setTimeout(() => {
      const div = document.querySelector('.background-animation')
      if (div) {
        this.linhasLoading = Math.floor(div.clientHeight / 57)
      }
    }, 100);

    this.lawArticleService.carregarLeis(false)

    this.readingSpeedData= this.ListArticleService.getReadingModel().then(
      listData => { this.readingSpeedData = listData;},
       err => console.log(err),
    )
    var tthis = this;
    this.studyPlanData = this.ListArticleService.getStudyPlans().then(
      listData => { this.studyPlanData = listData.planodeeStudo;
      this.docReadCount = listData.documentoProgressoRelatorios;         
      this.planName = this.studyPlanData.estudoNomedoplano;
      this.id = this.studyPlanData.id;
      // this.per_min_words = this.studyPlanData.noofWords;
      const configuracoes = this.usuarioPreferenciasService.Configuracoes;
      this.per_min_words = configuracoes.palavrasMinuto;
      // this.per_min_words = 80;
      this.endDate = this.studyPlanData.dadosFinais;
      this.documentlist = this.studyPlanData.planodeeStudoDocumentos;
      var d_list = this.documentlist;
      var t_cnt = 0;
      if(d_list.length > 0) {
        d_list.forEach((e,i) => {
          t_cnt = t_cnt + e.totaisPalavras;
          this.documentlist[i]['totalMarcaLinhas'] = this.docReadCount[i].totalMarcaLinhas
        });
      }
      this.total_number_words = t_cnt;
      var converted_date = this.studyPlanData.comecaas;      
      converted_date = converted_date.replace('/',"-");
      converted_date = converted_date.replace('/',"-");
      var newdate = converted_date.split("-").reverse().join("-");
      this.date = new FormControl(new Date(newdate));
      this.serializedDate = new FormControl(new Date(newdate));
      var added_time_log = this.studyPlanData.planodeestudoDiaeHoras;
      var time_logs = [];
      var lastAddedTime_Log = {"domingo" :[],"segunda" :[],"terca" :[],"quarta" :[],"quinta" :[],"sexta" :[],"sabado" :[]};
      var reverseAddedTime_Log = {"domingo" :[],"segunda" :[],"terca" :[],"quarta" :[],"quinta" :[],"sexta" :[],"sabado" :[]};
      if(added_time_log.length > 0) {
        added_time_log.forEach(function(ele, index) {
          // time_logs[ele.dia]['id'] = ele.id;
          time_logs[ele.dia] = [];
          var logs = ele.planodeeStudoHoras
          if(logs.length > 0) {
              logs.forEach(function(ele1, index1) {                
                if(ele1.horadeInício !== undefined && ele1.fimdoTempo !== undefined) {
                  var l = {"id" : ele1.id, "t1" : ele1.horadeInício, "t2" : ele1.fimdoTempo, "total_mins" : tthis.getAllMins(ele1.horadeInício, ele1.fimdoTempo)}
                  time_logs[ele.dia].push(l);
                  if((logs.length - 1) === index1) {
                    reverseAddedTime_Log[ele.dia][index1] = ({"t1" : tthis.hours.length, "t2" : tthis.hours.length});
                  } else {
                    reverseAddedTime_Log[ele.dia][index1] = ({"t1" : tthis.hours.indexOf(logs[index1 + 1]['horadeInício']), "t2" : tthis.hours.indexOf(logs[index1 + 1]['horadeInício'])});
                  }
                  
                  if(index1 == 0) {
                      lastAddedTime_Log[ele.dia][index1] = ({"t1" : -1, "t2" : tthis.hours.indexOf(ele1.horadeInício)});
                      lastAddedTime_Log[ele.dia][index1 + 1] = ({"t1" : tthis.hours.indexOf(ele1.fimdoTempo), "t2" : tthis.hours.indexOf(ele1.fimdoTempo)});
                  } else {
                      lastAddedTime_Log[ele.dia][index1]["t2"] = tthis.hours.indexOf(ele1.horadeInício);
                      lastAddedTime_Log[ele.dia][index1 + 1] = ({"t1" : tthis.hours.indexOf(ele1.fimdoTempo), "t2" : tthis.hours.indexOf(ele1.fimdoTempo)});
                  }
                }
              });
          }
        });        
      }  
      this.time_log = time_logs;
      this.lastAddedTimeLog = lastAddedTime_Log;
      this.reverseAddedTimeLog = reverseAddedTime_Log;
      },
       err => console.log(err),
    )    
  }

  conteudo_changed(c: Conteudo): void {
    this.painelBuscaAberto = (c && c.busca) ? true : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    const taskname = 'carregar-menu';
    this.statusService.ocultarMensagemProgresso(taskname);
  }

  public txtBusca_Change(): void {
    this.lawArticleService.alterarTextoBusca(this.txtBusca);
  }

  public toggleFiltroFavoritos() {
    this.lawArticleService.favoritos_toggle();
  }

  public alterarModoOrdenacao(novoModo: ItemLookupOrdenacaoNovaGuia): void {
    this.lawArticleService.alterarModoOrdenacao(novoModo);
  }

  public abrirBusca(): void {
    if (!this.painelBuscaAberto) {
      this.buscaService.abrirPainelBusca(null);
      const params = new BuscaPanelParameters();
      params.textoBuscar = this.txtBusca;
      params.buscarTodosDocumentos = false;
    }
  }

  public selecionar(item: LeiLookup): void {
    this.usuarioGuiasService.alterarConteudoGuia(this.guiaAtiva.id, item);
  }

  public async favoritoAlterardo(item: LeiLookup) {
    const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(item.id)
    item.favorita = estatisticas.favorito
  }

  public timeSlotFormat() {
    var time_logs = this.time_log;
    var response = [];
    var domingoData = {};
    domingoData["id"] = '';
    domingoData["dia"] = 'domingo';
    domingoData["planodeeStudoHoras"] = [];
    time_logs['domingo'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = ele['id'];
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        domingoData["planodeeStudoHoras"].push(time_data);
    });
    response.push(domingoData);

    var segundaData = {};
    segundaData["id"] = '';
    segundaData["dia"] = 'segunda';
    segundaData["planodeeStudoHoras"] = [];
    time_logs['segunda'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = '';
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        segundaData["planodeeStudoHoras"].push(time_data);
    });
    response.push(segundaData);

    var tercaData = {};
    tercaData["id"] = '';
    tercaData["dia"] = 'terca';
    tercaData["planodeeStudoHoras"] = [];
    time_logs['terca'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = '';
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        tercaData["planodeeStudoHoras"].push(time_data);
    });
    response.push(tercaData);

    var quartaData = {};
    quartaData["id"] = '';
    quartaData["dia"] = 'quarta';
    quartaData["planodeeStudoHoras"] = [];
    time_logs['quarta'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = '';
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        quartaData["planodeeStudoHoras"].push(time_data);
    });
    response.push(quartaData);

    var quintaData = {};
    quintaData["id"] = '';
    quintaData["dia"] = 'quinta';
    quintaData["planodeeStudoHoras"] = [];
    time_logs['quinta'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = '';
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        quintaData["planodeeStudoHoras"].push(time_data);
    });
    response.push(quintaData);

    var sextaData = {};
    sextaData["id"] = '';
    sextaData["dia"] = 'sexta';
    sextaData["planodeeStudoHoras"] = [];
    time_logs['sexta'].forEach((ele, index) => {
        var time_data = {};
        time_data["id"] = '';
        time_data["horadeInício"] = ele['t1'];
        time_data["fimdoTempo"] = ele['t2'];
        sextaData["planodeeStudoHoras"].push(time_data);
    });
    response.push(sextaData);

    var sabadoData = {};
    sabadoData["id"] = '';
    sabadoData["dia"] = 'sabado';
    sabadoData["planodeeStudoHoras"] = [];
    time_logs['sabado'].forEach((ele, index) => {
      var time_data = {};
      time_data["id"] = '';
      time_data["horadeInício"] = ele['t1'];
      time_data["fimdoTempo"] = ele['t2'];
      sabadoData["planodeeStudoHoras"].push(time_data);
    });
    response.push(sabadoData);
    return response;
  }

  public async saveStudyPlan() {    
    if(this.planName.trim() != '' && this.documentlist.length > 0) {
      this.planNameError = false;
      this.documentError = false;
      var sendData = {};
      const userId = this.authenticationService.userID
      sendData['UserId'] =  userId;
      sendData['RevogadoEstatisticas'] =  this.RevogadoEstatisticas;
      sendData['Dados'] =  {};
      sendData['Dados']['id'] = this.id;
      sendData['Dados']['estudoNomedoplano'] = this.planName;
      sendData['Dados']['noofWords'] = this.per_min_words;
      sendData['Dados']['planodeestudoDiaeHoras'] = this.timeSlotFormat();
      sendData['Dados']['planodeeStudoDocumentos'] = this.documentlist;
      var start_date = new Date(this.serializedDate.value);
      sendData['Dados']['comecaas'] = ((start_date.getDate() <= 9) ? '0' + start_date.getDate()  : start_date.getDate())  + '/' + (((start_date.getMonth() + 1) <= 9) ? '0' + (start_date.getMonth() + 1)  : (start_date.getMonth() + 1)) + '/' + start_date.getFullYear();
      sendData['Dados']['dadosFinais'] = this.endDate;
      var res = await this.ListArticleService.saveStudyPlans(sendData);
      if(res.status) {
        this.id = res.id;
        this.RevogadoEstatisticas = false;
        const alertDialog = this.dialog.open(AlertDialogComponent, {
          data: {
            title: '',
            message: res.message
          }
        });

        const prefs = this.usuarioPreferenciasService.Configuracoes;
        prefs.palavrasMinuto = this.per_min_words;
        this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
        this.carregarModel();
        this.dadosService.baixar().then(() => {
          console.log("updated storage");
        });
      }
    } else {
      if(this.planName.trim() == '') {
        this.planNameError = true;
      } else {
        this.planNameError = false;
      }
      
      if(this.listSelected == '') {
        this.documentError = true;
      } else {
        this.documentError = false;
      }

      if(this.per_min_words !== 0) {
        const alertDialog = this.dialog.open(AlertDialogComponent, {
          data: {
            title: '',
            message: 'Calcule sua velocidade de leitura clicando em calcular sua velocidade de leitura.' 
          }
        });
      }
      if(this.documentlist.length <= 0) {
        const alertDialog = this.dialog.open(AlertDialogComponent, {
          data: {
            title: '',
            message: 'Selecione pelo menos um documento para salvar o plano de estudo.' 
          }
        });
      }
    }

  }
  public addtolist(){
    if(this.listSelected != '') {
      var doc_details;
      var selected_leiItens = [];  
      var doc_leiItens = [];  
      var total_word_cnt = 0;
      var totalMarcaLinhas = 0;
      var totalLinhas = 0;
      var start_ele = '';
      var end_ele = '';
      var estudoParcialTitulo = ''; 
      var EstudoParcial = false; 
      doc_details = this.ListArticleService.getlist(this.listSelected).then(
        listData => {           
          if(listData.leiItens.length > 0) {
            total_word_cnt = listData.quantidadePalavras;
            totalMarcaLinhas = listData.totalMarcaLinhas;
            if(this.customSelectionDocumentlist.length == 0) {
              totalLinhas = listData.totalLinhas;
            } else {
              totalLinhas = this.total_custom_number_lines;
              totalMarcaLinhas = this.total_custom_read_lines;
            }           
            listData.leiItens.forEach((ele, index) => {
              selected_leiItens.push({"IdLeiItens" : ele.id, "totaisPalavras" : ele.totaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.artigoDe, 'artigoAte': ele.artigoAte});
              if(ele.leiItens.length > 0) {
                ele.leiItens.forEach((ele1, index1) => {
                  selected_leiItens.push({"IdLeiItens" : ele1.id, "totaisPalavras" : ele1.totaisPalavras, 'totalLinhas' : ele1.totalLinhas, 'artigoDe': (ele1.artigoDe == "") ? ele1.prefixo : ele1.artigoDe, 'artigoAte': ele1.prefixo});
                  if(ele1.leiItens.length > 0) {
                    ele1.leiItens.forEach((ele2, index2) => {
                      selected_leiItens.push({"IdLeiItens" : ele2.id, "totaisPalavras" : ele2.totaisPalavras, 'totalLinhas' : ele2.totalLinhas, 'artigoDe': (ele2.artigoDe == "") ? ele2.prefixo : ele2.artigoDe, 'artigoAte': ele2.prefixo});
                    });
                  }
                });
              }
            });
            if(this.customSelectionDocumentlist.length > 0) {
              var end_ele = '';
              var start_ele = '';
              this.customSelectionDocumentlist.forEach((ele,index) => {
                if(start_ele == '') {                  
                  start_ele = ele.artigoAte;
                  if(start_ele == '' || start_ele == null) {
                    start_ele = ele.artigoDe;
                  }
                }
              });
              if(start_ele == '') {
                this.customSelectionDocumentlist.forEach((ele,index) => {
                  if(ele.artigoAte != '' && start_ele == '') {
                    start_ele = ele.artigoAte;
                  }
                });        
              }
              var end_ele = '';
              this.customSelectionDocumentlist.forEach((ele,index) => {
                if(ele.artigoAte != '') {
                    end_ele = ele.artigoAte;
                }
              });              
              if(end_ele == '') {
                this.customSelectionDocumentlist.forEach((ele,index) => {
                  if(ele.artigoDe != '') {
                      end_ele = ele.artigoDe;
                  }
                });
              }
              if(start_ele == null) {
                start_ele = '';
              }
              if(end_ele == null) {
                end_ele = '';
              }
              if(listData.tipoDocumento == 1) {
                estudoParcialTitulo = '';
              } else {
                estudoParcialTitulo = 'Estudar de ' + start_ele + ' à ' + end_ele;
              }
              
            } else {
              var end_ele = '';
              var start_ele = '';
              selected_leiItens.forEach((ele,index) => {
                if(ele.artigoDe != '' && start_ele == '') {
                  start_ele = ele.artigoDe;
                }
              });
              if(start_ele == '') {
                selected_leiItens.forEach((ele,index) => {
                  if(ele.artigoAte != '' && start_ele == '') {
                    start_ele = ele.artigoAte;
                  }
                });  
              }

              var end_ele = '';
              selected_leiItens.forEach((ele,index) => {
                if(ele.artigoAte != '') {
                    end_ele = ele.artigoAte;
                }
              });              
              if(end_ele == '') {
                selected_leiItens.forEach((ele,index) => {
                  if(ele.artigoDe != '') {
                      end_ele = ele.artigoDe;
                  }
                });
              }
              if(start_ele == null) {
                start_ele = '';
              }
              if(end_ele == null) {
                end_ele = '';
              }
              if(listData.tipoDocumento == 1) {
                estudoParcialTitulo = '';
              } else {
                estudoParcialTitulo = 'Estudar de ' + start_ele + ' à ' + end_ele;
              }              
            }
          }
          this.documentDetails = listData;          
          if(this.documentlist.length > 0) {
            var in_flag = 0;                        
            this.documentlist.forEach((eled, indexd) => {       
              if(eled.id != null) {
                EstudoParcial = eled.estudoParcial;
              }
              if(this.customSelectionDocumentlist.length > 0) {
                EstudoParcial = true;
              }
              if(eled.idLei === this.listSelected) {
                if(this.customSelectionDocumentlist.length == 0) {
                  this.documentlist[indexd] = {"id":eled.id,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : total_word_cnt,"totalLinhas" : totalLinhas, "EstudoParcial" : EstudoParcial ,planodeeStudoLeiItens:selected_leiItens, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false};
                } else {
                  this.documentlist[indexd] = {"id":eled.id,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : this.total_custom_number_words, "totalLinhas" : totalLinhas, "EstudoParcial" : EstudoParcial, planodeeStudoLeiItens:this.customSelectionDocumentlist, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false};
                }
                in_flag = 1;
              }                
            });            
            if(in_flag === 0) {              
              if(this.customSelectionDocumentlist.length == 0) {
                this.documentlist.push({"id":null,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : total_word_cnt, "totalLinhas" : totalLinhas, "EstudoParcial" : EstudoParcial,  planodeeStudoLeiItens:selected_leiItens, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false})
              } else {                
                this.documentlist.push({"id":null,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : this.total_custom_number_words, "totalLinhas" : totalLinhas, "EstudoParcial" : EstudoParcial,  planodeeStudoLeiItens:this.customSelectionDocumentlist, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false})
              }
            }
          } else {
            if(this.customSelectionDocumentlist.length == 0) {              
              this.documentlist.push({"id":null,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : total_word_cnt, "totalLinhas" : totalLinhas , "EstudoParcial" : EstudoParcial,planodeeStudoLeiItens:selected_leiItens, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false})
            } else {
              EstudoParcial = true;
              this.documentlist.push({"id":null,"idLei":this.listSelected,"título":listData.leiTitulo, "totaisPalavras" : this.total_custom_number_words, "totalLinhas" : totalLinhas,"EstudoParcial" : EstudoParcial,  planodeeStudoLeiItens:this.customSelectionDocumentlist, "estudoParcialTitulo" : estudoParcialTitulo, 'totalMarcaLinhas' : totalMarcaLinhas, 'c_flag' : false})
            }
          }     
          if(this.customSelectionDocumentlist.length == 0) {
            this.total_number_words = this.total_number_words + total_word_cnt;            
          } else {
            this.total_number_words = this.total_number_words + this.total_custom_number_words;
          }
          this.customSelectionDocumentlist = [];
          this.total_custom_number_words = 0;          
          this.finalDateCalc();
          this.listSelected = '';
          this.subTitle = '';
          this.isCustom = false;
          // document.getElementById('study').trigger('change');
        },        
        err => console.log(err),
      );
    } else {
      const alertDialog = this.dialog.open(AlertDialogComponent, {
        data: {
          title: '',
          message: 'Selecione um documento.' 
        }
      });
    }
  }
  public clearForm() {
    this.planName = '';
    this.lastAddedTimeLog = {"domingo" :[],"segunda" :[],"terca" :[],"quarta" :[],"quinta" :[],"sexta" :[],"sabado" :[]};
    this.time_log = {
      "domingo": [],
      "segunda": [],
      "terca": [],
      "quarta": [],
      "quinta": [],
      "sexta": [],
      "sabado": []
    };
    this.documentlist = [];
    this.customSelectionDocumentlist = [];
    this.date = new FormControl(new Date());
    this.serializedDate = new FormControl((new Date()).toISOString());
    this.endDate = ((new Date().getDate() <= 9) ? '0' + new Date().getDate()  : new Date().getDate())  + '/' + ((new Date().getMonth() <= 9) ? '0' + (new Date().getMonth() + 1)  : (new Date().getMonth() + 1)) + '/' + new Date().getFullYear();
    this.endDate = '';
    this.total_custom_number_words = 0;
    this.total_number_words = 0;
    this.subTitle = '';

  }

  private carregarModel() {
    this.localData = this.localDataService.carregarConfiguracoes();
  }
  
 
}

