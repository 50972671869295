import { PaginaParameters } from '../leitor-content-pagina/pagina.parameters';
import { CaiuProvaPanelParameters } from '../leitor-content-panelcaiuprova/caiu-prova-panel.parameters';
import { ErrouProvaPanelParameters } from '../leitor-content-panelerrouprova/errou-prova-panel.parameters';
import { MarcacaoPanelParameters } from '../leitor-content-panelmarcacao/marcacao-panel.parameters';
import { ComentarioPanelParameters } from '../leitor-content-panelcomentario/comentario-panel.parameters';

export class TabContentParameters {
    pagina: PaginaParameters;
    painelCaiuProva: CaiuProvaPanelParameters;
    painelErrouProva: ErrouProvaPanelParameters;
    painelMarcacao: MarcacaoPanelParameters;
    painelComentario: ComentarioPanelParameters;
}
