import { ModoSelecao, TipoSelecao } from '../../services/selection.service';
import { ParametrosCaneta } from '../../models/UserData';

export class OpcaoToolbar {
    public descricao: string;
    public icone: string;
    public modo: ModoSelecao;
    public tipo: TipoSelecao;
    public cor: string;
    public hover: boolean;
    public ativo: boolean;
    public realcarMarcacao: boolean;
    public toolbar: boolean;

    constructor() {
        this.ativo = false;
        this.toolbar = true;
    }

    public static CarregarItensMenu(parametrosCaneta: ParametrosCaneta): OpcaoToolbar[] {
        const retorno = new Array<OpcaoToolbar>();

        const ferramentaSelecao = new OpcaoToolbar();
        ferramentaSelecao.descricao = 'Modo de seleção';
        ferramentaSelecao.modo = ModoSelecao.Padrao;
        ferramentaSelecao.tipo = null;
        ferramentaSelecao.icone = 'cursor-ponteiro';
        ferramentaSelecao.ativo = false;
        ferramentaSelecao.cor = '#646464';

        retorno.push(ferramentaSelecao);

        const ferramentaProva = new OpcaoToolbar();
        ferramentaProva.descricao = 'Prova';
        ferramentaProva.modo = ModoSelecao.Marcacao;
        ferramentaProva.tipo = TipoSelecao.Prova;
        ferramentaProva.icone = 'prova';
        ferramentaProva.ativo = false;
        ferramentaProva.cor = parametrosCaneta.corTagProva;
        ferramentaProva.realcarMarcacao = parametrosCaneta.realcarMarcacoesProva;
        ferramentaProva.toolbar = false;
        retorno.push(ferramentaProva);

        const ferramentaComentarios = new OpcaoToolbar();
        ferramentaComentarios.descricao = 'Comentários';
        ferramentaComentarios.modo = ModoSelecao.Marcacao;
        ferramentaComentarios.tipo = TipoSelecao.Comentario;
        ferramentaComentarios.icone = 'comentario';
        ferramentaComentarios.ativo = false;
        ferramentaComentarios.cor = parametrosCaneta.corTagComentario;
        ferramentaComentarios.realcarMarcacao = parametrosCaneta.realcarComentarios;
        ferramentaComentarios.toolbar = false;
        retorno.push(ferramentaComentarios);

        const ferramentaMnemonico = new OpcaoToolbar();
        ferramentaMnemonico.descricao = 'Mnemônico';
        ferramentaMnemonico.modo = ModoSelecao.Marcacao;
        ferramentaMnemonico.tipo = TipoSelecao.Mnemonico;
        ferramentaMnemonico.icone = 'mnemonico';
        ferramentaMnemonico.ativo = false;
        ferramentaMnemonico.cor = parametrosCaneta.corTagMnemonico;
        ferramentaMnemonico.realcarMarcacao = parametrosCaneta.realcarMnemonicos;
        ferramentaMnemonico.toolbar = false;
        retorno.push(ferramentaMnemonico);

        const ferramentaCaneta1 = new OpcaoToolbar();
        ferramentaCaneta1.descricao = parametrosCaneta.descricaoCaneta1;
        ferramentaCaneta1.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta1.tipo = TipoSelecao.Caneta1;
        ferramentaCaneta1.icone = (!parametrosCaneta.modoRealceCaneta1 || parametrosCaneta.modoRealceCaneta1 === 'Grifar') ? 'marcador' : 'underline';
        ferramentaCaneta1.ativo = false;
        ferramentaCaneta1.cor = parametrosCaneta.corCaneta1;
        retorno.push(ferramentaCaneta1);

        const ferramentaCaneta2 = new OpcaoToolbar();
        ferramentaCaneta2.descricao = parametrosCaneta.descricaoCaneta2;
        ferramentaCaneta2.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta2.tipo = TipoSelecao.Caneta2;
        ferramentaCaneta2.icone = (!parametrosCaneta.modoRealceCaneta2 || parametrosCaneta.modoRealceCaneta2 === 'Grifar') ? 'marcador' : 'underline';
        ferramentaCaneta2.ativo = false;
        ferramentaCaneta2.cor = parametrosCaneta.corCaneta2;
        retorno.push(ferramentaCaneta2);

        const ferramentaCaneta3 = new OpcaoToolbar();
        ferramentaCaneta3.descricao = parametrosCaneta.descricaoCaneta3;
        ferramentaCaneta3.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta3.tipo = TipoSelecao.Caneta3;
        ferramentaCaneta3.icone = (!parametrosCaneta.modoRealceCaneta3 || parametrosCaneta.modoRealceCaneta3 === 'Grifar') ? 'marcador' : 'underline';
        ferramentaCaneta3.ativo = false;
        ferramentaCaneta3.cor = parametrosCaneta.corCaneta3;
        retorno.push(ferramentaCaneta3);

        const ferramentaCaneta4 = new OpcaoToolbar();
        ferramentaCaneta4.descricao = parametrosCaneta.descricaoCaneta4;
        ferramentaCaneta4.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta4.tipo = TipoSelecao.Caneta4;
        ferramentaCaneta4.icone = (!parametrosCaneta.modoRealceCaneta4 || parametrosCaneta.modoRealceCaneta4 === 'Grifar') ? 'marcador' : 'underline';
        ferramentaCaneta4.ativo = false;
        ferramentaCaneta4.cor = parametrosCaneta.corCaneta4;
        retorno.push(ferramentaCaneta4);

        const ferramentaCaneta5 = new OpcaoToolbar();
        ferramentaCaneta5.descricao = parametrosCaneta.descricaoCaneta5;
        ferramentaCaneta5.modo = ModoSelecao.MarcaTexto;
        ferramentaCaneta5.tipo = TipoSelecao.Caneta5;
        ferramentaCaneta5.icone = (!parametrosCaneta.modoRealceCaneta5 || parametrosCaneta.modoRealceCaneta5 === 'Grifar') ? 'marcador' : 'underline';
        ferramentaCaneta5.ativo = false;
        ferramentaCaneta5.cor = parametrosCaneta.corCaneta5;
        retorno.push(ferramentaCaneta5);

        const ferramentaBorracha = new OpcaoToolbar();
        ferramentaBorracha.descricao = 'Borracha';
        ferramentaBorracha.modo = ModoSelecao.Borracha;
        ferramentaBorracha.tipo = null;
        ferramentaBorracha.icone = 'borracha';
        ferramentaBorracha.cor = '#919191';
        retorno.push(ferramentaBorracha);

        return retorno;
    }
}
