import { Component, OnInit, OnDestroy } from '@angular/core';
import { TimerService, Apontamento } from '../../../services/timer.service';
import { Subscription } from 'rxjs';
import { PainelLateralService } from '../../timer/painel-lateral/painel-lateral.service';

@Component({
  selector: '[app-timer]',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  private s_apontamento: Subscription;
  private tick_interval: number;

  public apontamentoAtual: Apontamento;
  public tempoApontamento: string;
  public apontamentoAtivo: boolean;
  public textoAnotacao: string;

  get textoApontamentoAtual() {
    return this.apontamentoAtual.anotacoes[0];
  }

  constructor(
    public timerService: TimerService,
    private painelLateralService: PainelLateralService
  ) { }

  ngOnInit() {
    this.s_apontamento = this.timerService.$apontamentoAtual.subscribe(a => {
      this.carregarApontamento(a);
    });

    this.tick_interval = window.setInterval(() => {
      if (this.apontamentoAtual)
        this.atualizarModelApontamento();
    }, 1000);
  }

  ngOnDestroy() {
    this.s_apontamento.unsubscribe();
    window.clearInterval(this.tick_interval);
  }

  public toggleTimer(): void {
    if (!this.apontamentoAtivo) {
      this.timerService.resumirApontamento();
    }
    else {
      this.timerService.pausarApontamento();
    }
  }

  public inserirAnotacao(): void {
    this.timerService.incluirAnotacao(this.textoAnotacao);
    this.textoAnotacao = null;
  }

  public togglePainel(): void {
    this.painelLateralService.togglePainel();
  }

  private carregarApontamento(a: Apontamento): void {
    this.apontamentoAtual = a;
    this.atualizarModelApontamento();
  }

  private atualizarModelApontamento(): void {
    if (this.apontamentoAtual) {
      this.tempoApontamento = this.apontamentoAtual.strTempoTotal;
      this.apontamentoAtivo = this.apontamentoAtual.ativo;
    } else {
      this.tempoApontamento = null;
      this.apontamentoAtivo = null;
    }
  }
}
