import { Injectable } from "@angular/core";
import { EstatisticasLeitura } from "../../models/usuario/EstatisticasLeitura";
import { SignalrService, OperacaoSignalR, EnumTipoObjeto } from "../signalr.service";
import { EstatisticasRepositorio } from "../../repositorios/estatisticas.repositorio";
import { BehaviorSubject, Observable } from "rxjs";
import { LeiRepositorio } from "../../repositorios/lei.repositorio";

@Injectable()
export class UsuarioEstatisticasService {
    Favoritos: Observable<string[]>
    private favoritos = new BehaviorSubject<string[]>(null)

    constructor(
        private signalrService: SignalrService,
        private leiRepositorio: LeiRepositorio,
        private estatisticasRepositorio: EstatisticasRepositorio
    ) {
        this.Favoritos = this.favoritos.asObservable()
        signalrService.$Mensagem.subscribe(msg => this.onMessageReceived(msg))
    }

    listar(): Promise<EstatisticasLeitura[]> {
        return new Promise(async resolve => {
            const listaEstatisticas = await this.estatisticasRepositorio.listar()
            this.favoritos.next(listaEstatisticas.filter(l => l.favorito).map(l => l.id))
            resolve(listaEstatisticas)
        })
    }

    async buscar(idLei: string): Promise<EstatisticasLeitura> {
        let estatisticas = await this.estatisticasRepositorio.buscar(idLei)
        return estatisticas
    }

    private onMessageReceived(msg: OperacaoSignalR): void {
        if (msg && msg.tipoObjeto === EnumTipoObjeto.Estatisticas) {
            this.salvar(msg.dados, false)
        }
    }

    public salvarLote(listaEstatisticas: EstatisticasLeitura[], sync = true): Promise<void> {
        return new Promise(async resolve => {
            for (const estatisticas of listaEstatisticas) {
                await this.estatisticasRepositorio.salvar(estatisticas)
            }

            if (sync) {
                const mensagens = listaEstatisticas.map(estatisticas => {
                    const msg = new OperacaoSignalR()
                    msg.dados = estatisticas
                    return msg
                });

                await this.signalrService.enviarMensagens(mensagens, EnumTipoObjeto.Estatisticas)
            }

            resolve()
        });
    }

    public salvar(estatisticas: EstatisticasLeitura, sync = true): Promise<void> {
        return new Promise(async resolve => {
            await this.estatisticasRepositorio.salvar(estatisticas)

            if (sync) {
                const mensagem = new OperacaoSignalR()
                mensagem.dados = estatisticas

                await this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Estatisticas)
            }

            resolve()
        });
    }

    public marcarLido(idLei: string, idLinha: string): Promise<EstatisticasLeitura> {
        return new Promise(async resolve => {
            const estatisticas = await this.buscar(idLei)

            const iLinha = estatisticas.linhasLidas.findIndex(l => l === idLinha)
            if (iLinha === -1)
                estatisticas.linhasLidas.push(idLinha)
            else
                estatisticas.linhasLidas.splice(iLinha, 1)

            await this.salvar(estatisticas)
            resolve(estatisticas)
        });
    }

    public marcarVariosLidos(idLei: string, idsLinhas: string[]): Promise<EstatisticasLeitura> {

        return new Promise(async resolve => {
            const estatisticas = await this.buscar(idLei)

            idsLinhas.forEach(idLinha => {
                const iLinha = estatisticas.linhasLidas.findIndex(l => l === idLinha)
                if (iLinha === -1)
                    estatisticas.linhasLidas.push(idLinha)
            });

            await this.salvar(estatisticas)
            resolve(estatisticas)
        });
    }

    public async marcarFavorito(id: string): Promise<EstatisticasLeitura> {
        const estatisticas = await this.buscar(id)
        estatisticas.favorito = !estatisticas.favorito

        await this.salvar(estatisticas)
        await this.listar()

        return estatisticas
    }

    public async alterarPosicaoLeitura(idLei: string, idLinha: string) {
        const estatisticas = await this.buscar(idLei)
        estatisticas.idUltimoItemLido = idLinha

        await this.salvar(estatisticas)
    }
}