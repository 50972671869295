import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { ConfiguracoesUsuario } from '../../../../models/usuario/ConfiguracoesUsuario';
import { EntitiesHelper } from '../../../../helpers/entities.helper';

@Component({
  selector: 'app-form-identificacao',
  templateUrl: './form-identificacao.component.html',
  styleUrls: ['./form-identificacao.component.scss']
})
export class FormIdentificacaoComponent implements OnInit, OnDestroy {
  public defaultPhoto = `assets\\ui\\user.svg`;
  public model: IdentificacaoModel;
  public email: string;

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  private s_userdata: Subscription;

  @ViewChild('fileInput') fileInputRef: ElementRef;

  constructor(
    private usuarioPreferenciasService: UsuarioPreferenciasService
  ) { }

  public ngOnInit(): void {
    this.s_userdata = this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
      this.model = new IdentificacaoModel(config);
      this.email = config.email;
    });
  }

  public ngOnDestroy(): void {
    this.s_userdata.unsubscribe();
  }

  public abrir(): void {
    this.cancelar();
  }

  public fechar(): void { }

  public async cancelar(): Promise<void> {
    this.model = new IdentificacaoModel(this.usuarioPreferenciasService.Configuracoes);
  }

  public salvar(): void {
    this.onsaving.emit();
    const salvarDadosUsuario = (): Promise<any> => {
      return new Promise((onSuccess, onFail) => {
        const prefs = <ConfiguracoesUsuario>EntitiesHelper.Copy(this.usuarioPreferenciasService.Configuracoes);

        prefs.nome = this.model.nome;
        prefs.sobrenome = this.model.sobrenome;
        prefs.foto = this.model.foto;
        console.log(prefs);
        this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
        onSuccess();
      });
    };

    salvarDadosUsuario().then(() => {
      this.onsaved.emit('Dados atualizados com sucesso!');
    }).catch((err) => {
      this.onsaved.emit(err);
    });
  }

  public pickPhoto(): void {
    const el = <HTMLInputElement>this.fileInputRef.nativeElement;
    el.click();
  }


  public onFileChanged(evt: Event): void {
    const resizedataURL = (datas): Promise<string> => {
      const maxWidth = 128;
      const maxHeight = 128;
      return new Promise(async function (resolve, reject) {

        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function () {
          // We create a canvas and get its context.
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');

          const imgWidth = img.width;
          const imgHeight = img.height;

          const ratio = imgWidth / imgHeight;

          let finalImageWidth = maxWidth;
          let finalImageHeight = maxHeight;

          if (ratio > 1) {
            finalImageHeight = maxHeight;
            finalImageWidth = finalImageHeight * ratio;
          } else if (ratio < 1) {
            finalImageWidth = maxWidth;
            finalImageHeight = finalImageWidth / ratio;
          }

          // We set the dimensions at the wanted size.
          canvas.width = maxWidth;
          canvas.height = maxHeight;

          // We resize the image with the canvas method drawImage();
          ctx.drawImage(
            img,
            canvas.width / 2 - finalImageWidth / 2,
            canvas.height / 2 - finalImageHeight / 2,
            finalImageWidth,
            finalImageHeight);

          var dataURI = canvas.toDataURL();
          resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = datas;

      })
    };

    const toBase64 = (f: File): Promise<string> => {
      return new Promise((onSuccess, onFail) => {
        const reader = new FileReader();
        reader.readAsDataURL(f);

        reader.onload = () => {
          const originalB64 = reader.result.toString();

          resizedataURL(originalB64).then((img) => {
            onSuccess(img);
          });
        };
        reader.onerror = (e) => onFail(e);
      });
    };

    const el = <HTMLInputElement>evt.target;
    const file = el.files[0];

    toBase64(file).then((str) => {
      this.model.foto = str;
    }).catch((err => {
      alert(`Erro: ${err}`);
      throw err;
    }));
  }
}

export class IdentificacaoModel {
  private nomeOriginal: string;
  private sobrenomeOriginal: string;
  private fotoOriginal: string;

  constructor(user: ConfiguracoesUsuario) {
    this.nomeOriginal = user.nome;
    this.nome = user.nome;

    this.sobrenomeOriginal = user.sobrenome;
    this.sobrenome = user.sobrenome;

    this.fotoOriginal = user.foto;
    this.foto = user.foto;
  }

  public nome: string;
  public sobrenome: string;
  public foto: string;

  public get alterado(): boolean {
    return (
      (this.nome !== this.nomeOriginal) ||
      (this.sobrenome !== this.sobrenomeOriginal) ||
      (this.foto !== this.fotoOriginal)
    ) ? true : false;
  }

  public get valido(): boolean {
    return (
      (this.nome && this.nome.length > 0) &&
      (this.sobrenome && this.sobrenome.length > 0)
    ) ? true : false;
  }
}