import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

export class AuthGuardParams {
  redirectWhenNotLoggedIn: boolean
  redirectWhenLoggedIn: boolean
  redirectWhenFirstAccess: boolean
  redirectWhenNotFirstAccess: boolean

  static build(params: Partial<AuthGuardParams>) {
    return Object.assign(new AuthGuardParams(), params)
  }

  static loggedOff() {
    return AuthGuardParams.build({
      redirectWhenLoggedIn: true
    })
  }

  static loggedInAndFirstAccess() {
    return AuthGuardParams.build({
      redirectWhenNotLoggedIn: true,
      redirectWhenNotFirstAccess: true
    })
  }

  static loggedInAndNotFirstAccess() {
    return AuthGuardParams.build({
      redirectWhenNotLoggedIn: true,
      redirectWhenFirstAccess: true
    })
  }
}

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const params = <AuthGuardParams>next.data.authGuardParams

    if (params.redirectWhenLoggedIn && this.authService.userIsLoggedIn()) {
      this.router.navigate(['/'])
      return false;
    }

    if (params.redirectWhenNotLoggedIn && !this.authService.userIsLoggedIn()) {
      this.router.navigate(['/auth/login'])
      return false;
    }

    if (params.redirectWhenFirstAccess && this.authService.isUserFirstAccess()) {
      this.router.navigate(['/auth/primeiro-acesso'])
      return false;
    }

    if (params.redirectWhenNotFirstAccess && !this.authService.isUserFirstAccess()) {
      this.router.navigate(['/'])
      return false;
    }

    return true;
  }
}
