import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../../app.config";
import { DetalhesAssinaturaResponse } from "../arguments/assinatura/DetalhesAssinaturaResponse";
import { BehaviorSubject, Observable } from "rxjs";
import { ItemLookupPlano } from "../../auth/models/item-lookup-plano.model";
import { CadastroUsuarioPlanoRequest } from "../../auth/models/CadastroUsuarioPlanoRequest.model";

@Injectable()
export class AssinaturaService {
    TrialExpirou: Observable<boolean>
    private trialExpirou = new BehaviorSubject<boolean>(false)

    constructor(private httpClient: HttpClient) {
        this.TrialExpirou = this.trialExpirou.asObservable()
    }

    async carregarPlanoAtual(): Promise<DetalhesAssinaturaResponse> {
        const url = `${AppConfig.apiEndpoint}/assinatura/meu-plano`
        const plano = <DetalhesAssinaturaResponse>(await this.httpClient.get(url).toPromise())[0]

        if (plano.trialGratuito) {
            plano.diasTesteUtilizados = plano.tempoAvaliacaoGratuita - plano.diasRestantesAvaliacao

            if (plano.tempoAvaliacaoGratuita <= 0)
                this.trialExpirou.next(true)
        }

        return plano
    }


    async listarPlanos(apenasTrial: boolean): Promise<ItemLookupPlano[]> {
        const planos = [
            { id: 6, descricao: 'Teste grátis', periodicidade: 'trial' },
            { id: 7, descricao: 'Cobrança mensal - R$ 14,90', periodicidade: 'mensal' },
            { id: 8, descricao: 'Cobrança anual - R$ 149,00', periodicidade: 'anual' }
        ]

        return planos.filter(p => (apenasTrial && p.periodicidade == 'trial') || p.periodicidade != 'trial')
    }

    async assinarPlano(payload: CadastroUsuarioPlanoRequest) {
        const url = `${AppConfig.apiEndpoint}/assinatura/alterar`
        await this.httpClient.post(url, payload).toPromise()
    }
}