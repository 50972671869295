import { Component, Input, OnInit, Output, EventEmitter, HostListener, Directive, Renderer2, ViewChild, OnDestroy, ElementRef, ViewChildren, AfterContentInit, AfterViewInit } from '@angular/core';
import { Comentario } from '../../../../models/Comentario';
import { TagConteudo } from '../../../../interfaces/TagConteudo';
import { HoverService } from '../../../../services/hover.service';
import { UsuarioComentariosService } from '../../../../services/data-services/usuario.comentarios.service';
import { TextoPagina } from '../../../../models/pagina/TextoPagina';
import { Marcacao, FuncoesProva, ProvaDados } from '../../../../models/Marcacao';
import { UsuarioProvasService } from '../../../../services/data-services/usuario.provas.service';
import { ProvaDatasource, ParametrosCaneta } from '../../../../models/UserData';
import { UsuarioMarcacoesService } from '../../../../services/data-services/usuario.marcacoes.service';
import { TagPickerComponent } from '../../placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component';
import { StringHelper } from '../../../../helpers/string.helper';
import { MatDialog } from '@angular/material/dialog';
import { SimNaoDialogComponent } from '../../../dialogs/sim-nao-dialog/sim-nao-dialog.component';

@Directive({
    selector: '[appResizableDiv]'
})
export class ResizableDivDirective implements OnDestroy {
    @Output() resize = new EventEmitter();

    elId: string;

    @HostListener('mousedown', ['$event.target'])
    onMouseDown(el) {
        this.elId = el.id;
    }

    @HostListener('document:mouseup')
    onMouseUp() {
        this.ngOnDestroy();
    }

    constructor(private renderer: Renderer2) { }

    ngOnDestroy() {
        if (this.elId) {
            const comentDiv = document.getElementById(this.elId);

            if (comentDiv.style.width.length > 0 && comentDiv.style.height.length > 0) {
                this.resize.emit({ width: comentDiv.style.width, height: comentDiv.style.height });
                this.elId = null;
            }
        }
    }
}

@Directive({
    selector: '[appAutoGrowDiv]'
})
export class AutoGrowDivDirective {
    private _buffer: HTMLTextAreaElement;

    @HostListener('input', ['$event'])
    onInput(ev: any): void {
        const textArea = <HTMLTextAreaElement>ev.target;
        setTimeout(() => this.resizeTxtAreaComentarios(textArea), 0);
    }


    public resizeTxtAreaComentarios(targ: HTMLTextAreaElement) {

        if (this._buffer == null) {
            this._buffer = document.createElement('textarea');
            this._buffer.style.border = 'none';
            this._buffer.style.height = '0';
            this._buffer.style.overflow = 'hidden';
            this._buffer.style.padding = '0';
            this._buffer.style.position = 'absolute';
            this._buffer.style.left = '0';
            this._buffer.style.top = '0';
            this._buffer.style.zIndex = '-1';
            document.body.appendChild(this._buffer);
        }

        var cs = window.getComputedStyle(targ);
        var pl = parseInt(cs.paddingLeft);
        var pr = parseInt(cs.paddingRight);
        var lh = parseInt(cs.lineHeight);

        // [cs.lineHeight] may return 'normal', which means line height = font size.
        if (isNaN(lh)) lh = parseInt(cs.fontSize);

        // Copy content width.
        this._buffer.style.width = (targ.clientWidth - pl - pr) + 'px';

        // Copy text properties.
        this._buffer.style.font = cs.font;
        this._buffer.style.letterSpacing = cs.letterSpacing;
        this._buffer.style.whiteSpace = cs.whiteSpace;
        this._buffer.style.wordBreak = cs.wordBreak;
        this._buffer.style.wordSpacing = cs.wordSpacing;
        this._buffer.style.wordWrap = cs.wordWrap;

        // Copy value.
        this._buffer.value = targ.value;

        var result = Math.floor(this._buffer.scrollHeight / lh);
        if (result == 0) { result = 1; }


        const div = document.getElementById(targ.id.replace('coment-sup-input', 'coment-sup-div').toString());


        //limitar 15 quebras de linha por comentario
        if (result > 15) {
            targ.value = targ.value.substring(0, targ.value.length - 1);
            result = 15;
        }

        targ.style.cssText = 'height:auto; padding:0';
        if (result === 1) {
            div.style.height = 25 + 'px';
        } else {
            div.style.height = 25 + (12.5 * result) + 'px';
        }
        targ.style.height = '100%';
    }
}

@Directive({
    selector: '[appAutoWidht]'
})
export class AutoWidhtDirective implements AfterViewInit {



    private _texto: string;
    @Input('textoAutoWidhtDirective') set textoAutoWidhtDirective(val: string) {
        this._texto = val;

        if (!this.el.nativeElement.id) { return; }
        this.resize(this.el.nativeElement.id, this._texto)
    }

    @HostListener('input', ['$event'])
    onInput(ev: Event): void {
        ev.preventDefault();
        const input = <HTMLInputElement>(ev.target);
        this.resize(input.id, input.value)
    }

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        if (!this.el || !this._texto) {
            return;
        }

        setTimeout(() => {
            this.resize(this.el.nativeElement.id, this._texto);
        }, 0);
    }

    public resize(id, texto) {

        const hideEl = document.getElementById("hide-resize-helper");
        hideEl.textContent = texto;

        let item = document.getElementById(id);
        if (id.indexOf("mne") != -1) {
            hideEl.textContent = texto.toUpperCase();
        }

        const width = hideEl.offsetWidth + 45;
        this.el.nativeElement.style.width = width.toString() + 'px';
    }
}

export interface MarcacoesGroup {
    tipo: string;
    marcacoes: ProvaDados[];
}


@Component({
    selector: 'app-marcacao-superior',
    templateUrl: './marcacao-superior.component.html',
    styleUrls: ['./marcacao-superior.component.scss']
})
export class MarcacaoSuperiorComponent implements OnInit {

    @Input() linha: TextoPagina;
    @Input() opcoesPonteiros: ParametrosCaneta;
    @Input() posicaoAtual: string;
    // @Input() corProva: string;
    // @Input() corComentario: string;
    // @Input() corMnemonico: string;

    public provaEditando: Marcacao;
    public provaNova = false;
    public provaTextoBuscando: string;
    public carregandoOpcoesProva = true;
    public opcoesProva: ProvaDatasource;
    public funcoesProva = FuncoesProva;
    private _provasExistentes = new Array<MarcacoesGroup>();
    public provasExistentes = new Array<MarcacoesGroup>();

    private _bufferInputWidht: HTMLSpanElement;
    public marginTop: number;
    public marginBottom: number;

    public get any() {
        return this.comentarios.length > 0 || this.mnemonicos.length > 0 || this.marcacoesLinhaAtual.length > 0;
    }

    public get comentarios() {
        return this.comentariosLinhaAtual.filter(x => x.mnemonico === false);
    }
    public get mnemonicos() {
        return this.comentariosLinhaAtual.filter(x => x.mnemonico === true);
    }

    private get comentariosLinhaAtual(): Comentario[] {
        if (this.linha.comentarios && this.linha.comentarios.length > 0) {
            if (this.posicaoAtual === 'Acima') {
                return this.linha.comentarios.filter(x => x.range.idItens[0].idItem === this.linha.id);
            } else {
                return this.linha.comentarios.filter(x => x.range.idItens[x.range.idItens.length - 1].idItem === this.linha.id);
            }

        } else {
            return new Array<Comentario>();
        }
    }

    public get marcacoesLinhaAtual(): Marcacao[] {
        if (this.linha.marcacoesProva && this.linha.marcacoesProva.length > 0) {
            if (this.posicaoAtual === 'Acima') {
                return this.linha.marcacoesProva.filter(x => x.range.idItens[0].idItem === this.linha.id);
            } else {
                return this.linha.marcacoesProva.filter(x => x.range.idItens[x.range.idItens.length - 1].idItem === this.linha.id);
            }

        } else {
            return new Array<Marcacao>();
        }
    }

    @ViewChild('instituicaoElement') set instituicaoElement(inst: TagPickerComponent) {
        if (!inst) { return; }
        inst.focus = true;
    }
    @ViewChild('controlProvaExistente') set controlProvaExistente(inpu: ElementRef) {
        if (!inpu) { return; }
        setTimeout(() => { inpu.nativeElement.focus(); }, 0);
    }


    ngOnInit() {
        switch (this.posicaoAtual) {
            case 'Acima':
                this.marginBottom = -7;
                this.marginTop = 3;
                break;
            case 'Abaixo':
                this.marginBottom = 2;
                this.marginTop = -5;
                break;
        }
    }

    constructor(
        private hoverService: HoverService,
        // private dialogoService: DialogoService,
        private usuarioComentariosService: UsuarioComentariosService,
        private usuarioMarcacaoService: UsuarioMarcacoesService,
        private usuarioProvasService: UsuarioProvasService,
        public dialog: MatDialog
    ) {
    }



    //#region Comentarios

    cancelarComentario() {

    }

    salvarComentario(comentario) {
        this.atualizarComentario(comentario)
    }

    public realcarComentario(idComentario: string): void {
        const col = new Array<TagConteudo>();

        const coment = this.linha.comentarios.find(x => x.id === idComentario);
        const tag = new TagConteudo(null, coment);
        col.push(tag);

        this.hoverService.destacar(col);
    }

    public confirmarDelecaoComentario(comentario: Comentario) {
        const tipoComentario = comentario.mnemonico ? 'mnemonico' : 'comentário';
        const dialogRef = this.dialog.open(SimNaoDialogComponent, { width: '250px', data: 'Deseja mesmo excluir o ' + tipoComentario + '?' });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.usuarioComentariosService.remover(comentario);
            }
        });
    }

    public atualizarComentario(c: Comentario, size: any = null) {
        this.hoverService.destacar(null);

        let largura: number;
        if (c.texto.length > 0) {
            // REGRAS INSERIDAS NO EDITOR DE COMENTÁRIO
            // if (!c.mnemonico) {
            //     const reg = /\n/g;
            //     if (c.texto.match(reg)) {
            //         const linhas = c.texto.split(reg);
            //         let maiorLinha = '';
            //         linhas.forEach(l => {
            //             if (l.length > maiorLinha.length) {
            //                 maiorLinha = l;
            //             }
            //         });
            //         largura = this.getWidthOfText(maiorLinha);

            //     } else {
            //         largura = this.getWidthOfText(c.texto);
            //     }


            //     if (size) {
            //         c.larguraExibicao = size.width.toString().replace('px', '');
            //         c.alturaExibicao = size.height.toString().replace('px', '');
            //     } else {
            //         const div = document.getElementById('coment-sup-div-' + c.id);
            //         c.larguraExibicao = (largura && largura < div.offsetWidth) ? largura : div.offsetWidth;
            //         c.alturaExibicao = div.offsetHeight;
            //     }
            // }

            this.usuarioComentariosService.atualizar(c, true);
        } else {
            this.usuarioComentariosService.remover(c);
        }
        // this.atualizarComentario(c);
    }

    idEditando: string

    public focusEnterComentarios(targ: HTMLElement, c: Comentario) {
        const div = document.getElementById(targ.id.replace('input', 'div').toString());

        div.classList.add('custom-chip-ativo');
        this.idEditando = c.id

        this.realcarComentario(c.id);
        // this.resizeTxtAreaComentarios(targ);
    }

    public focusLeaveComentarios(targ: HTMLElement, c: Comentario) {
        const div = document.getElementById(targ.id.replace('input', 'div').toString());
        div.classList.remove('custom-chip-ativo');
        this.idEditando = null
        targ.blur();

        // const heightAtual = Number.parseFloat(div.style.height.toString().replace('px', ''));
        // if (!c.mnemonico && (!c.alturaExibicao || c.alturaExibicao < heightAtual)) {
        //     div.style.height = '25px';
        // }
        //this.atualizarComentario(c);

        this.atualizarComentario(c);
    }

    public getWidthOfText(texto: string) {

        {
            this._bufferInputWidht = document.createElement('span');
            this._bufferInputWidht.style.border = 'none';
            this._bufferInputWidht.style.height = '0';
            this._bufferInputWidht.style.overflow = 'hidden';
            this._bufferInputWidht.style.padding = '0';
            this._bufferInputWidht.style.position = 'absolute';
            this._bufferInputWidht.style.left = '0';
            this._bufferInputWidht.style.top = '0';
            this._bufferInputWidht.style.zIndex = '-1';
            this._bufferInputWidht.style.fontSize = '10pt';
            document.body.appendChild(this._bufferInputWidht);
        }

        this._bufferInputWidht.textContent = texto;

        const width = this._bufferInputWidht.offsetWidth + 40;
        return width;
        // this.el.nativeElement.style.width = width.toString() + 'px';
    }

    public resizeTxtAreaComentarios(targ: HTMLElement) {
        targ.style.cssText = 'height:auto; padding:0';
        const div = document.getElementById(targ.id.replace('coment-sup-input', 'coment-sup-div').toString());

        const heightAtual = Number.parseFloat(div.style.height.toString().replace('px', ''));
        if (targ.scrollHeight > heightAtual) {
            const tamanhoFinal = targ.scrollHeight + 10;
            div.style.height = tamanhoFinal + 'px';
            targ.style.height = '100%';
        }

    }


    //#endregion

    //#region Prova

    gerarNovaProva() {
        this.provaNova = true;
        this.provaEditando.dados.ano = null;
        this.provaEditando.dados.banca = null;
        this.provaEditando.dados.cargo = null;
        this.provaEditando.dados.tipo = null;
        this.provaEditando.dados.instituicao = null;
    }

    focusEnterProva(targ: HTMLElement, p: Marcacao) {
        const div = document.getElementById(targ.id.replace('input', 'div').toString());
        div.classList.add('custom-chip-ativo');

        this.provaEditando = p;
        this.carregandoOpcoesProva = true;
        this.usuarioProvasService.carregarDatasource().then(ds => {
            this.opcoesProva = ds;
            // this.carregandoOpcoesProva = false;
        });

        let todasProvas: Marcacao[];
        this.usuarioMarcacaoService.listar().then(provas => {
            todasProvas = provas;

            const ultimaProva = provas.sort((a, b) => {
                if (a.dataHoraModificacao > b.dataHoraModificacao) {
                    return -1;
                } else if (a.dataHoraModificacao < b.dataHoraModificacao) {
                    return 1;
                }
            })[0];

            if (ultimaProva) {
                this._provasExistentes = new Array<MarcacoesGroup>();
                this._provasExistentes.push({ tipo: 'Última prova', marcacoes: [ultimaProva.dados] });
                let provasAz = new Array<Marcacao>();
                todasProvas.forEach(pro => {
                    if (provasAz.findIndex(x => FuncoesProva.getLabelProva(x.dados) === this.funcoesProva.getLabelProva(pro.dados)) === -1) {
                        provasAz.push(pro);
                    }
                });
                provasAz = provasAz.sort((a, b) => {
                    return StringHelper.AlphabeticnaturalSort(FuncoesProva.getLabelProva(a.dados), FuncoesProva.getLabelProva(b.dados));
                });
                this._provasExistentes.push({ tipo: 'A-Z', marcacoes: provasAz.map(x => x.dados) });
            }

            this.provasExistentes = this._provasExistentes;
            this.provaNova = this._provasExistentes.length > 0 ? false : true;
            this.carregandoOpcoesProva = false;
        });
    }

    picker_keyup_enter(idProva: string) {
        const idPicker = `prov-sup-input-${idProva}`;
        const picker = document.getElementById(idPicker);
        this.focusLeaveProva(picker);
    }

    focusLeaveProva(targ: HTMLElement) {
        console.log('prova');

        const div = document.getElementById(targ.id.replace('input', 'div').toString());
        div.classList.remove('custom-chip-ativo');

        if (this.provaNova) {

            if (this.provaEditando.dados.ano && this.provaEditando.dados.ano.descricao.length > 0 && !this.provaEditando.dados.ano.dataHoraModificacao) {
                this.usuarioProvasService.incluirAno(this.provaEditando.dados.ano);
            }

            if (this.provaEditando.dados.banca && this.provaEditando.dados.banca.descricao.length > 0 && !this.provaEditando.dados.banca.dataHoraModificacao) {
                this.usuarioProvasService.incluirBanca(this.provaEditando.dados.banca);
            }

            if (this.provaEditando.dados.cargo && this.provaEditando.dados.cargo.descricao.length > 0 && !this.provaEditando.dados.cargo.dataHoraModificacao) {
                this.usuarioProvasService.incluirCargo(this.provaEditando.dados.cargo);
            }

            if (this.provaEditando.dados.tipo && this.provaEditando.dados.tipo.descricao.length > 0 && !this.provaEditando.dados.tipo.dataHoraModificacao) {
                this.usuarioProvasService.incluirTipo(this.provaEditando.dados.tipo);
            }

            if (this.provaEditando.dados.instituicao && this.provaEditando.dados.instituicao.descricao.length > 0 && !this.provaEditando.dados.instituicao.dataHoraModificacao) {
                this.usuarioProvasService.incluirInstituicao(this.provaEditando.dados.instituicao);
            }

        }

        this.usuarioMarcacaoService.atualizar(this.provaEditando);
        this.blurRealce();
        this.provaEditando = null;
        this.carregandoOpcoesProva = true;
    }

    public confirmarDelecaoProva(prova: Marcacao) {
        const dialogRef = this.dialog.open(SimNaoDialogComponent, { width: '250px', data: 'Deseja mesmo excluir a marcação de prova?' });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.usuarioMarcacaoService.remover(prova);
            }
        });
    }

    public realcarProva(idComentario: string): void {
        const col = new Array<TagConteudo>();

        const prova = this.linha.marcacoesProva.find(x => x.id === idComentario);
        const tag = new TagConteudo(prova, null);
        col.push(tag);

        this.hoverService.destacar(col);
    }

    public filtroMarcacaoProva(value: string) {
        if (value && value.length > 0) {
            value = value.toLowerCase();
            this.provasExistentes = new Array<MarcacoesGroup>();
            this.provasExistentes.push({
                tipo: this._provasExistentes[1].tipo,
                marcacoes: this._provasExistentes[1].marcacoes.filter(x => FuncoesProva.getLabelProva(x).toLowerCase().indexOf(this.provaTextoBuscando.toLowerCase()) !== -1)
            }
            );
        } else {
            this.provasExistentes = this._provasExistentes;
        }
    }

    public selecionarProvaExistente(dados: ProvaDados, targ: HTMLElement) {
        this.provaEditando.dados = dados;
        this.focusLeaveProva(targ);
    }

    //#endregion

    //#region Comum

    public blurRealce(): void {
        this.hoverService.destacar(null);
    }

    //#endregion
}
