import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { ErrouProvaPanelParameters } from '../leitor-content-panelerrouprova/errou-prova-panel.parameters';
import { MarcacaoPanelParameters } from '../leitor-content-panelmarcacao/marcacao-panel.parameters';
import { ComentarioPanelParameters } from '../leitor-content-panelcomentario/comentario-panel.parameters';
import { CaiuProvaPanelParameters } from '../leitor-content-panelcaiuprova/caiu-prova-panel.parameters';
import { Marcacao } from '../../models/Marcacao';
import { Comentario } from '../../models/Comentario';

@Injectable()
export class PaineisService {
    painelCaiuProvaParameters: Observable<CaiuProvaPanelParameters>;
    painelCaiuProvaParametersSubject = new BehaviorSubject<CaiuProvaPanelParameters>(null);

    painelErrouProvaParameters: Observable<ErrouProvaPanelParameters>;
    painelErrouProvaParametersSubject = new BehaviorSubject<ErrouProvaPanelParameters>(null);

    painelMarcacoesParameters: Observable<MarcacaoPanelParameters>;
    painelMarcacoesParametersSubject = new BehaviorSubject<MarcacaoPanelParameters>(null);

    painelComentariosParameters: Observable<ComentarioPanelParameters>;
    painelComentariosParametersSubject = new BehaviorSubject<ComentarioPanelParameters>(null);

    painelMarcacoesAberto: Observable<boolean>;
    painelMarcacoesAbertoSubject = new BehaviorSubject<boolean>(false);

    painelMarcacaoHover: Observable<Marcacao>;
    painelMarcacaoHoverSubject = new BehaviorSubject<Marcacao>(null);

    painelComentarioHover: Observable<Comentario>;
    painelComentarioHoverSubject = new BehaviorSubject<Comentario>(null);

    painelNovidades: Observable<boolean>;
    painelNovidadesSubject = new BehaviorSubject<boolean>(null);

    painelSpinner: Observable<boolean>;
    painelSpinnerSubject = new BehaviorSubject<boolean>(null);

    public algumPainelAberto: Observable<boolean>;
    private algumPainelAbertoSubject = new BehaviorSubject<boolean>(null);

    constructor(
    ) {
        this.painelCaiuProvaParameters = this.painelCaiuProvaParametersSubject.asObservable();
        this.painelErrouProvaParameters = this.painelErrouProvaParametersSubject.asObservable();
        this.painelMarcacoesParameters = this.painelMarcacoesParametersSubject.asObservable();
        this.painelComentariosParameters = this.painelComentariosParametersSubject.asObservable();
        this.painelMarcacoesAberto = this.painelMarcacoesAbertoSubject.asObservable();
        this.painelMarcacaoHover = this.painelMarcacaoHoverSubject.asObservable();
        this.painelComentarioHover = this.painelComentarioHoverSubject.asObservable();
        this.algumPainelAberto = this.algumPainelAbertoSubject.asObservable();
        this.painelNovidades = this.painelNovidadesSubject.asObservable();
        this.painelSpinner = this.painelSpinnerSubject.asObservable();
    }

    private updatePanelInfo() {
        if (this.painelAberto) {
            this.painelMarcacoesAbertoSubject.next(true);
        } else {
            this.painelMarcacoesAbertoSubject.next(false);
        }

    }

    get painelAberto() {
        if (this.painelCaiuProvaParametersSubject.getValue()) {
            return this.painelCaiuProvaParametersSubject.getValue();
        }
        if (this.painelErrouProvaParametersSubject.getValue()) {
            return this.painelErrouProvaParametersSubject.getValue();
        }
        if (this.painelMarcacoesParametersSubject.getValue()) {
            return this.painelMarcacoesParametersSubject.getValue();
        }
        if (this.painelComentariosParametersSubject.getValue()) {
            return this.painelComentariosParametersSubject.getValue();
        }


        return null;
    }

    abrirPainelCaiuProva(params: CaiuProvaPanelParameters) {
        this.fecharPaineis();
        this.painelCaiuProvaParametersSubject.next(params);

        if (params.marcacaoEdicao) {
            this.painelMarcacaoHoverSubject.next(params.marcacaoEdicao);
        }

        this.algumPainelAbertoSubject.next(true);
    }

    fecharPainelCaiuProva() {
        this.painelCaiuProvaParametersSubject.next(null);
        this.updatePanelInfo();

        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelErrouProva(params: ErrouProvaPanelParameters) {
        this.fecharPaineis();
        this.painelErrouProvaParametersSubject.next(params);

        if (params.marcacaoEdicao) {
            this.painelMarcacaoHoverSubject.next(params.marcacaoEdicao);
        }

        this.algumPainelAbertoSubject.next(true);
    }

    fecharPainelErrouProva() {
        this.painelErrouProvaParametersSubject.next(null);
        this.updatePanelInfo();

        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelMarcacoes(params: MarcacaoPanelParameters) {
        this.fecharPaineis();
        this.painelMarcacoesParametersSubject.next(params);

        this.algumPainelAbertoSubject.next(true);
    }

    fecharPainelMarcacoes() {
        this.painelMarcacoesParametersSubject.next(null);
        this.updatePanelInfo();

        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelComentarios(params: ComentarioPanelParameters) {
        this.fecharPaineis();
        this.painelComentariosParametersSubject.next(params);

        if (params.comentarioEdicao) {
            this.painelComentarioHoverSubject.next(params.comentarioEdicao);
        }


        this.algumPainelAbertoSubject.next(true);
    }

    fecharPainelComentarios() {
        this.painelComentariosParametersSubject.next(null);
        this.updatePanelInfo();

        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelNovidades() {
        this.fecharPaineis();
        this.painelNovidadesSubject.next(true);

        this.algumPainelAbertoSubject.next(false);
    }

    fecharPainelNovidades() {
        this.painelNovidadesSubject.next(null);
        this.algumPainelAbertoSubject.next(false);
    }

    abrirPainelSpinner() {
        this.fecharPaineis();
        this.painelSpinnerSubject.next(true);

        this.algumPainelAbertoSubject.next(false);
    }

    fecharPainelSpinner() {
        this.painelSpinnerSubject.next(null);
        this.algumPainelAbertoSubject.next(false);
    }

    fecharPaineis() {
        this.fecharPainelCaiuProva();
        this.fecharPainelErrouProva();
        this.fecharPainelMarcacoes();
        this.fecharPainelComentarios();
        this.fecharPainelNovidades();
        this.fecharPainelSpinner();
    }
}
