import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { SelectionService, ModoSelecao } from "./selection.service";
import { ConnectivityService } from "./connectivity.service";

@Injectable()
export class StatusService {
    private selectedTool: number = null;

    public msgStatusObservable: Observable<string>;
    private msgStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>('Pronto');

    public $AppOffline: Observable<boolean>;
    private _AppOffline = new BehaviorSubject<boolean>(false);

    public $Message: Observable<string>;
    private _Message = new BehaviorSubject<string>(null);

    public $ProgressoOperacao: Observable<number>;
    private _ProgressoOperacao = new BehaviorSubject<number>(null);

    public $MensagensProgresso: Observable<ProgressMessageModel[]>;
    private _MensagensProgresso: BehaviorSubject<ProgressMessageModel[]> = new BehaviorSubject<ProgressMessageModel[]>([]);

    constructor(
        private selectionService: SelectionService,
        private connectivityService: ConnectivityService
    ) {
        this.initialize()

        connectivityService.ConnectivityStatus.subscribe(cs => {
            if (cs) {
                this.setAppOffline(!cs.online)
            }
        })
    }

    private initialize() {
        this.msgStatusObservable = this.msgStatusSubject.asObservable();
        this.$MensagensProgresso = this._MensagensProgresso.asObservable();
        this.$AppOffline = this._AppOffline.asObservable();
        this.$Message = this._Message.asObservable();
        this.$ProgressoOperacao = this._ProgressoOperacao.asObservable();

        this.selectionService.$Selection.subscribe(sel => {
            if (sel) {
                this.selectedTool = sel.modo;
            }
            this.voidStatus();
        });
    }

    public async testConnection(): Promise<void> {
        await this.connectivityService.testConnection()
    }

    public get DataUltimaAtualizacao(): Date {
        return <Date>JSON.parse(localStorage.getItem('DataUltimaAtualizacao'));
    }

    public set DataUltimaAtualizacao(value: Date) {
        localStorage.setItem('DataUltimaAtualizacao', JSON.stringify(value));
    }

    public get PrimeiroDownloadConcluido(): boolean {
        return this.DataUltimaAtualizacao ? true : false;
    }

    public mostrarMensagemProgresso(mensagem: string, tarefa: string) {
        const message = new ProgressMessageModel();
        message.message = mensagem;
        message.task = tarefa;

        const pilha = this._MensagensProgresso.getValue();
        const iItemPilha = pilha.findIndex(p => p.task === message.task);

        if (iItemPilha === -1)
            pilha.push(message);
        else
            pilha[iItemPilha] = message;

        this._MensagensProgresso.next(pilha);
    }

    public ocultarMensagemProgresso(tarefa: string) {
        const pilha = this._MensagensProgresso.getValue().filter(i => i.task !== tarefa);
        this._MensagensProgresso.next(pilha);
    }

    public setProgressoOperacao(progresso: number = null) {
        this._ProgressoOperacao.next(progresso);
    }

    private wasOffline = false
    private setAppOffline(isOffline: boolean) {
        this._AppOffline.next(isOffline);

        if (!this.wasOffline && isOffline)
            this.setMessage('Não foi possível conectar ao servidor, tentaremos reconectar em breve');

        this.wasOffline = isOffline
    }

    public setMessage(message: string = null) {
        this._Message.next(message);
    }

    public get isAppOnline(): boolean {
        return !this._AppOffline.getValue();
    }

    public voidStatus() {
        this.msgStatusSubject.next(this.getToolDescription(this.selectedTool));
    }

    private getToolDescription(modo: ModoSelecao): string {
        switch (modo) {
            case ModoSelecao.Padrao:
                return 'Modo de seleção';
            case ModoSelecao.Marcacao:
                return 'Modo marcação';
            case ModoSelecao.MarcaTexto:
                return 'Modo marca-texto';
            case ModoSelecao.Borracha:
                return 'Modo borracha';
        }
    }

    public setStatusMessage(message: string, timeout: boolean = false) {
        this.msgStatusSubject.next(message);

        if (timeout) {
            setTimeout(() => {
                this.voidStatus();
            }, 2500);
        }
    }
}

export class ProgressMessageModel {
    task: string;
    message: string;
}