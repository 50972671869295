import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-erro-popup',
    templateUrl: './erro-popup.component.html',
    styleUrls: ['./erro-popup.component.css'],
    animations: [
        trigger('msgCarregamento', [
            transition('* <=> *', [
                style({ opacity: 0 }),
                animate(1000)
            ])
        ])
    ]
})
export class ErroPopUpComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<ErroPopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErroPopupDataModel
    ) {
    }

    ngOnInit() {
    }

    public finalizar() {
        this.dialogRef.close();
    }
}

export class ErroPopupDataModel {
    public titulo: string;
    public mensagem: string;

    constructor() {
        this.titulo = 'Erro',
        this.mensagem = 'A operação não ocorreu como esperado. Inicie a operação novamente ou entre em contato com a equipe de suporte do Super Vade Mecum';
    }
}
