import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Pagina } from '../../models/Pagina';
import { PaginaResultadoBusca } from '../leitor-content-panelbuscaavancada/leitor-content-panelbuscaavancada.component';

@Component({
    selector: 'app-paginador',
    templateUrl: './paginador.component.html',
    styleUrls: ['./paginador.component.scss']
})
export class PaginadorComponent implements OnInit {


    @Input() public textoCentralPaginador: string;
    @Input() public paginas: PaginaResultadoBusca[];
    @Input() public indexAtual: number;

    @Output() public retroceder = new EventEmitter<void>();
    @Output() public avancar = new EventEmitter<void>();
    @Output() public irPara = new EventEmitter<PaginaResultadoBusca>();

    ngOnInit(): void {
    }


    public get paginaAtual() {
        return this.paginas[this.indexAtual];
    }

    constructor() {
        this.indexAtual = 0;
    }

    retroceder_click() {
        if (this.indexAtual === 0) {
            this.indexAtual = this.paginas.length - 1;
        } else {
            this.indexAtual--;
        }


        this.retroceder.emit();
    }

    avancar_click() {
        if (this.indexAtual === (this.paginas.length - 1)) {
            this.indexAtual = 0;
        } else {
            this.indexAtual++;
        }

        this.avancar.emit();
    }

    irPara_click(event: Event, pagina: PaginaResultadoBusca) {
        // event.stopPropagation();
        // event.preventDefault();

        const indexPaginaIr = this.paginas.findIndex(p => p.idLei === pagina.idLei);
        if (this.indexAtual === indexPaginaIr) {
            return;
        }

        this.indexAtual = indexPaginaIr;
        this.irPara.emit(pagina);
    }
}
