export class Tema {
    nome: string;
    background: string;
    thumbnail: string;
    foreground: string;
    tipoFundo: TipoFundo;
}

export enum TipoFundo {
    Imagem = 0,
    Cor = 1
}