import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { DataControle, EnumTipoDataControle } from "../models/dataControle";

const STORE_NAME = 'datasControle'

@Injectable()
export class DataControleRepositorio {
    constructor(
        private dbService: NgxIndexedDBService
    ) { }

    salvar(dataControle: DataControle): Promise<void> {
        return this.dbService.update(STORE_NAME, dataControle)
    }

    buscar(tipo: EnumTipoDataControle): Promise<DataControle> {
        return this.dbService.getByKey<DataControle>(STORE_NAME, tipo)
    }
}