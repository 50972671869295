import { Component, OnDestroy, ɵLocaleDataIndex } from "@angular/core";
import { ConteudoService } from "../../../services/conteudo.service";
import { Subscription } from "rxjs";
import { Conteudo } from "../../../models/pagina/conteudo";
import { TextoPagina } from "../../../models/pagina/TextoPagina";
import { TipoItem, FuncoesItem } from "../../../models/Item";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { PopupsService } from "../popups.service";

@Component({
    selector: "app-indice-sistematico",
    templateUrl: "./indice-sistematico.component.html",
    styleUrls: [
        "./indice-sistematico.component.scss",
        "../../../../styles/formatacao-texto.scss"
    ]
})
export class IndiceSistematicoComponent implements OnDestroy {

    indiceSistematicoCompleto = new Array<IndiceSistematicoItemModel>();
    treeControl = new NestedTreeControl<IndiceSistematicoItemModel>(node => node.children);
    dataSource = new MatTreeNestedDataSource<IndiceSistematicoItemModel>();
    hasChild = (_: number, node: IindiceSistematicoItemModel) => {
        return node.children && node.children.length > 0;
    };

    private _subscriptions = Array<Subscription>();
    constructor(
        private conteudoSerivce: ConteudoService,
        private popupsService: PopupsService
    ) {
        this._subscriptions.push(this.conteudoSerivce.Conteudo.subscribe(c => this.SubscribeConteudo(c)));
        this.dataSource.data.length >= 1
    }

    ngOnDestroy() {
        this._subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }

    private SubscribeConteudo(c: Conteudo) {
        this.GerarIndice(c.linhas);
        this.dataSource.data = this.indiceSistematicoCompleto;
    }

    private GerarIndice(linhas: Array<TextoPagina>) {

        let indiceSistematico = new Array<IndiceSistematicoItemModel>();

        // Verificar se é um dos tipos que serão incluídos no índice e se não é do tipo citação
        let reg = /(preambulo|parte|livro|titulo|ementa|capitulo|secao|subsecao)/gi;
        let LinhasIndice = linhas.filter(x => x.tipoTexto.match(reg) && x.versoes[x.indexVersao].revogado == false && x.tipoTexto.toLowerCase().indexOf("citacao") === -1)
            .map(x => <IndiceSistematicoItemModel>{
                itemId: x.id
                , texto: [x.versoes[x.indexVersao].prefixo, x.versoes[x.indexVersao].texto].join("")
                , index: linhas.indexOf(x)
                , TipoItem: FuncoesItem.identificarTipoItem(x)
                , tipoItemCssClass: x.tipoTexto
                , children: new Array<IndiceSistematicoItemModel>()
            });

        //Variveis para controle de relacionamento entre itens
        let ultimoItemPorTipo = new Array<IndiceSistematicoItemModel>();

        LinhasIndice.forEach(l => {
            let proximoItemTipoNecessario = LinhasIndice.find(x => x.TipoItem.valueOf() <= l.TipoItem.valueOf() && x.index > l.index);
            let indexLinhaIndiceProximo = proximoItemTipoNecessario ? proximoItemTipoNecessario.index : linhas.length - 1;

            //Identificar propriedades pendentes
            l.artigoDe = this.IdentificarPrimeiroArtigo(l.index, indexLinhaIndiceProximo, linhas);
            l.artigoAte = this.IdentificarUltimoArtigo(l.index, indexLinhaIndiceProximo, linhas);
            l.rangeArtigo = (l.TipoItem  == TipoItem.TituloPrincipal || l.TipoItem > TipoItem.Preambulo) ? this.identificarRangeArtigo(l.artigoDe, l.artigoAte) : "";
            for (let index = ultimoItemPorTipo.length - 1
                //Partes, Livros e titulos sempre serão root do indice, não serão filhos de ninguém
                ; !l.itemParentId && l.TipoItem.valueOf() > TipoItem.Titulo.valueOf() && index > 0
                ; index--) {
                if (ultimoItemPorTipo[index].TipoItem.valueOf() < l.TipoItem.valueOf()) {
                    l.itemParentId = ultimoItemPorTipo[index].itemId;
                }
            }

            //Atualizar utlimo item por tipo
            let ultimoItemTipoIndex = ultimoItemPorTipo.findIndex(x => x.TipoItem === l.TipoItem);
            if (ultimoItemTipoIndex === -1) {
                ultimoItemPorTipo.push(l);
            } else {
                ultimoItemPorTipo.splice(ultimoItemPorTipo.findIndex(x => x.TipoItem === l.TipoItem), 1, l);
            }

            indiceSistematico.push(l);
        });

        indiceSistematico.forEach(l => {
            l.children = indiceSistematico.filter(x => l.itemId === x.itemParentId);
            if (!l.itemParentId) {
                this.indiceSistematicoCompleto.push(l);
            }
        });

    }

    private IdentificarPrimeiroArtigo(indexFrom: number, indexMax: number, linhas: Array<TextoPagina>): string {
        var artPrefixo: string;

        const artigo = linhas.find(x => x.tipoTexto.toLowerCase() === "texto-artigo" && linhas.indexOf(x) > indexFrom && linhas.indexOf(x) < indexMax);
        if (artigo) {
            artPrefixo = artigo.versoes[artigo.indexVersao].prefixo;
        }

        return artPrefixo;
    }

    private IdentificarUltimoArtigo(indexFrom: number, indexMax: number, linhas: Array<TextoPagina>): string {
        var artPrefixo: string;

        for (indexMax; !artPrefixo && indexMax > indexFrom; indexMax--) {
            if (linhas[indexMax].tipoTexto.toLowerCase() === "texto-artigo") {
                const artigo = linhas[indexMax];
                artPrefixo = artigo.versoes[artigo.indexVersao].prefixo;
            }
        }

        return artPrefixo;
    }

    private identificarRangeArtigo(artigoDe: string, artigoAte: string) {
        artigoDe = artigoDe? artigoDe.trim() : "";
        artigoAte = artigoAte? artigoAte.trim() : "";

        // Quando não há nenhum artigo
        if (artigoDe === "" && artigoAte === "") { return ""; }

        // Quando há apenas 1 artigo
        if(artigoDe == artigoAte || (artigoDe && (!artigoAte || artigoAte === "")) || ((!artigoDe || artigoDe === "") && artigoAte)){
            if(!artigoDe || artigoDe === "") { return "("+ artigoAte.toLowerCase() +")";}
            if(!artigoAte || artigoAte === "") { return "("+ artigoDe.toLowerCase() +")";}
            return "("+ artigoDe +")";
        }

        // Quando há apenas 2 artigos
        let numeroDe = artigoDe.match(/\d+/).length > 0 ? parseInt(artigoDe.match(/\d+/)[0]) : 0
        let numeroAte = artigoAte.match(/\d+/).length > 0 ? parseInt(artigoAte.match(/\d+/)[0]) : 0
        if(numeroDe > 0 && numeroAte > 0 && numeroAte === numeroDe + 1){
            return "(arts. " + artigoDe.replace(/art[\t ]?[.-]?/i, "") 
            + " e "  
            + artigoAte.replace(/art[\t ]?[.-]?/i, "") + ")";
        }

        // Quando há vários artigos
        return "(arts. " + artigoDe.replace(/art[\t ]?[.-]?/i, "") 
            + " a "  
            + artigoAte.replace(/art[\t ]?[.-]?/i, "") + ")";

    }

    private IrParaItem(node: IndiceSistematicoItemModel) {
        this.popupsService.fecharPopups();
        this.conteudoSerivce.alterarIndexFoco(node.index);
    }
}

export interface IindiceSistematicoItemModel {
    itemId: string;
    itemParentId: string;
    children: Array<IndiceSistematicoItemModel>;
    TipoItem: TipoItem;
    index: number;
    texto: string;
    artigoDe: string;
    artigoAte: string;
}

export class IndiceSistematicoItemModel {
    itemId: string;
    itemParentId: string;
    children: Array<IndiceSistematicoItemModel>;
    TipoItem: TipoItem;
    tipoItemCssClass: string;
    index: number;
    texto: string;
    artigoDe: string;
    artigoAte: string;
    rangeArtigo: string;


}