import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { LeiRepositorio, LeiUpdateStatus } from "../../repositorios/lei.repositorio";
import { LeiDownload } from "../../arguments/lei/lei.download";

@Injectable()
export class DownloaderService {
    StatusDownload: Observable<LeiUpdateStatus>
    private statusDownload = new BehaviorSubject<LeiUpdateStatus>(LeiUpdateStatus.defaults())

    constructor(
        private leiRepositorio: LeiRepositorio
    ) {
        this.StatusDownload = this.statusDownload.asObservable()
    }

    public processar = () => new Promise(async (resolve) => {
        // console.log("downloader service - baixando leis para modo offline");
        let leisBaixadas = (await this.leiRepositorio.carregarLookup())
        const listaLeis = leisBaixadas
            .filter(lei => lei.baixada)
            .map(lei => LeiDownload.novo(lei.id, lei.titulo, lei.dataHoraUltimaModificacao, false))

        this.leiRepositorio.sincronizacaoLeis(listaLeis).subscribe(async status => {
            this.statusDownload.next(status)
        })

        // console.log("downloader service - finalizando download de leis para modo offline");
    })
}