import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConteudoService, ProgressoLeitura } from '../../services/conteudo.service';
import { Tempo } from '../../models/usuario/EstatisticasLeitura';
import { Subscription, Observable } from 'rxjs';
import { Conteudo } from '../../models/pagina/conteudo';
import { EntitiesHelper } from '../../helpers/entities.helper';
import { PopupsService } from '../popups/popups.service';
import { StatusService } from '../../services/status.service';
import { SignalrService } from '../../services/signalr.service';

@Component({
  selector: 'app-leitor-statusbar',
  templateUrl: './leitor-statusbar.component.html',
  styleUrls: ['./leitor-statusbar.component.scss']
})
export class LeitorStatusbarComponent implements OnInit, OnDestroy {
  public progresso: number;
  public tempoRestante: Tempo;
  public mostrar: boolean;

  private subscriptions: Subscription[] = [];
  private progressoLeitura: ProgressoLeitura;

  conteudo: Observable<Conteudo>;

  public offline: boolean;
  public pendingChanges: number;

  constructor(
    private conteudoService: ConteudoService,
    private popupsService: PopupsService,
    private statusService: StatusService,
    private signalrService: SignalrService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.conteudoService.Conteudo.subscribe(c => this.conteudo_changed(c)));
    this.statusService.$AppOffline.subscribe(offline => this.offline = offline)
    this.signalrService.PendingChanges.subscribe(pendingChanges => this.pendingChanges = pendingChanges)
    this.conteudo = this.conteudoService.Conteudo
  }

  conteudo_changed(c: Conteudo): void {
    this.mostrar = c ? true : false;
    console.log(this.progressoLeitura);
    console.log(c.progressoLeitura);
    if (this.mostrar && !EntitiesHelper.equals(this.progressoLeitura, c.progressoLeitura)) {
      this.progresso_changed(c.progressoLeitura);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  private progresso_changed(progresso: ProgressoLeitura) {
    this.progressoLeitura = progresso;
    console.log(progresso);
    if (progresso) {
      this.progresso = progresso.progresso;
      this.tempoRestante = progresso.tempoRestante;
    } else {
      this.progresso = null;
      this.tempoRestante = null;
    }
  }

  public btnFeedback_click(): void {
    this.popupsService.abrirPopupFeedback();
  }
}
