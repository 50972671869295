export class NumberHelper {
    static isValidNumberOrRoman(number: string): boolean {
        return this.isNumber(number) || this.isRomanNumber(number)
    }

    static rangeContem(range1: Range, range2: Range): boolean {
        let retorno = false;

        if (!range2.de && !range2.ate) {
            retorno = true;
        } else if (!range2.de && range2.ate >= range1.de) {
            retorno = true;
        } else if (!range2.ate && range2.de <= range1.ate) {
            retorno = true;
        } else if (range2.de && range2.ate) {
            if (range2.de >= range1.de && range2.de <= range1.ate) {
                retorno = true;
            } else if (range2.ate >= range1.de && range2.ate <= range1.ate) {
                retorno = true;
            }
        }

        return retorno;
    }

    static isNumber(str): boolean {
        var retValue = false;
        if (str !== null) {
            if (str.length > 0) {
                if (!isNaN(str)) {
                    retValue = true;
                }
            }
        }
        return retValue;
    }

    static isRomanNumber(number: string): boolean {
        var _regexp = /^((\(M\)){0,3})(\(C\)\(M\)|\(C\)\(D\)|(\(D\))?(\(C\)){0,3})(\(X\)\(C\)|\(X\)\(L\)|(\(L\))?(\(X\)){0,3})(M\(X\)|M\(V\)|(\(V\))?)(M{0,3})(CM|CD|D?C{0,3})(XC|XL|L?X{0,3})(IX|IV|V?I{0,3})$/;
		return _regexp.test(number);
    }

    static romanize(num) {
        if (isNaN(num))
            return NaN;
        var digits = String(+num).split(""),
            key = ["", "C", "CC", "CCC", "CD", "D", "DC", "DCC", "DCCC", "CM",
                "", "X", "XX", "XXX", "XL", "L", "LX", "LXX", "LXXX", "XC",
                "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX"],
            roman = "",
            i = 3;
        while (i--)
            roman = (key[+digits.pop() + (i * 10)] || "") + roman;
        return Array(+digits.join("") + 1).join("M") + roman;
    }

    static unRomanize(str1) {
        if (str1 == null) return -1;

        var num = this.char_to_int(str1.charAt(0));
        var pre, curr;

        for (var i = 1; i < str1.length; i++) {
            curr = this.char_to_int(str1.charAt(i));
            pre = this.char_to_int(str1.charAt(i - 1));
            if (curr <= pre) {
                num += curr;
            } else {
                num = num - pre * 2 + curr;
            }
        }

        return num;
    }

    private static char_to_int(c) {
        switch (c) {
            case 'I': return 1;
            case 'V': return 5;
            case 'X': return 10;
            case 'L': return 50;
            case 'C': return 100;
            case 'D': return 500;
            case 'M': return 1000;
            default: return -1;
        }
    }
}

export class Range {
    de: number;
    ate: number;

    constructor(de, ate) {
        this.de = de;
        this.ate = ate;
    }
}