import { DBConfig } from 'ngx-indexed-db';
import { Injectable } from '@angular/core';

// Ahead of time compiles requires an exported function for factories
export function migrationFactory() {
    // Adicionar numero da versão e tabela que precisa ser criada/recriada

    // The animal table was added with version 2 but none of the existing tables or data needed
    // to be modified so a migrator for that version is not included.
    return {
        // 1: (db, transaction) => {
        //     const store = transaction.objectStore('people');
        //     store.createIndex('country', 'country', { unique: false });
        // },
        // 3: (db, transaction) => {
        //     const store = transaction.objectStore('people');
        //     store.createIndex('age', 'age', { unique: false });
        // }
    };
}

export const dbConfig: DBConfig = {
    name: 'svdm',
    version: 6,
    objectStoresMeta: [{
        store: 'lei-info',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'lei-conteudo',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'lei-download',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'buscas',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
            { name: 'guia', keypath: 'guia', options: { unique: true } }
        ]
    }, {
        store: 'prova-instituicoes',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'prova-bancas',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'prova-anos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'prova-tipos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'prova-cargos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'guias',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'marcacoes',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'comentarios',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'grifos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'apontamentos',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'estatisticas',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'userdata',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: []
    }, {
        store: 'datasControle',
        storeConfig: { keyPath: 'tipo', autoIncrement: false },
        storeSchema: []
    }
    ,{
        store: 'errorLog',
        storeConfig: { keyPath: 'clientId', autoIncrement: false },
        storeSchema: []
    }],
    // provide the migration factory to the DBConfig
    migrationFactory
};