import { TipoSelecao } from "../services/selection.service";
import { ParametrosCaneta } from "./UserData";
import { RangeIntervalo } from "./range.intervalo";
import { TipoIntervalo } from "../enums/tipo.intervalo";

export class Intervalo {
    public idOrigem: string;
    public ranges: RangeIntervalo[];
    public tipoIntervalo: TipoIntervalo;
    public tipoSelecao: TipoSelecao;
    public destacar: boolean;
    public dataAlteracao: Date;
  
    public constructor(idOrigem: string, tipoIntervalo: TipoIntervalo, destacar: boolean, de: number, ate: number, dataAlteracao: Date, tipoSelecao: TipoSelecao = null) {
      this.idOrigem = idOrigem;
      this.tipoIntervalo = tipoIntervalo;
      this.destacar = destacar;
      this.tipoSelecao = tipoSelecao;
      this.dataAlteracao = dataAlteracao;
  
      this.ranges = new Array<RangeIntervalo>();
      const range = new RangeIntervalo();
      range.de = de;
      range.ate = ate;
      range.ignorar = false;
  
      this.ranges.push(range);
    }
  
    public getTextDecoration(p: ParametrosCaneta): string {
      if ((!this.destacar && (this.tipoIntervalo === TipoIntervalo.MarcacaoProva || this.tipoIntervalo === TipoIntervalo.Comentario || this.tipoIntervalo === TipoIntervalo.Mnemonico))
        || this.tipoIntervalo === TipoIntervalo.Caneta1 || this.tipoIntervalo === TipoIntervalo.Caneta2 || this.tipoIntervalo === TipoIntervalo.Caneta3
        || this.tipoIntervalo === TipoIntervalo.Caneta4 || this.tipoIntervalo === TipoIntervalo.Caneta5) {
        let cor: string;
        switch (this.tipoIntervalo) {
          case TipoIntervalo.MarcacaoProva:
            cor = p.corTagProva;
            break;
          case TipoIntervalo.Comentario:
            cor = p.corTagComentario;
            break;
          case TipoIntervalo.Mnemonico:
            cor = p.corTagMnemonico;
            break;
          case TipoIntervalo.Caneta1:
            cor = (p.corCaneta1.indexOf('rgb') > -1) ? this.rgbToHex(p.corCaneta1) : p.corCaneta1;
            break;
          case TipoIntervalo.Caneta2:
            cor = (p.corCaneta2.indexOf('rgb') > -1) ? this.rgbToHex(p.corCaneta2) : p.corCaneta2;
            break;
          case TipoIntervalo.Caneta3:
            cor = (p.corCaneta3.indexOf('rgb') > -1) ? this.rgbToHex(p.corCaneta3) : p.corCaneta3;
            break;
          case TipoIntervalo.Caneta4:
            cor = (p.corCaneta4.indexOf('rgb') > -1) ? this.rgbToHex(p.corCaneta4) : p.corCaneta4;
            break;
          case TipoIntervalo.Caneta5:
            cor = (p.corCaneta5.indexOf('rgb') > -1) ? this.rgbToHex(p.corCaneta5) : p.corCaneta5;
            break;
        }
        return `underline ${cor}`;
      }
  
      return null;
    }
  
    public getBackgroundColor(p: ParametrosCaneta): string {
      let cor = null;
  
      if (this.tipoIntervalo === TipoIntervalo.Caneta1 || this.tipoIntervalo === TipoIntervalo.Caneta2 || this.tipoIntervalo === TipoIntervalo.Caneta3
        || this.tipoIntervalo === TipoIntervalo.Caneta4 || this.tipoIntervalo === TipoIntervalo.Caneta5) {
        switch (this.tipoSelecao) {
          case TipoSelecao.Caneta1:
            cor = p.corCaneta1;
            break;
          case TipoSelecao.Caneta2:
            cor = p.corCaneta2;
            break;
          case TipoSelecao.Caneta3:
            cor = p.corCaneta3;
            break;
          case TipoSelecao.Caneta4:
            cor = p.corCaneta4;
            break;
          case TipoSelecao.Caneta5:
            cor = p.corCaneta5;
            break;
        }
      } else if (this.tipoIntervalo === TipoIntervalo.ResultadoBusca) {
        cor = (this.destacar) ? ParametrosCaneta.corResultadoBuscaDestaque : ParametrosCaneta.corResultadoBuscaNormal;
      } else {
        cor = (this.destacar) ? ParametrosCaneta.corDestaqueHover : null;
      }
  
      return cor;
    }
  
    public getModoRealce(p: ParametrosCaneta): string {
  
      let modo = null;
  
      switch (this.tipoSelecao) {
        case TipoSelecao.Caneta1:
          modo = p.modoRealceCaneta1;
          break;
        case TipoSelecao.Caneta2:
          modo = p.modoRealceCaneta2;
          break;
        case TipoSelecao.Caneta3:
          modo = p.modoRealceCaneta3;
          break;
        case TipoSelecao.Caneta4:
          modo = p.modoRealceCaneta4;
          break;
        case TipoSelecao.Caneta5:
          modo = p.modoRealceCaneta5;
          break;
      }
      return modo;
    }
  
    public rgbToHex(color) {
      color = "" + color;
      if (!color || color.indexOf("rgb") < 0) {
        return;
      }
  
      if (color.charAt(0) == "#") {
        return color;
      }
  
      let nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color),
        r = parseInt(nums[2], 10).toString(16),
        g = parseInt(nums[3], 10).toString(16),
        b = parseInt(nums[4], 10).toString(16);
  
      return "#" + (
        (r.length == 1 ? "0" + r : r) +
        (g.length == 1 ? "0" + g : g) +
        (b.length == 1 ? "0" + b : b)
      );
    }
  
  }