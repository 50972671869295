import { Versao } from './Versao';
import { TextoPagina } from './pagina/TextoPagina';

export class Item {
    id: string;
    // idConjunto: string;
    idLei: string;
    itemCitacao: string;
    versao: Versao;
    versoes: Array<Versao>;
    // identificador: string;
    tipoItem: TipoItem;
    tipoNaoIdentificado: boolean;

    constructor() {
        this.versoes = new Array<Versao>();
    }
}

export enum TipoItem {
    TituloPrincipal = 1,
    Preambulo = 2,
    Parte = 3,
    Livro = 4,
    Titulo = 5,
    Ementa = 6,
    Capitulo = 7,
    Secao = 8,
    SubSecao = 9,
    Artigo = 10,
    Paragrafo = 11,
    Inciso = 12,
    Alinea = 13,
    Rodape = 14,
    Sumula = 15,
    IncisoSumula = 16,
    RodapeSumula = 17,
    ParagrafoInternacional = 18,
    AlineaInternacional = 19,
    IncisoInternacional = 20,
    LinhaTabela = 98,
    Desconhecido = 99
}

export class FuncoesItem {

    public static identificarTipoItem(itemTexto: TextoPagina): TipoItem {
        let ret: TipoItem

        switch (itemTexto.tipoTexto) {
            case "texto-tituloprincipal":
                ret = TipoItem.TituloPrincipal;
                break;
            case "texto-preambulo":
                ret = TipoItem.Preambulo;
                break;
            case "texto-parte":
                ret = TipoItem.Parte;
                break;
            case "texto-livro":
                ret = TipoItem.Livro;
                break;
            case "texto-titulo":
                ret = TipoItem.Titulo;
                break;
            case "texto-ementa":
                ret = TipoItem.Ementa;
                break;
            case "texto-capitulo":
                ret = TipoItem.Capitulo;
                break;
            case "texto-secao":
                ret = TipoItem.Secao;
                break;
            case "texto-subsecao":
                ret = TipoItem.SubSecao;
                break;
            case "texto-artigo":
                ret = TipoItem.Artigo;
                break;
            case "texto-paragrafo":
                ret = TipoItem.Paragrafo;
                break;
            case "texto-inciso":
                ret = TipoItem.Inciso;
                break;
            case "texto-alinea":
                ret = TipoItem.Alinea;
                break;
            case "texto-rodape":
                ret = TipoItem.Rodape;
                break;
            case "texto-sumula":
                ret = TipoItem.Sumula;
                break;
            case "texto-inciso":
                ret = TipoItem.Inciso;
                break;
            case "texto-rodape":
                ret = TipoItem.Rodape;
                break;
            case "texto-linhatabela":
                ret = TipoItem.LinhaTabela;
                break;
            case "texto-desconhecido":
                ret = TipoItem.Desconhecido;
                break;
        }

        return ret;
    }

    static descreverTipoItem(item: Partial<Item>): string {

        let ret = 'Desconhecido';
        const citacao = item.itemCitacao ? ' citacao' : '';

        if (item.tipoNaoIdentificado) {
            return ret;
        }

        switch (item.tipoItem) {
            case 1:
                ret = 'TituloPrincipal';
                break;
            case 2:
                ret = 'Preambulo';
                break;
            case 3:
                ret = 'Parte';
                break;
            case 4:
                ret = 'Livro';
                break;
            case 5:
                ret = 'Titulo';
                break;
            case 6:
                ret = 'Ementa';
                break;
            case 7:
                ret = 'Capitulo';
                break;
            case 8:
                ret = 'Secao';
                break;
            case 9:
                ret = 'SubSecao';
                break;
            case 10:
                ret = 'Artigo';
                break;
            case 11:
                ret = 'Paragrafo';
                break;
            case 12:
                ret = 'Inciso';
                break;
            case 13:
                ret = 'Alinea';
                break;
            case 14:
                ret = 'Rodape';
                break;
            case 15:
                ret = 'Sumula';
                break;
            case 16:
                ret = 'Inciso';
                break;
            case 17:
                ret = 'Rodape';
                break;
            case 18:
                // O tratamento do front deve ser igual ao paragrafo comum
                ret = 'Paragrafo';
                break;
            case 19:
                // O tratamento do front deve ser igual a alinea comum
                ret = 'Alinea';
                break;
            case 20:
                // O tratamento do front deve ser igual ao inciso comum
                ret = 'Inciso';
                break;
            case 98:
                ret = 'LinhaTabela';
        }

        return ret + citacao;
    }
}
