import { Injectable, ErrorHandler } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { HttpClient, HttpErrorResponse, HttpRequest, HttpEventType ,HttpHeaders} from "@angular/common/http";
import { LeiStorage } from "../models/lei/lei.storage";
import { CabecalhoLeiResponse } from "../arguments/lei/cabecalho.lei.response";
import { AppConfig } from "../app.config";
import { CompressedResult, CompressionHelper } from "../helpers/compression.helper";
import { LeiInfo } from "../models/lei/lei.info";
import { LeiConteudo } from "../models/lei/lei.conteudo";
import { LeiLookup } from "../models/lei/lei.lookup";
import { Lei } from "../models/Lei";
import { StatusService } from "../services/status.service";
import { DataControleRepositorio } from "./dataControle.repositorio";
import { EnumTipoDataControle } from "../models/dataControle";
import { LeiDownloadResponse } from "../arguments/lei/lei.download.response";
import { LeiDownload } from "../arguments/lei/lei.download";
import { LocalDataService } from "../services/data-services/local.data.service";
import { Observable, Subscriber } from "rxjs";
import { IdDataLei } from "../models/lei/id.data.lei";
import { isDataSource } from "@angular/cdk/collections";
import { kMaxLength } from "buffer";
import { ErrorHandlerService } from "../services/errorHandler.service";
import { ErrorLogParameters } from "../models/error/errorLogParameters";
import { ObterLeiEstudado } from "../models/lei/list.article";
import { AuthService } from "../modules/shared/services/auth.service";



const LEI_INFO_STORE = 'lei-info'
const LEI_CONTEUDO_STORE = 'lei-conteudo'
const LEI_TEMP_STORE = 'lei-download'

@Injectable()
export class LeiRepositorio {
    constructor(
        private dbService: NgxIndexedDBService,
        private dataControleRepositorio: DataControleRepositorio,
        private statusService: StatusService,
        private httpClient: HttpClient,
        private localDataService: LocalDataService,
        private errorHandlerService: ErrorHandlerService,
        private authenticationService: AuthService,
    ) { }

    private listarOffline(): Promise<LeiStorage[]> {
        return new Promise(async resolve => {
            const leis = (await this.dbService.getAll<LeiInfo>(LEI_INFO_STORE)).map(info => LeiStorage.Load(info, null))
            const temp = await this.listarTemp()

            temp.forEach(async t => {
                const iLei = leis.map(l => l.id).indexOf(t.id)
                if (iLei === -1)
                    leis.push(t)
                else
                    leis[iLei].disponivel = true
            })
            resolve(leis)
        })
    }

    private async listarTemp(): Promise<LeiStorage[]> {
        let leis: LeiStorage[] = []

        const json = localStorage.getItem(LEI_TEMP_STORE)
        if (json)
            leis = JSON.parse(json)

        return leis.map(l => LeiStorage.Load(l, null))
    }

    private listarOnline(idsLeisOffline: IdDataLei): Promise<LeiStorage[]> {
        const url = `${AppConfig.apiEndpoint}/leis/cabecalhos`
        return new Promise(async (resolve) => {
            try {
                idsLeisOffline = !idsLeisOffline ? new IdDataLei() : idsLeisOffline;

                // console.log("listarOnline - Data enviada para sincronização: " + idsLeisOffline.MaiorDataAlteracaoApp.toLocaleDateString());
                const result = <CompressedResult>(await this.httpClient.post(url, idsLeisOffline).toPromise())
                var json = CompressionHelper.unzip(result)
                const leis = (<CabecalhoLeiResponse[]>JSON.parse(json)).map(l => CabecalhoLeiResponse.toLeiStorage(l))
                resolve(leis)
            } catch (error) {
                throw error;
            }
        })
    }

    public Getlist(id:string) {
        const userId = this.authenticationService.userID
        const url = `${AppConfig.apiEndpoint}/planodeestudo/obterLeiIten/`+ id + `/` + userId;

        const header = new HttpHeaders().set(
            "Authorization",
              localStorage.getItem('AccessToken')
          );

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.get(url,{headers:header}).toPromise())
                var json = CompressionHelper.unzip(result)
                const listData = JSON.parse(json);
                resolve(listData)

            } catch (error) {
                throw error;
            }
        })
    }

    public GetReadingSpeedlist() {
        // const url = `${AppConfig.apiEndpoint}/planodeestudo/obterestudadoetail`;
        const userId = this.authenticationService.userID;
        const url = `${AppConfig.apiEndpoint}/planodeestudo/obterestudadodetail/` + userId;
        const header = new HttpHeaders().set(
            "Authorization",
              localStorage.getItem('AccessToken')
          );

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.get(url,{headers:header}).toPromise())
                var json = CompressionHelper.unzip(result)
                const listData = JSON.parse(json);
                resolve(listData.amostradeConteudo)
            } catch (error) {
                throw error;
            }
        })
    }

    public GetStudyPlanData() {
        const userId = this.authenticationService.userID
        const url = `${AppConfig.apiEndpoint}/planodeestudo/obterestudadodetail/` + userId;
        const header = new HttpHeaders().set(
            "Authorization",
              localStorage.getItem('AccessToken')
          );

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.get(url,{headers:header}).toPromise())
                var json = CompressionHelper.unzip(result)
                const listData = JSON.parse(json);
                resolve(listData)
            } catch (error) {
                throw error;
            }
        })
    }
    public GetProgressBarData() {
      const userId = this.authenticationService.userID
      const url = `${AppConfig.apiEndpoint}/planodeestudo/progressorelatorio/` + userId;
      const header = new HttpHeaders().set(
          "Authorization",
            localStorage.getItem('AccessToken')
        );

      return new Promise(async (resolve) => {
          try {
              const result = <CompressedResult>(await this.httpClient.get(url,{headers:header}).toPromise())
              var json = CompressionHelper.unzip(result)
              const listData = JSON.parse(json);
              resolve(listData)
          } catch (error) {
              throw error;
          }
      })
  }
    public SaveStudyPlanData(sendData) {
        const url = `${AppConfig.apiEndpoint}/planodeestudo/salvar`;
        const header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('AccessToken')
          );

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.post(url,sendData,{headers:header}).toPromise())
                var json = CompressionHelper.unzip(result)
                const listData = JSON.parse(json);
                resolve(listData)
            } catch (error) {
                throw error;
            }
        })
    }

    public getReadCount(sendData) {
        const url = `${AppConfig.apiEndpoint}/planodeestudo/counttotallinhas`;
        const header = new HttpHeaders().set(
            "Authorization",
            localStorage.getItem('AccessToken')
          );

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.post(url,sendData,{headers:header}).toPromise())
                var json = CompressionHelper.unzip(result)
                const listData = JSON.parse(json);
                resolve(listData)
            } catch (error) {
                throw error;
            }
        })
    }



    private salvar(lei: LeiStorage): Promise<void> {
        return new Promise(async (resolve) => {
            try {
                const tasks: Promise<void>[] = []

                tasks.push(this.dbService.update(LEI_INFO_STORE, LeiInfo.fromStorage(lei)))
                if (lei.dados)
                    tasks.push(this.dbService.update(LEI_CONTEUDO_STORE, LeiConteudo.fromStorage(lei)))

                await Promise.all(tasks)
                resolve()
            } catch (err) {
                throw err;
            }
        })
    }

    private async buscarLocal(id: string): Promise<LeiStorage> {
        const leiTemp = await this.dbService.getByKey<LeiConteudo>(LEI_TEMP_STORE, id)

        if (leiTemp)
            await this.salvarLeiDownload(id)

        const leiInfo = await this.dbService.getByKey<LeiInfo>(LEI_INFO_STORE, id)
        const leiConteudo = await this.dbService.getByKey<LeiConteudo>(LEI_CONTEUDO_STORE, id)

        if (leiInfo && leiConteudo)
            return LeiStorage.Load(leiInfo, leiConteudo)
        else if (this.canDownloadLei()) {
            const lei = await this.baixarLei(id)
            if (lei)
                return (lei)
            else
                return null;
        }
    }

    private buscarOnline(id: string): Promise<LeiStorage> {
        const url = `${AppConfig.apiEndpoint}/leis/conteudo/${id}`

        return new Promise(async (resolve) => {
            try {
                const result = <CompressedResult>(await this.httpClient.get(url).toPromise())
                const lei = <Lei>JSON.parse(CompressionHelper.unzip(result))
                const leiStorage = LeiStorage.FromLei(lei)

                resolve(leiStorage)
            } catch (err) {
                let params = new Array<ErrorLogParameters>();
                params.push({ name: "urlEndpoint", value: url });
                this.errorHandlerService.handleError(this.errorHandlerService, "Erro em leiRepositorio.bucarOnline", params);
            }
        });
    }

    private canDownloadLei() {
        return this.statusService.isAppOnline
    }

    private async canCheckLeis() {

        // console.log("modo offline ativado? " + this.localDataService.modoOffline);
        if (this.localDataService.modoOffline) {
            // console.log("gerenciamento de leis offline realizará a atualização das leis");
            return false;
        }


        const hoje = new Date();
        // console.log("data local: " + hoje.toLocaleDateString());
        const dataControle = await this.dataControleRepositorio.buscar(EnumTipoDataControle.DataVerificacaoListaLeis)
        // console.log("data de útlima checagem de leis: " + (dataControle ? dataControle.data.toLocaleDateString() : "null"));
        const checadoHoje = dataControle
            && dataControle.data.getDay() === hoje.getDay()
            && dataControle.data.getFullYear() === hoje.getFullYear()
            ? true : false;

        // console.log("checagem já foi realizada hoje? " + checadoHoje);
        return !checadoHoje && this.canDownloadLei();
    }

    atualizarLista(): Promise<void> {
        return new Promise(async resolve => {

            const leisOffline = await this.listarOffline()
            let leisOnline = new Array<LeiStorage>();

            await this.statusService.testConnection();
            // console.log("app está onine? " + this.statusService.isAppOnline);
            if (this.statusService.isAppOnline) {

                let idLeisOfflineMaiorData = new IdDataLei();
                idLeisOfflineMaiorData.ids = leisOffline.map(lei => lei.id);
                leisOffline.forEach(l => {
                    let dataLei = l.dataHoraUltimaModificacaoTextoLei ? new Date(l.dataHoraUltimaModificacaoTextoLei) : null;
                    idLeisOfflineMaiorData.MaiorDataAlteracaoApp = dataLei && dataLei > idLeisOfflineMaiorData.MaiorDataAlteracaoApp
                        ? dataLei
                        : idLeisOfflineMaiorData.MaiorDataAlteracaoApp
                });

                leisOnline = await this.listarOnline(idLeisOfflineMaiorData);
            };
            // console.log("Qtd. leis online identificadas: " + leisOnline.length);

            for (let lei of leisOnline) {
                const leiAtualizar = leisOffline.find(l => l.id == lei.id)

                if (leiAtualizar) {
                    if (!leiAtualizar.dataHoraModificacao || lei.dataHoraModificacao > leiAtualizar.dataHoraModificacao) {
                        await this.salvar(lei)
                    }
                    // console.log("Lei " + lei.descricao + " atualizada");
                }
                else {
                    await this.salvar(lei)
                    // console.log("Lei " + lei.descricao + " inserida");
                }
            }

            //if (leisOnline.length > 0)
            await this.dataControleRepositorio.salvar({ data: new Date(), tipo: EnumTipoDataControle.DataVerificacaoListaLeis });
            // console.log("data de controle (Data_Verificacao_ListaLeis) de leis atualizada");

            resolve()
        })
    }

    carregarLookup(onlineOnly = false): Promise<LeiLookup[]> {
        return new Promise(async (resolve) => {
            try {
                if (await this.canCheckLeis()) {
                    await this.atualizarLista()
                } else if (onlineOnly) { resolve(null); return; }

                const leiStorageCollection = await this.listarOffline()
                const leis = leiStorageCollection.map(lei => LeiLookup.fromLeiStorage(lei))

                resolve(leis)
            }
            catch (error) {
                throw error;
            }
        })
    }

    carregarLei(id: string): Promise<Lei> {
        return new Promise(async (resolve, reject) => {
            try {
                let leiStorage = await this.buscarLocal(id)
                const lei = LeiStorage.ToLei(leiStorage)

                resolve(lei)
            } catch (err) {
                reject(err)
            }
        })
    }

    carregarItemLookup(id: string): Promise<LeiLookup> {
        return new Promise(async resolve => {
            let leiStorage = await this.buscarLocal(id)

            const itemLookup = LeiLookup.fromLeiStorage(leiStorage)
            itemLookup.baixada = leiStorage.dados ? true : false

            resolve(itemLookup)
        })
    }

    baixarLei(id: string): Promise<LeiStorage> {
        return new Promise(async (resolve) => {
            try {
                const leiStorage = await this.buscarOnline(id)
                await this.salvar(leiStorage)

                resolve(leiStorage)
            } catch (error) {
                if (error instanceof (HttpErrorResponse) && (<HttpErrorResponse>error).status == 404) {
                    await this.removerLei(id);
                    resolve(null);
                } else {
                    throw error;
                }

            }

        })
    }

    async removerLei(id: string) {
        await this.dbService.delete(LEI_INFO_STORE, id)
        await this.dbService.delete(LEI_CONTEUDO_STORE, id)
    }

    sincronizacaoLeis(leis: LeiDownload[]): Observable<LeiUpdateStatus> {
        // console.log("Lei repositorio_SincronizacaoLeis - baixando leis para modo offline");
        const status = new LeiUpdateStatus()
        status.step = LeiUpdateStep.verificandoServidor
        status.leis = leis

        const url = `${AppConfig.apiEndpoint}/leis/sync`
        let idLeis = new IdDataLei();
        idLeis.ids = leis.map(lei => lei.id);
        leis.forEach(l => {
            let dataLei = l.DataHoraUltimaModificacao ? new Date(l.DataHoraUltimaModificacao) : null;
            idLeis.MaiorDataAlteracaoApp = dataLei && dataLei > idLeis.MaiorDataAlteracaoApp
                ? dataLei
                : idLeis.MaiorDataAlteracaoApp
        });

        let response: LeiDownloadResponse[] = null

        const request = (observer: Subscriber<LeiUpdateStatus>) => new Promise<void>(async (resolve) => {
            setTimeout(async () => {

                // console.log("Iniciando sincronização. MaiorDataAlteracao: ") + idLeis.MaiorDataAlteracaoApp.toDateString();

                const request = new HttpRequest('POST', url, idLeis, {
                    reportProgress: true,
                })

                this.httpClient.request(request).subscribe(event => {
                    if (event.type === HttpEventType.DownloadProgress) {
                        status.step = LeiUpdateStep.baixandoLeis
                        status.packageSize = event.loaded
                        // console.log("baixando leis atualizadas zip. tamanho: " + event.loaded);
                        updateStatus(observer)
                    } else if (event.type == HttpEventType.Response) {
                        response = <LeiDownloadResponse[]>event.body
                        for (let i = 0; i < response.length; i++) {
                            const leiResponse = response[i]
                            const iLeiDownload = leis.findIndex(l => l.id === leiResponse.id)

                            if (iLeiDownload === -1) {
                                status.leis.push(LeiDownload.novo(leiResponse.id, leiResponse.descricao, leiResponse.DataHoraUltimaModificacao, true))
                                // console.log("baixando lei: " + leiResponse.descricao);
                            }
                            else {
                                status.leis[iLeiDownload].baixar = true
                            }
                        }

                        status.leis.forEach(lei => {
                            lei.concluido = !lei.baixar
                        })

                        updateStatus(observer)
                        resolve();
                    }
                })

                // response = <LeiDownloadResponse[]>(await this.httpClient.post(url, idLeis).toPromise())

            });
        })

        const gravar = (observer: Subscriber<LeiUpdateStatus>) => new Promise<void>(async resolve => {
            status.step = LeiUpdateStep.atualizandoBase
            updateStatus(observer)

            setTimeout(async () => {
                const tempJson = localStorage.getItem(LEI_TEMP_STORE)
                const leiInfoTemp: LeiInfo[] = (!tempJson) ? [] : JSON.parse(tempJson)

                for (let i = 0; i < response.length; i++) {
                    const leiResponse = response[i]
                    const iLeiDownload = leis.findIndex(l => l.id === leiResponse.id)

                    status.leis[iLeiDownload].baixando = true
                    updateStatus(observer)

                    await this.dbService.update(LEI_TEMP_STORE, leiResponse)

                    if (leiInfoTemp.findIndex(l => l.id === leiResponse.id) === -1)
                        leiInfoTemp.push(LeiInfo.fromLeiDownloadResponse(leiResponse))

                    status.leis[iLeiDownload].baixando = false
                    status.leis[iLeiDownload].concluido = true
                    // console.log("Lei repositorio_SincronizacaoLeis - inserindo lei: " + leiResponse.descricao);
                    updateStatus(observer)
                }

                localStorage.setItem(LEI_TEMP_STORE, JSON.stringify(leiInfoTemp))
                resolve()
            });
        })

        const updateStatus = (observer: Subscriber<LeiUpdateStatus>) => {
            const nextStatus = Object.assign(new LeiUpdateStatus(), status)
            observer.next(nextStatus)
        }

        return new Observable<LeiUpdateStatus>(observer => {
            updateStatus(observer)
            request(observer).then(() => {
                gravar(observer).then(() => {
                    status.step = LeiUpdateStep.concluido
                    updateStatus(observer)
                })
            })
        })
    }

    public async salvarLeiDownload(idLei: string) {
        try {
            const tempLei: LeiDownloadResponse = await this.dbService.getByKey<LeiDownloadResponse>(LEI_TEMP_STORE, idLei)
            const jsonLei = CompressionHelper.strUnzip(tempLei.jsonData)

            const lei = <Lei>JSON.parse(jsonLei)
            const leiStorage = LeiStorage.FromLei(lei)

            await this.salvar(leiStorage)

            const json = localStorage.getItem(LEI_TEMP_STORE)
            if (json) {
                let leis: LeiInfo[] = JSON.parse(json)
                leis = leis.filter(l => l.id !== idLei)
                localStorage.setItem(LEI_TEMP_STORE, JSON.stringify(leis))
            }

            await this.dbService.delete(LEI_TEMP_STORE, idLei)
        } catch (error) {
            throw error
        }
    }

    public async clearRepository(){
        try {
            await this.dbService.clear(LEI_INFO_STORE);
        } catch (err) {
            throw new Error(`Erro em leiRepositorio.ClearRepository. Detalhes: ${err.message}`);
        }
    }
}

export class LeiUpdateStatus {
    step: LeiUpdateStep
    packageSize: number
    leis: LeiDownload[] = []

    get leiBaixando() {
        const filter = this.leis.filter(l => l.baixando)
        return filter.length > 0 ? filter[0] : null
    }

    get leisBaixar() {
        return this.leis.filter(l => l.baixar && !l.concluido)
    }

    get leisAtualizadas() {
        return this.leis.filter(l => l.concluido)
    }

    get packageSizeKB() {
        if (!this.packageSize)
            return this.packageSize

        return `${Math.round(this.packageSize / 1024 * 100) / 100} KB`
    }

    get packageSizeMB() {
        if (!this.packageSize)
            return this.packageSize

        return `${Math.round(this.packageSize / 1024 / 1024 * 100) / 100} MB`
    }

    get displayPackageSize() {
        if (!this.showDownloadSize)
            return ''

        return Math.round(this.packageSize / 1024 / 1024) > 1 ? this.packageSizeMB : this.packageSizeKB
    }

    get progress(): number {
        if (this.step === LeiUpdateStep.concluido)
            return 100

        const quantidadeLeisBaixar = this.leis.filter(l => l.baixar).length
        const quantidadeLeisBaixadas = this.leis.filter(l => l.baixar && l.concluido).length

        if (!quantidadeLeisBaixar && !quantidadeLeisBaixadas)
            return null

        let progresso = quantidadeLeisBaixadas / quantidadeLeisBaixar
        progresso = progresso * 100 * 10
        progresso = Math.round(progresso) / 10

        return progresso
    }

    get showDownloadSize() {
        return this.step >= 2 && Math.round(this.packageSize / 1024)
    }

    static defaults(): LeiUpdateStatus {
        const retorno = new LeiUpdateStatus()

        retorno.step = LeiUpdateStep.verificandoLeisBaixadas

        return retorno
    }
}

export enum LeiUpdateStep {
    verificandoLeisBaixadas,
    verificandoServidor,
    baixandoLeis,
    atualizandoBase,
    concluido
}
