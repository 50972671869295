import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
    selector: 'app-leitor-content-panelbuscaprogresso',
    templateUrl: './leitor-content-panelbuscaprogresso.component.html',
    styleUrls: ['./leitor-content-panelbuscaprogresso.component.css'],
    animations: [
        trigger('msgCarregamento', [
            transition('* <=> *', [
                style({ opacity: 0 }),
                animate(1000)
            ])
        ])
    ]
})
export class LeitorContentPanelBuscaProgressoComponent implements OnInit {
    public interval: any;

    constructor(
        public dialogRef: MatDialogRef<LeitorContentPanelBuscaProgressoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: LeiContentPanelBuscaProgressoModel
    ) {
    }

    ngOnInit() {

        this.interval = setInterval(() => {
            const iAtual = this.data.mensagensCarregamento.indexOf(this.data.mensagemAtual);
            let iProximo = (iAtual === -1) ? 0 : iAtual + 1;
            iProximo = ((iProximo + 1) > this.data.mensagensCarregamento.length) ? 0 : iProximo;

            this.data.mensagemAtual = this.data.mensagensCarregamento[iProximo];
        }, 5000);

        this.data.mensagemAtual = this.data.mensagensCarregamento[0];
    }

    cancelar() {
        this.data.cancelarBusca = true;
    }

}

export class LeiContentPanelBuscaProgressoModel {
    public message: string = null;
    public progress: number = null;

    public mensagensCarregamento: string[] = ['teste', 'inserir aqui'];
    public mensagemAtual: string;
    public cancelarBusca: boolean;
}
