import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-view-lab',
  templateUrl: './view-lab.component.html',
  styleUrls: ['./view-lab.component.scss']
})
export class ViewLabComponent implements OnInit {
  public email: string;
  public senha: string;
  public loginResult: string;
  public loginError: string;

  public token: string;
  public primeiroDownloadResult: string;
  public primeiroDownloadError: string;

  constructor(
    private httpClient: HttpClient) { }

  ngOnInit() {
  }

  public btnLogin_click($event: Event, email: string, senha: string): void {
    $event.preventDefault();
    $event.stopPropagation();

    const url = `${AppConfig.apiEndpoint}/auth/login`;
    const model = {
      'Email': email,
      'Senha': senha
    };

    this.httpClient.post(url, model).toPromise().then(result => {
      this.loginError = '';
      this.loginResult = JSON.stringify(result);
    }).catch(err => {
      this.loginResult = '';
      this.loginError = JSON.stringify(err);
      throw err;
    });
  }

  public btnPrimeiroDownload_click($event, token): void {
    $event.preventDefault();
    $event.stopPropagation();

    const url = `${AppConfig.apiEndpoint}/sync/`;

    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.append('authorization', `bearer ${token}`);

    this.httpClient.get(url, { headers: requestHeaders }).toPromise().then(result => {
      this.primeiroDownloadError = '';
      this.primeiroDownloadResult = JSON.stringify(result);
    }).catch(err => {
      this.primeiroDownloadResult = '';
      this.primeiroDownloadError = JSON.stringify(err);
      throw err;
    });
  }
}
