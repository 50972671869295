import { Injectable } from '@angular/core';
import { BuscaPanelParameters, estadoJanelaBusca } from '../components/leitor-content-panelbusca/busca-panel.parameters';
import { Lei } from '../models/Lei';
import { Intervalo } from '../components/leitor-content-pagina/pagina.parameters';
import { FuncoesProva } from '../models/Marcacao';
import { MatchBuscaTexto } from '../models/MatchBuscaTexto';
import { TipoSelecao } from './selection.service';
import { TextoPagina, TextoPaginaFunctions } from '../models/pagina/TextoPagina';
import { StorageHelper } from '../helpers/storage.helper';
import { BuscaSalva } from '../models/UserData';
import { Item, TipoItem } from '../models/Item';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioPreferenciasService } from './data-services/usuario.preferencias.service';
import { StringHelper } from '../helpers/string.helper';
import { ErroPopUpComponent, ErroPopupDataModel } from '../components/popups/mensagem/erro-popup.component';
import { UsuarioGuiasService } from './data-services/usuario.guias.service';
import { UsuarioAnotacoesService } from './data-services/usuario.anotacoes.service';
import { MarcacaoServiceCarregarMarcacaoResult } from '../models/MarcacaoServiceCarregarMarcacaoResult';
import { Guia } from '../models/Guia';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { StatusService } from './status.service';
import { CompressedResult, CompressionHelper } from '../helpers/compression.helper';
import { BuscaRequest } from '../arguments/busca/BuscaRequest';
import { ResultadoBuscaResponse } from '../arguments/busca/ResultadoBuscaResponse';
import { LeiRepositorio } from '../repositorios/lei.repositorio';
import { NumberHelper } from '../helpers/numbers.helper';
import { strict } from 'assert';
import { LoggingService } from './logging.service';

export class ResultadoMarcacaoItem {
    idItem: string;
    intervalos: Array<Intervalo>;

    constructor() {
        this.intervalos = new Array<Intervalo>();
    }
}

@Injectable()
export class BuscaService {
    readonly databaseName = 'buscas';
    readonly collectionName = 'buscas';

    public isBusy: boolean;
    private matchBuscas: Array<MatchBuscaTexto>;

    //Guilherme - Não subscrever a este observable, utilizar ConteudoService.$Conteudo
    public $Busca: Observable<BuscaPanelParameters>;
    private _busca = new BehaviorSubject<BuscaPanelParameters>(null);
    private guiaAtiva: Guia;

    constructor(
        public dialog: MatDialog,
        private usuarioGuiasService: UsuarioGuiasService,
        private usuarioAnotacoesService: UsuarioAnotacoesService,
        private usuarioPreferenciasService: UsuarioPreferenciasService,
        private loggingService: LoggingService,
        private statusService: StatusService,
        private leiRepositorio: LeiRepositorio,
        private httpClient: HttpClient
    ) {
        this.matchBuscas = new Array<MatchBuscaTexto>();
        this.$Busca = this._busca.asObservable();

        usuarioGuiasService.$GuiaAtiva.subscribe(g => this.guia_subscribe(g));
    }

    private guia_subscribe(guiaAtual: Guia): void {
        const taskname = 'carregarBusca';

        if (!guiaAtual || (this.guiaAtiva && guiaAtual.idLei === this.guiaAtiva.idLei))
            return;

        this.guiaAtiva = guiaAtual;
        this.fecharPainelBusca(false).then(() => {
            this.carregarBuscaGuia(this.guiaAtiva.id).then(buscaSalva => {
                let busca = null;
                if (buscaSalva) {
                    const busca = BuscaPanelParameters.fromBuscaSalva(buscaSalva);
                    if (busca.buscarTodosDocumentos) {
                        this.statusService.mostrarMensagemProgresso('Recuperando busca salva', taskname);
                        this.buscar(busca, false).then((resultadoBusca) => {
                            if (!resultadoBusca.matchResultadoBuscaFoco)
                                resultadoBusca.matchResultadoBuscaFoco = resultadoBusca.matchsResultadoBusca[0];
                            this._busca.next(resultadoBusca);
                            this.statusService.ocultarMensagemProgresso(taskname);
                        });
                    } else {
                        if (!busca.matchResultadoBuscaFoco)
                            busca.matchResultadoBuscaFoco = busca.matchsResultadoBusca[0];

                        this._busca.next(busca)
                    }
                } else {
                    this._busca.next(busca);
                }
            });
        });
    }

    public carregarBuscaGuia(idGuia: string): Promise<BuscaSalva> {
        return new Promise((onsuccess, onerror) => {
            StorageHelper.list<BuscaSalva>(this.databaseName, this.collectionName, b => b.guiaId === idGuia)
                .then(guias => onsuccess(guias[0]))
                .catch(err => onerror(err));
        });
    }

    public abrirPainelBusca(buscaAberta: BuscaPanelParameters = null): void {
        if (!buscaAberta) {
            buscaAberta = new BuscaPanelParameters();
        }
        this._busca.next(buscaAberta);
    }

    public async fecharPainelBusca(apagarBusca: boolean) {
        if (apagarBusca) {
            await this.finalizarBusca();
        }

        this._busca.next(null);
    }

    private async finalizarBusca() {
        if (!this.guiaAtiva) {
            return;
        }

        const buscasArmazenadas = <BuscaSalva[]>await new Promise((onsuccess) => {
            StorageHelper.list<BuscaSalva>(this.databaseName, this.collectionName)
                .then(buscas => onsuccess(buscas));
        });

        // deletar versao antiga da busca
        const indexBuscaAtualizar = buscasArmazenadas.findIndex(busca => busca.guiaId === this.guiaAtiva.id);
        if (indexBuscaAtualizar !== -1) {
            await new Promise((onsuccess) => {
                StorageHelper.delete(this.databaseName, this.collectionName, buscasArmazenadas[indexBuscaAtualizar].id).then(() => onsuccess());
            });
        }

        const carregarBuscas = new Promise((onsuccess) => {
            StorageHelper.list<BuscaSalva>(this.databaseName, this.collectionName)
                .then(buscas => onsuccess(buscas));
        });

        carregarBuscas.then((buscas: BuscaSalva[]) => {
            const indexBuscaAtualizar = buscas.findIndex(busca => busca.guiaId === this.usuarioGuiasService.guiaAtiva.id);
            if (indexBuscaAtualizar !== -1) {
                StorageHelper.delete(this.databaseName, this.collectionName, buscas[indexBuscaAtualizar].id);
            }
        });
    }

    buscar(params: BuscaPanelParameters, updateObservable = true): Promise<BuscaPanelParameters> {
        const taskname = 'buscar';
        return new Promise((onsuccess, onerror) => {
            this.isBusy = true;

            // console.log('termo original: ' + params.textoBuscar);
            params.textoBuscar = StringHelper.RemoverAcentosCaracteresEspeciais(params.textoBuscar);
            // console.log('termo corrigido: ' + params.textoBuscar);

            const processarBuscaTexto = async (lei: Lei) => {
                const anotacoes = await this.usuarioAnotacoesService.BuscarAnotacoesPorLei(lei.id);
                await this.carregarConteudo(lei.itens, null, null, null, null, params, lei, anotacoes);
            };

            this.matchBuscas = new Array<MatchBuscaTexto>();

            const guiaAtiva = this.guiaAtiva;
            let idLei = guiaAtiva.idLei;

            if (params.buscarTodosDocumentos && params.resultadosBuscaWeb && params.resultadosBuscaWeb.resultadoAtual) {
                idLei = params.resultadosBuscaWeb.resultadoAtual.id;
            }

            if (!params.buscarTodosDocumentos) {
                this.loggingService.LogEvent("Leitor - Buscar documento atual", null, null);

                this.leiRepositorio.carregarLei(guiaAtiva.idLei).then(async lei => {
                    await processarBuscaTexto(lei);

                    if (this.matchBuscas.length > 0) {
                        params.buscaSemResultado = false;
                        params.matchsResultadoBusca = this.matchBuscas;
                        params.matchResultadoBuscaFoco = this.matchBuscas[0];
                        params.estadoJanelaBusca = estadoJanelaBusca.resultadoBuscaSimples;
                    } else {
                        params.buscaSemResultado = true;

                        const data = new ErroPopupDataModel();
                        data.titulo = 'A busca não encontrou nenhum resultado';
                        data.mensagem = 'Altere as opções de busca e tente novamente';
                        this.dialog.open(ErroPopUpComponent, {
                            width: '600px',
                            data: data,
                        });
                    }
                    this._busca.next(params);
                    this.salvarBusca(new BuscaSalva(params));
                });

            } else {
                this.loggingService.LogEvent("Leitor - Buscar todos documentos", null, null);

                const dto = new BuscaRequest();

                dto.texto = params.textoBuscar;

                if (params.idPagina)
                    dto.pagina = params.idPagina;

                if (params.provascaiuEmProva.ativo) {
                    dto.buscarMarcacoes = true;
                    if (params.provaBuscar) {
                        if (params.provaBuscar.instituicao)
                            dto.idInstituicaoMarcacao = params.provaBuscar.instituicao.id;
                        if (params.provaBuscar.banca)
                            dto.idBancaMarcacao = params.provaBuscar.banca.id;
                        if (params.provaBuscar.ano)
                            dto.idAnoMarcacao = params.provaBuscar.ano.id;
                        if (params.provaBuscar.tipo)
                            dto.idTipoMarcacao = params.provaBuscar.tipo.id;
                        if (params.provaBuscar.cargo)
                            dto.idCargoMarcacao = params.provaBuscar.cargo.id;
                    }
                }

                if (params.comentariosComentado.ativo || params.comentarioBuscar) {
                    dto.buscarComentarios = true;
                    dto.textoComentario = params.comentarioBuscar
                }

                if (params.mnemonicosOpcao.ativo || params.mnemonicoBuscar) {
                    dto.buscarMnemonicos = true;
                    dto.textoMnemonico = params.mnemonicoBuscar;
                }

                dto.buscarCaneta1 = params.marcacoesCaneta[0].ativo;
                dto.buscarCaneta2 = params.marcacoesCaneta[1].ativo;
                dto.buscarCaneta3 = params.marcacoesCaneta[2].ativo;
                dto.buscarCaneta4 = params.marcacoesCaneta[3].ativo;
                dto.buscarCaneta5 = params.marcacoesCaneta[4].ativo;

                const url = `${AppConfig.apiEndpoint}/busca/v2`;

                if (updateObservable) {
                    this.statusService.mostrarMensagemProgresso("Buscando...", taskname);
                }

                this.httpClient.post(url, dto).toPromise().then((result: CompressedResult) => {
                    params.matchsResultadoBusca = new Array<MatchBuscaTexto>();
                    params.matchResultadoBuscaFoco = null;

                    if (updateObservable) {
                        this.statusService.mostrarMensagemProgresso("Processando resultados...", taskname);
                    }

                    const resultadosBusca = <ResultadoBuscaResponse>JSON.parse(CompressionHelper.unzip(result));

                    params.estadoJanelaBusca = estadoJanelaBusca.resultadoBuscaAvancada;
                    params.resultadosBuscaWeb = resultadosBusca;

                    if (params.resultadosBuscaWeb.resultadoAtual) {
                        params.matchResultadoBuscaFoco = null;
                        params.buscaSemResultado = resultadosBusca.leisEncontradas.length === 0;

                        params.matchsResultadoBusca = [];
                        resultadosBusca.nodes.forEach(node => {
                            node.children.forEach(child => {
                                child.ocorrencias.forEach(ocorrencia => {
                                    const match = new MatchBuscaTexto();

                                    match.de = ocorrencia.de;
                                    match.ate = ocorrencia.ate;
                                    match.cor = "yellow";
                                    match.textoItemId = child.id;

                                    match.idLei = resultadosBusca.resultadoAtual.id;
                                    match.tituloLei = resultadosBusca.resultadoAtual.titulo;

                                    params.matchsResultadoBusca.push(match);
                                });
                            });
                        });

                        if (updateObservable) {
                            this._busca.next(params);
                            this.statusService.ocultarMensagemProgresso(taskname);
                        }

                        this.isBusy = false;
                        onsuccess(params);
                    } else {
                        params.buscaSemResultado = true;

                        if (updateObservable) {
                            this._busca.next(params);
                            this.statusService.ocultarMensagemProgresso(taskname);
                        }

                        this.isBusy = false;
                        onsuccess(params);
                    }
                }).catch(err => {
                    this.statusService.setMessage(err.message);
                    this.statusService.ocultarMensagemProgresso(taskname);

                    this.isBusy = false;
                    onerror(err);
                });
            }
        });
    }

    private async carregarConteudo(itens: Array<Item>, ultimoTitulo: Item, utlimoArtigo: Item, utlimoInciso: Item, ultimaAlinea: Item, params: BuscaPanelParameters, lei: Lei, marcacoes: MarcacaoServiceCarregarMarcacaoResult) {
        const nomeLei = lei.descricao;

        let textoRegex = '';
        if (!params.buscaRapidaPrefixos) {
            for (let i = 0, c = params.textoBuscar[i]; c; i++, c = params.textoBuscar[i]) {
                textoRegex += `${c}[\\.;ºo\\t]?[ ]*?`;
            }
        } else {
            textoRegex = `(art[\\.;ºo\\t]?[ ]*?${params.textoBuscar})`
                + `|(sumula[\\.;ºo\\t]?[ ]*?n[\\.;ºo\\t]?[ ]*?[0]?${params.textoBuscar})`
                + `|(sumula[\\.;ºo\\t]?[ ]*?[0]?${params.textoBuscar})`
                + `|(sumula[\\.;ºo\\t]?[ ]*?vinculante[\\.;ºo\\t]?[ ]*?[0]?${params.textoBuscar})`
        }

        const reg = new RegExp(textoRegex, 'gi');
        // const user = await this.authService.getUserdata();
        const prefs = this.usuarioPreferenciasService.Configuracoes;

        await itens.forEach(async item => {
            const texto = new TextoPagina(item);

            if (item.versoes && item.versoes.filter(versao => versao.texto).length > 0) {

                ultimoTitulo = (TipoItem[item.tipoItem] === 'Titulo') ? item : ultimoTitulo;
                utlimoArtigo = (TipoItem[item.tipoItem] === 'Artigo') ? item : utlimoArtigo;
                utlimoInciso = (TipoItem[item.tipoItem] === 'Inciso') ? item : utlimoInciso;
                ultimaAlinea = (TipoItem[item.tipoItem] === 'Alinea') ? item : ultimaAlinea;

                // não criar resultado de busca para itens que forem revogados, quando a opção de exibição de itens revogados não estiver habilitada
                if (texto.versoes[item.versoes.length - 1].revogado === true) {
                    return;
                }

                // Buscar texto compatível
                if (reg.source.length > 0 && reg.source !== '(?:)') {

                    const prefixoOriginal = TextoPaginaFunctions.getVersao(texto).prefixo
                    let prefixo = prefixoOriginal

                    if (prefixo) {
                        const partesPrefixo = prefixo.split(' ')
                        for (let i = 0; i < partesPrefixo.length; i++) {
                            const parte = partesPrefixo[i]
                            let number = 0;
                            if (parte.toLowerCase().indexOf("súmula") == -1 && parte.toLowerCase().indexOf("vinculante") == -1) {
                                number = NumberHelper.unRomanize(parte)
                            }

                            if (number > 0)
                                partesPrefixo[i] = `${number}`
                        }

                        prefixo = partesPrefixo.join(' ')
                        prefixo = prefixo.replace('artigo', 'art').replace('Artigo', 'art')
                    }

                    let textoCompleto = (prefixo) ? prefixo : ''
                    textoCompleto += params.buscaRapidaPrefixos ? '' : TextoPaginaFunctions.getVersao(texto).texto;
                    textoCompleto = StringHelper.RemoverAcentos(textoCompleto);

                    // Identificar resultados de texto e validadar outros parametros
                    let match: RegExpExecArray;
                    while (match = reg.exec(textoCompleto)) {
                        const lengthPrefixoOriginal = prefixoOriginal ? prefixoOriginal.length : 0
                        const lengthPrefixoAlterado = prefixo ? prefixo.length : 0

                        let de = match.index + (lengthPrefixoOriginal - lengthPrefixoAlterado);
                        let ate = de + (match[0].length - 1);

                        this.criarResultado(ultimoTitulo, utlimoArtigo, utlimoInciso, ultimaAlinea
                            , item, de, ate, lei);
                    }
                }

                // Buscar marcações compatíveis
                const marcacoesCompativeis = await this.validarCriteriosMarcacao(params, texto, marcacoes);
                marcacoesCompativeis.forEach(marcacao => {
                    this.criarResultado(ultimoTitulo, utlimoArtigo, utlimoInciso, ultimaAlinea
                        , item, marcacao.rangeInicio, marcacao.rangeFinal, lei);
                });
            }
        });
    }

    private criarResultado(ultimoTitulo, utlimoArtigo, utlimoInciso, ultimaAlinea, item, indexInicial: number, indexFinal: number, lei: Lei) {
        const ultimoTituloTexto = (ultimoTitulo && ultimoTitulo.versoes[ultimoTitulo.versoes.length - 1].texto) ? ultimoTitulo.versoes[ultimoTitulo.versoes.length - 1].texto : '';
        const ultimoArtigoTexto = (utlimoArtigo && utlimoArtigo.versoes[utlimoArtigo.versoes.length - 1].prefixo) ? utlimoArtigo.versoes[utlimoArtigo.versoes.length - 1].prefixo : '';
        const utlimoIncisoTexto = (utlimoInciso && utlimoInciso.versoes[utlimoInciso.versoes.length - 1].prefixo) ? utlimoInciso.versoes[utlimoInciso.versoes.length - 1].prefixo : '';
        const ultimaAlineaTexto = (ultimaAlinea && ultimaAlinea.versoes[ultimaAlinea.versoes.length - 1].prefixo) ? ultimaAlinea.versoes[ultimaAlinea.versoes.length - 1].prefixo : '';

        const caminhoItemArray = new Array(ultimoTituloTexto, ultimoArtigoTexto, utlimoIncisoTexto, ultimaAlineaTexto).filter(String);
        const caminhoItem = caminhoItemArray.join('/');

        const texto = new TextoPagina(item);
        const matchTextoInserido = new MatchBuscaTexto();

        matchTextoInserido.tituloLei = lei.descricao;
        matchTextoInserido.textoItemId = texto.id;
        matchTextoInserido.caminhoItem = caminhoItem;
        matchTextoInserido.de = indexInicial;
        matchTextoInserido.ate = indexFinal;
        matchTextoInserido.cor = 'amarelo';
        matchTextoInserido.tipo = 'resultadobusca';
        matchTextoInserido.idLei = lei.id;
        matchTextoInserido.descricaoLei = lei.descricao;
        matchTextoInserido.idGuiaAtiva = this.guiaAtiva.id;

        this.matchBuscas.push(matchTextoInserido);
    }

    private async validarCriteriosMarcacao(params: BuscaPanelParameters, texto: TextoPagina, marcacoes: MarcacaoServiceCarregarMarcacaoResult): Promise<any[]> {
        const comentariosTextoAtual = marcacoes.comentarios.filter(x => x.range.idItens.findIndex(r => r.idItem === texto.id && r.idImportacao === texto.indexVersao) !== -1);
        const marcacoesItemAtual = marcacoes.marcacoes.filter(x => x.range.idItens.findIndex(r => r.idItem === texto.id && r.idImportacao === texto.indexVersao) !== -1);
        const grifosItemAtual = marcacoes.grifos.filter(x => x.idItem === texto.id && x.idImportacao === texto.indexVersao);

        const itensCompativeis = new Array<any>();

        // Marcacoes canetas
        if (params.marcacoesCaneta.filter(marcacaoParametro => marcacaoParametro.ativo)) {
            params.marcacoesCaneta.filter(marcacaoParametro => marcacaoParametro.ativo).forEach(marcacaoParametro => {
                grifosItemAtual.forEach(marcacaoUsuario => {

                    const marcadorUsuarioTexto = (TipoSelecao[marcacaoUsuario.tipo]);
                    const marcadorParametroTexto = (TipoSelecao[marcacaoParametro.tipo]);

                    if (marcadorUsuarioTexto === marcadorParametroTexto) {
                        itensCompativeis.push({ rangeInicio: marcacaoUsuario.inicio, rangeFinal: marcacaoUsuario.termino });
                    }
                });
            });
        }

        // prova buscada
        if (params.provascaiuEmProva.ativo === true || params.strProvaBuscar) {
            if (params.provascaiuEmProva.ativo === true && !params.strProvaBuscar) {
                if (marcacoesItemAtual) {
                    marcacoesItemAtual.forEach(marcacaoItemAtual => {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    });
                }
            }

            if (params.strProvaBuscar) {
                marcacoesItemAtual.forEach(marcacaoItemAtual => {
                    if (FuncoesProva.getLabelProva(marcacaoItemAtual.dados).includes(params.strProvaBuscar)) {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    }
                });
            }
        }

        // Comentarios
        if (params.comentariosComentado.ativo === true || params.comentarioBuscar.length > 0) {
            if (params.comentariosComentado.ativo === true && params.comentarioBuscar.length <= 0) {
                if (comentariosTextoAtual) {
                    comentariosTextoAtual.filter(x => x.mnemonico === false).forEach(marcacaoItemAtual => {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    });
                }
            }

            if (params.comentarioBuscar.length > 0) {
                const regComentario = new RegExp(params.comentarioBuscar, 'gi');

                const comentariosCompativeis = comentariosTextoAtual.filter(comentatioUsuario => regComentario.exec(comentatioUsuario.texto));
                if (comentariosCompativeis && comentariosCompativeis.length > 0) {
                    comentariosCompativeis.filter(x => x.mnemonico === false).forEach(marcacaoItemAtual => {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    });
                }
            }
        }

        // Mnemonicos
        if (params.mnemonicosOpcao.ativo === true || params.mnemonicoBuscar.length > 0) {
            if (params.mnemonicosOpcao.ativo === true && params.mnemonicoBuscar.length <= 0) {
                if (comentariosTextoAtual) {
                    comentariosTextoAtual.filter(x => x.mnemonico === true).forEach(marcacaoItemAtual => {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    });
                }
            }

            if (params.mnemonicoBuscar.length > 0) {
                const regComentario = new RegExp(params.mnemonicoBuscar, 'gi');

                const mnemonicosCompativeis = comentariosTextoAtual.filter(mnemonicoUsuario => regComentario.exec(mnemonicoUsuario.texto));
                if (mnemonicosCompativeis && mnemonicosCompativeis.length > 0) {
                    mnemonicosCompativeis.filter(x => x.mnemonico === true).forEach(marcacaoItemAtual => {
                        itensCompativeis.push({ rangeInicio: marcacaoItemAtual.range.inicio, rangeFinal: marcacaoItemAtual.range.termino });
                    });
                }
            }
        }


        return itensCompativeis;

    }

    public async salvarBusca(buscaSalvar: BuscaSalva = null, idGuia: string = null) {
        if (!this.guiaAtiva && !idGuia) {
            return;
        }

        // Salvar status busca ja aberta
        if (!buscaSalvar && !this._busca.value)
            return;
        else if (!buscaSalvar)
            buscaSalvar = new BuscaSalva(this._busca.value);

        // Vincular busca à guia
        buscaSalvar.guiaId = !idGuia ? this.guiaAtiva.id : idGuia;

        const buscasArmazenadas = <BuscaSalva[]>await new Promise((onsuccess) => {
            StorageHelper.list<BuscaSalva>(this.databaseName, this.collectionName).then(buscas => {
                onsuccess(buscas);
            });
        });

        // deletar versao antiga da busca
        const indexBuscaAtualizar = buscasArmazenadas.findIndex(busca => busca.guiaId === buscaSalvar.guiaId);
        if (indexBuscaAtualizar !== -1) {
            await new Promise((onsuccess) => {
                StorageHelper.delete(this.databaseName, this.collectionName, buscasArmazenadas[indexBuscaAtualizar].id).then(() => {
                    onsuccess();
                });
            });
        }

        // salvar versão nova
        await new Promise((onsuccess) => {
            StorageHelper.upsert(buscaSalvar, this.databaseName, this.collectionName).then((busca) => {
                onsuccess(busca);
            });
        });

        const carregarBuscas = new Promise((onsuccess) => {
            StorageHelper.list<BuscaSalva>(this.databaseName, this.collectionName).then((buscas) => {
                onsuccess(buscas);
            });
        });

        carregarBuscas.then((buscas: BuscaSalva[]) => {
            const indexBuscaAtualizar = buscas.findIndex(busca => busca.guiaId === buscaSalvar.guiaId);
            if (indexBuscaAtualizar !== -1 && buscasArmazenadas[indexBuscaAtualizar]) {
                StorageHelper.delete(this.databaseName, this.collectionName, buscasArmazenadas[indexBuscaAtualizar].id);
            }

            StorageHelper.upsert(buscaSalvar, this.databaseName, this.collectionName);
        });
    }
}