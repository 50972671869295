import { ItemVersaoRangeSelecao } from '../models/ItemVersaoRangeSelecao';
import { Selector } from '../services/selection.service';

export class SelectionHelper {
  detector = new PlatformDetector();
  element: HTMLElement;
  before;
  after;
  onSelectionStart;

  static Init(element, before, after, onSelectionStart) {
    new SelectionHelper(element, before, after, onSelectionStart).events();
  }

  constructor(element: HTMLElement, before, after, onSelectionStart) {
    this.element = element;
    this.before = before || function () { };
    this.after = after || function () { };
    this.onSelectionStart = onSelectionStart || function () { };
  }

  isNodeList = function (element) {
    const stringRepr = Object.prototype.toString.call(element);

    return typeof element === 'object' &&
      /^\[object (HTMLCollection|NodeList|Object)\]$/.test(stringRepr) &&
      element.hasOwnProperty('length') &&
      (element.length === 0 || (typeof element[0] === 'object' &&
        element[0].nodeType > 0));
  };

  debounce(callback, wait: number) {
    let timeout;

    return function () {
      const context = this;
      const args = arguments;

      const later = function () {
        timeout = null;
        callback.apply(context, args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    }
  }

  events() {
    const before = this.before;
    const after = this.after;
    const onSelectionStart = this.onSelectionStart;

    const functionCallback = (event) => {
      before();

      let srcEl = event.srcElement;
      while (srcEl.id === '' && srcEl.parentElement) {
        srcEl = srcEl.parentElement;
      }

      const selection = getSelection();
      const range = (selection && !selection.isCollapsed) ? selection.getRangeAt(0) : null;

      if (!range) {
        after(null);
      } else {
        let de = range.startOffset;
        let ate = range.endOffset;
        let ids = new Array<ItemVersaoRangeSelecao>();

        const elems = new Array<SelectedElement>();
        const allWithinRangeParent = document.getElementsByClassName('coluna-texto');

        for (let i = 0, el; el = allWithinRangeParent[i]; i++) {
          if (selection.containsNode(el, false) && !(<HTMLElement>el).parentElement.classList.contains('texto-introducao-busca')) {
            elems.push(new SelectedElement(el, false));
          } else if (selection.containsNode(el, true) && !(<HTMLElement>el).parentElement.classList.contains('texto-introducao-busca')) {
            elems.push(new SelectedElement(el, true));
          }

          if ((<HTMLElement>el).parentElement.classList.contains('texto-introducao-busca')) {
            if (i === 0) {
              de = -1;
            } else if (i === allWithinRangeParent.length - 1) {
              ate = -1;
            }
          }
        }

        ids = elems.map(function (s) { return { idItem: (s.element as HTMLElement).id, marcacaoDesatualizada: null, idImportacao: Number((s.element as HTMLElement).getAttribute('data-indexVersao')) }; });

        const trimLeft = (txt: string): string => {
          let txtCopy = '';
          let pular = false;
          let spaces = [10, 32];

          for (let i = 0; i < txt.length; i++) {
            let te = txt[i];
            if (!pular && spaces.indexOf(txt.charCodeAt(i)) === -1)
              pular = true;

            if (pular)
              txtCopy += txt[i];
          }

          return txtCopy;
        }

        const trimRight = (txt: string): string => {
          let spaces = [10, 32];
          let retorno = '';

          let pular = false;
          for (let i = txt.length - 1, c = txt.charCodeAt(i); c; i-- , c = txt.charCodeAt(i)) {
            if (pular) {
              retorno = txt.charAt(i) + retorno;
            } else {
              if (spaces.indexOf(c) === -1) {
                retorno = txt.charAt(i) + retorno;
                pular = true;
              }
            }
          }

          return retorno;
        }

        const trimWithin = (txt: string): string => {
          let retorno = txt;

          const lb = String.fromCharCode(10);
          retorno = retorno.replace(new RegExp(lb, "g"), '');

          const es = String.fromCharCode(32);
          while (retorno.indexOf(`${es}${es}`) !== -1) {
            retorno = retorno.replace(`${es}${es}`, es);
          }

          return retorno;
        }

        const calcularStartSelecao = (element: Node) => {
          const startParentSpan = <HTMLSpanElement>range.startContainer.parentNode.parentNode.parentNode;
          const tipoSpan = startParentSpan.className;
          let offset = range.startOffset;

          let currentAppTrechoTexto = range.startContainer.parentNode.parentNode;
          while (currentAppTrechoTexto.previousSibling && currentAppTrechoTexto.previousSibling.nodeName === 'APP-TRECHO-TEXTO') {
            currentAppTrechoTexto = <Node & ParentNode>currentAppTrechoTexto.previousSibling;

            let span = (<HTMLSpanElement>currentAppTrechoTexto.firstChild);
            if (!span.innerText)
              span = (<HTMLSpanElement>span.nextSibling);
              
            const spanLength = span.innerText.length;
            offset += spanLength;
          }

          de = offset;
          if (tipoSpan === "texto" && (startParentSpan.previousElementSibling)) {
            de += (<HTMLSpanElement>startParentSpan.previousElementSibling).innerText.length;
          }
        };

        const calcularEndSelecao = (element: Node) => {
          const endParentSpan = <HTMLSpanElement>range.endContainer.parentNode.parentNode.parentNode;
          const tipoSpan = endParentSpan.className;
          let offset = range.endOffset - 1;

          let currentAppTrechoTexto = range.endContainer.parentNode.parentNode;
          while (currentAppTrechoTexto.previousSibling && currentAppTrechoTexto.previousSibling.nodeName === 'APP-TRECHO-TEXTO') {
            currentAppTrechoTexto = <Node & ParentNode>currentAppTrechoTexto.previousSibling;
            let span = (<HTMLSpanElement>currentAppTrechoTexto.firstChild);

            if (!span.innerText)
              span = (<HTMLSpanElement>span.nextSibling);

            const spanLength = span.innerText.length;
            offset += spanLength;
          }

          ate = offset;
          if (tipoSpan === "texto" && (endParentSpan.previousElementSibling)) {
            let span = (<HTMLSpanElement>endParentSpan.previousElementSibling);

            if (!span.innerText)
              span = (<HTMLSpanElement>span.nextSibling);

            ate += span.innerText.length;
          }
        };

        if (elems.length === 0) {
          after(null);
        } else {
          if (elems.length === 1) {
            calcularStartSelecao(elems[0].element);
            calcularEndSelecao(elems[0].element);
          } else {
            elems.filter(e => e.partlySelected).forEach((selected) => {
              const index = elems.indexOf(selected);
              if (index === 0) {
                calcularStartSelecao(elems[index].element);
              } else if (index === elems.length - 1) {
                calcularEndSelecao(elems[index].element);
              }
            });
          }

          const sel = new Selector();
          sel.start = de;
          sel.end = ate;
          sel.selected = ids;
          sel.selectedSourceId = srcEl.id;
          sel.clientX = event.clientX;
          after(sel);
        }
      }
    };

    const selectionStartedCallback = (event) => {
      onSelectionStart();
    };

    if (this.detector.isComputer) {
      this.bindMouseDown(selectionStartedCallback);
      this.bindMouseUp(functionCallback);
      if (this.detector.isTouchEnabled) {
        this.bindTouch(functionCallback);
      }
    } else if (this.detector.isMobile) {
      this.bindTouch(functionCallback);
    }
  }

  getText() {
    let text = '';
    text = window.getSelection().toString();

    return text;
  }

  checkForSelections(element, getText, callback) {
    let intervalCheckingForText;

    const checkForChanges = function (cb) {
      let currentText = getText();

      intervalCheckingForText = setInterval(function () {
        if (getText() !== currentText) {
          cb(getText());
          currentText = getText();
        } else if (getText() === '') {
          clearInterval(intervalCheckingForText);
        }
      }, 250);
    };

    const selectionStart = function () {
      element.removeEventListener('touchend', selectionEnd, false);
      element.addEventListener('touchend', selectionEnd, false);

      if (intervalCheckingForText) {
        clearInterval(intervalCheckingForText);
      }

      intervalCheckingForText = setInterval(function () {
        const text = getText();

        if (text !== '') {
          callback(text);
          selectionEnd();
          checkForChanges(callback);
        }
      }, 250);
    };

    const selectionEnd = function () {
      clearInterval(intervalCheckingForText);
      element.removeEventListener('touchend', selectionEnd, false);
    };

    element.addEventListener('touchstart', selectionStart, false);
  }

  bindTouch(callback) {
    const checkForSelections = this.checkForSelections;
    const getText = this.getText;

    const bindDOM = function (el) {
      checkForSelections(el, getText, callback);
    };

    if (!this.isNodeList(this.element)) {
      bindDOM(this.element);
      return;
    }

    [].forEach.call(this.element, function (item) {
      bindDOM(item);
    });
  }

  bindMouseDown(callback) {
    const debounce = this.debounce;

    const bindDOM = function (el) {
      // el.addEventListener('mousedown', debounce(callback, 150), false);
      el.addEventListener('mousedown', callback, false);
    };

    if (!this.isNodeList(this.element)) {
      bindDOM(this.element);
      return;
    }

    [].forEach.call(this.element, function (item) {
      bindDOM(item);
    });
  }

  bindMouseUp(callback) {
    const debounce = this.debounce;

    const bindDOM = function (el) {
      el.addEventListener('mouseup', debounce(callback, 150), false);
    };

    if (!this.isNodeList(this.element)) {
      bindDOM(this.element);
      return;
    }

    [].forEach.call(this.element, function (item) {
      bindDOM(item);
    });
  }
}

export class PlatformDetector {
  Android = navigator.userAgent.match(/Android/i);
  BlackBerry = navigator.userAgent.match(/Blackberry/i);
  iOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  OperaMobile = navigator.userAgent.match(/Opera Mini/i);
  WindowsMobile = navigator.userAgent.match(/IEMobile/i);

  get isMobile() {
    return (this.Android || this.BlackBerry || this.iOS || this.OperaMobile || this.WindowsMobile);
  }

  get isComputer(): boolean {
    return !this.isMobile;
  }

  get isTouchEnabled(): boolean {
    return 'ontouchstart' in window;
  }
}

export class SelectedElement {
  element: Node;
  partlySelected: boolean;

  constructor(element: Node, partlySelected: boolean) {
    this.element = element;
    this.partlySelected = partlySelected;
  }
}