import { Guia } from './Guia';
import { Grifo } from './Grifo';
import { Comentario } from './Comentario';
import { Marcacao, ProvaDados } from './Marcacao';
import { EntitiesHelper } from '../helpers/entities.helper';
import { OpcaoToolbar } from '../components/leitor-toolbar/opcaoToolbar';
import { BuscaPanelParameters, estadoJanelaBusca } from '../components/leitor-content-panelbusca/busca-panel.parameters';
import { MatchBuscaTexto } from './MatchBuscaTexto';
import { IdentificacaoModel } from '../components/popups/perfil/form-identificacao/form-identificacao.component';
import { PreferenciasModel } from '../components/popups/preferencias/preferencias.component';
import { TipoSelecao } from '../services/selection.service';
import { Apontamento } from '../services/timer.service';
import { EstatisticasLeitura } from './usuario/EstatisticasLeitura';
import { BaseObj } from './UserdataSync';

// export class UserData {
//     id: string;
//     email: string;
//     nome: string;
//     sobrenome: string;
//     dataUltimaAlteracao: Date;
//     hash: string;

//     palavrasMinuto: number;
//     exibirItensRevogados: boolean;

//     guias: Array<Guia>;

//     preferencias: PreferenciasUsuario;
//     parametrosCaneta: ParametrosCaneta;
//     comentarios: Array<Comentario>;
//     marcacoesProva: Array<Marcacao>;
//     grifos: Array<Grifo>;
//     provaDatasource: ProvaDatasource;
//     estatisticasLeitura: Array<EstatisticasLeitura>;
//     apontamentos: Apontamento[];
//     authToken: string;
//     perfilUsuario: PerfilUsuario;
//     foto: string;

//     constructor() {
//         this.guias = new Array<Guia>();

//         this.comentarios = new Array<Comentario>();
//         this.marcacoesProva = new Array<Marcacao>();
//         this.grifos = new Array<Grifo>();
//         this.provaDatasource = new ProvaDatasource();
//         this.estatisticasLeitura = new Array<EstatisticasLeitura>();
//         this.apontamentos = new Array<Apontamento>();
//     }

//     public static atualizarDadosUsuario(usuario: UserData, model: IdentificacaoModel): UserData {
//         usuario.nome = model.nome;
//         usuario.sobrenome = model.sobrenome;
//         usuario.foto = model.foto;

//         return usuario;
//     }

//     public static alterarPreferencias(u: UserData, model: PreferenciasModel): UserData {
//         u.perfilUsuario = model.opcaoModoUsoSelecionada.tipo;
//         // u.palavrasMinuto = model.palavrasPorMinuto;
//         u.exibirItensRevogados = model.exibirItensRevogados;

//         u.parametrosCaneta.corCaneta1 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta1).cor;
//         u.parametrosCaneta.descricaoCaneta1 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta1).apelido;

//         u.parametrosCaneta.corCaneta2 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta2).cor;
//         u.parametrosCaneta.descricaoCaneta2 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta2).apelido;

//         u.parametrosCaneta.corCaneta3 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta3).cor;
//         u.parametrosCaneta.descricaoCaneta3 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta3).apelido;

//         u.parametrosCaneta.corCaneta4 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta4).cor;
//         u.parametrosCaneta.descricaoCaneta4 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta4).apelido;

//         u.parametrosCaneta.corCaneta5 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta5).cor;
//         u.parametrosCaneta.descricaoCaneta5 = model.grifos.find(g => g.tipoMarcacao === TipoSelecao.Caneta5).apelido;

//         u.parametrosCaneta.corTagProva = model.marcadores.find(g => g.tipoMarcacao === TipoSelecao.Prova).cor;
//         u.parametrosCaneta.realcarMarcacoesProva = model.marcadores.find(g => g.tipoMarcacao === TipoSelecao.Prova).sublinhar;

//         u.parametrosCaneta.corTagComentario = model.marcadores.find(g => g.tipoMarcacao === TipoSelecao.Comentario).cor;
//         u.parametrosCaneta.realcarComentarios = model.marcadores.find(g => g.tipoMarcacao === TipoSelecao.Comentario).sublinhar;

//         return u;
//     }

//     public static fromJSON(json: string): UserData {
//         if (!json)
//             return null;

//         const ret = new UserData();
//         const obj = JSON.parse(json);

//         for (let prop in obj)
//             ret[prop] = obj[prop];

//         return ret;
//     }
// }

export enum PerfilUsuario {
    Estudante = 1,
    Concurseiro = 2,
    Advogado = 3
}

export enum TipoLogin {
    Senha = 0,
    Facebook = 1,
    Google = 2,
    Token = 3
}

export class PreferenciasUsuario {
    larguraPapel: string;
    idTema: number;

    constructor() {
        this.larguraPapel = '2-3';
        this.idTema = 0;
    }
}

export class ParametrosCaneta {

    public static corResultadoBuscaDestaque = 'orange';
    public static corResultadoBuscaNormal = 'yellow';
    public static corDestaqueHover = 'lightgray';


    public descricaoCaneta1: string;
    public corCaneta1: string;
    public modoRealceCaneta1: string;
    public descricaoCaneta2: string;
    public corCaneta2: string;
    public modoRealceCaneta2: string;
    public descricaoCaneta3: string;
    public corCaneta3: string;
    public modoRealceCaneta3: string;
    public descricaoCaneta4: string;
    public corCaneta4: string;
    public modoRealceCaneta4: string;
    public descricaoCaneta5: string;
    public corCaneta5: string;
    public modoRealceCaneta5: string;

    public corTagProva: string;
    public posicionamentoProva: string;
    public corTagComentario: string;
    public posicionamentoComentario: string;
    public corTagMnemonico: string;
    public posicionamentoMnemonico: string;
    public realcarMarcacoesProva: boolean;
    public realcarComentarios: boolean;
    public realcarMnemonicos: boolean;

    public constructor() { }
}

export class ProvaDatasource {
    instituicoes: Array<ItemProvaDatasource>;
    bancas: Array<ItemProvaDatasource>;
    anos: Array<ItemProvaDatasource>;
    tipos: Array<ItemProvaDatasource>;
    cargos: Array<ItemProvaDatasource>;

    constructor() {
        this.instituicoes = new Array<ItemProvaDatasource>();
        this.bancas = new Array<ItemProvaDatasource>();
        this.anos = new Array<ItemProvaDatasource>();
        this.tipos = new Array<ItemProvaDatasource>();
        this.cargos = new Array<ItemProvaDatasource>();
    }
}

export class ItemProvaDatasource extends BaseObj {
    descricao: string;

    constructor() {
        super();
        this.id = EntitiesHelper.generateGuid();
    }
}

export class BuscaSalva extends BaseObj {
    textoBuscar: string;
    marcacoesCaneta: Array<OpcaoToolbar>;
    provaBuscar: ProvaDados;
    provascaiuEmProva: OpcaoToolbar;
    comentarioBuscar: string;
    comentariosComentado: OpcaoToolbar;
    mnemonicoBuscar: string;
    mnemonicosOpcao: OpcaoToolbar;
    buscarTodosDocumentos: boolean;
    estadoJanelaBusca: estadoJanelaBusca;
    matchsResultadoBusca: Array<MatchBuscaTexto>;
    matchResultadoBuscaFoco: MatchBuscaTexto;
    guiaId: string;
    idPagina: number;

    constructor(params: BuscaPanelParameters = null) {
        super();

        if (!params) {
            this.marcacoesCaneta = new Array<OpcaoToolbar>();
            this.matchsResultadoBusca = new Array<MatchBuscaTexto>();
            return;
        }

        this.marcacoesCaneta = params.marcacoesCaneta.slice();
        this.comentariosComentado = params.comentariosComentado;
        this.comentarioBuscar = (params.comentarioBuscar) ? params.comentarioBuscar : '';
        this.mnemonicosOpcao = params.mnemonicosOpcao;
        this.mnemonicoBuscar = (params.mnemonicoBuscar)
        this.buscarTodosDocumentos = params.buscarTodosDocumentos;
        this.idPagina = params.idPagina;
        this.provascaiuEmProva = params.provascaiuEmProva;
        this.textoBuscar = (params.textoBuscar) ? params.textoBuscar : '';
        this.provaBuscar = (params.provaBuscar) ? params.provaBuscar : null;
        this.estadoJanelaBusca = params.estadoJanelaBusca;
        this.matchsResultadoBusca = params.matchsResultadoBusca;
        this.matchResultadoBuscaFoco = params.matchResultadoBuscaFoco;
    }
}

