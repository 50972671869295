import { Component, OnInit, Inject, ViewEncapsulation  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListArticleService } from "../list-articles/list-article.service";

@Component({
  selector: 'app-reading-speed',
  templateUrl: './reading-speed.component.html',
  styleUrls: ['./reading-speed.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReadingSpeedComponent implements OnInit {
  public startTime;
  public endTime;
  public hours = 0;
  public mins = 0;
  public secs = 0;
  public time_hours = 0;
  public time_min = 0;
  public time_sec = 0;
  public no_of_words = 800;
  public per_mins_words = 0;  
  public show_reading_bar = false;
  public reading_step = 1;
  counter: number;
  timerRef;
  running: boolean = false;
  startText = 'Start';
  public  list:any;
  
  constructor(
    public dialogRef: MatDialogRef<ReadingSpeedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [],
  ) {
    this.list = data; 
    this.list['conteud'] = this.list['conteud'].replace("/\/", "");   
    this.no_of_words = this.list['totaisPalavras'];
  }

  ngOnInit(): void {
    this.clearTimer();
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  StartTimeClick() {
    var d = new Date();
    var time_string = d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    this.startTime = time_string;
    this.running = !this.running;
    if (this.running) {
      this.startText = 'Stop';
      const startTime = Date.now() - (this.counter || 0);
      this.timerRef = setInterval(() => {
        this.counter = Date.now() - startTime;
        this.formatTime(this.counter);
      });
    } else {
      this.startText = 'Resume';
      clearInterval(this.timerRef);
    }
  }

  clearTimer() {
    this.running = false;
    this.startText = 'Start';
    this.counter = undefined;
    this.hours = 0;
    this.mins = 0;
    this.secs = 0;
    this.startTime = '';
    clearInterval(this.timerRef);    
  }

  formatTime(duration) {
    var seconds = Math.floor((duration / 1000) % 60);
    var minutes = Math.floor((duration / (1000 * 60)) % 60);
    var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    this.time_hours = (hours < 10) ? 0 + hours : hours;
    this.time_min = (minutes < 10) ? 0 + minutes : minutes;
    this.time_sec = (seconds < 10) ? 0 + seconds : seconds;    
  }

  EndTimeClick() {
    if(this.hours != 0 || this.time_min != 0 || this.time_sec != 0) {
      var hms = this.time_hours + ':' + this.time_min + ':' + this.time_sec;
      var a = hms.split(':'); 
      var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]); 
      var mins_words = (this.no_of_words * 60) / seconds;    
      var result = this.per_mins_words = Math.floor(mins_words);
      this.show_reading_bar = true;      
      if(result <= 25) {
        this.reading_step = 0;
      } else if(result >= 26 && result <= 50) {
        this.reading_step = 1;
      } else if(result >= 51 && result <= 75) {
        this.reading_step = 2;
      } else if(result >= 76 && result <= 100) {
        this.reading_step = 3;
      } else if(result >= 101 && result <= 125) {
        this.reading_step = 4;
      } else {
        this.reading_step = 5;
      }
    } else {
      this.show_reading_bar = false;      
    }
  }
  CloseDialog() {
    this.dialogRef.close(this.per_mins_words);
  }
}
