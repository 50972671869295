import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Tempo } from '../../../models/usuario/EstatisticasLeitura';

@Component({
  selector: '[indicador-leitura]',
  templateUrl: './indicador-leitura.component.html',
  styleUrls: ['./indicador-leitura.component.scss']
})
export class IndicadorLeituraComponent implements OnInit {
  @Input() progresso: number;
  @Input() tempoRestante: Tempo;

  public math = Math;

  public get horas(): number {
    return this.tempoRestante.horas || 0;
  };

  public get minutos(): number {
    return this.tempoRestante.minutos || 0;
  };

  public get segundos(): number {
    return this.tempoRestante.segundos || 0;
  };

  public constructor() { }

  public ngOnInit(): void { }
}
