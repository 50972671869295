export class WebserviceHelper {
    public static consultaCep(cep: string): Promise<EnderecoModel> {
        return new Promise<EnderecoModel>(resolve => {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                let endereco = JSON.parse(xhr.response);

                if (endereco && !endereco.erro) {
                    let retorno = new EnderecoModel();

                    retorno.cep = endereco.cep;
                    retorno.logradouro = endereco.logradouro;
                    retorno.bairro = endereco.bairro;
                    retorno.cidade = endereco.localidade;
                    retorno.estado = endereco.uf;

                    resolve(retorno);
                } else {
                    resolve(null);
                }
            };
            xhr.open('GET', `https://viacep.com.br/ws/${cep}/json/`);
            xhr.send();
        });
    }
}

export class EnderecoModel {
    cep: string;
    logradouro: string;
    bairro: string;
    cidade: string;
    estado: string;
}