import { ProvaDados } from '../../models/Marcacao';
import { OpcaoToolbar } from '../leitor-toolbar/opcaoToolbar';
import { MatchBuscaTexto } from '../../models/MatchBuscaTexto';
import { TextoPagina } from '../../models/pagina/TextoPagina';
import { BuscaSalva } from '../../models/UserData';
import { ResultadoBuscaResponse } from '../../arguments/busca/ResultadoBuscaResponse';

export class BuscaPanelParameters {
    // Propriedades não salvas
    buscaSemResultado: boolean;
    buscaRapidaPrefixos = false;

    // Propriedades salvas
    estadoJanelaBusca: estadoJanelaBusca;
    nomeBusca: string;
    salvandoBusca: boolean;
    textoBuscar: string;
    janelaFiltroAtiva: string;
    marcacoesCaneta: OpcaoToolbar[];
    provasDados: Array<ProvaDados>;
    provaBuscar: ProvaDados;
    provascaiuEmProva: OpcaoToolbar;
    comentarioBuscar: string;
    comentariosComentado: OpcaoToolbar;
    mnemonicoBuscar: string;
    mnemonicosOpcao: OpcaoToolbar;
    buscarTodosDocumentos: boolean;
    matchsResultadoBusca: Array<MatchBuscaTexto>;
    matchResultadoBuscaFoco: MatchBuscaTexto;
    matchTextoResultadoBuscaAvancada: Array<TextoPagina>;
    strProvaBuscar: string = '';
    resultadosBuscaWeb: ResultadoBuscaResponse;
    idPagina: number;
    idLinhaScroll: string;

    constructor(buscaSalva: BuscaSalva = null) {

        if (!buscaSalva) {
            // Provas
            this.provasDados = new Array<ProvaDados>();
            this.provascaiuEmProva = new OpcaoToolbar();
            this.provaBuscar = null;

            // Comentarios
            this.comentarioBuscar = '';
            this.comentariosComentado = new OpcaoToolbar();

            // Mnemonicos
            this.mnemonicoBuscar  = '';
            this.mnemonicosOpcao = new OpcaoToolbar();

            // Marcações simples
            this.marcacoesCaneta = new Array<OpcaoToolbar>();

            this.textoBuscar = '';
            this.buscaSemResultado = false;
            this.buscarTodosDocumentos = false;
            this.estadoJanelaBusca = estadoJanelaBusca.maximizado;
            this.salvandoBusca = false;
            this.matchsResultadoBusca = new Array<MatchBuscaTexto>();
            this.matchTextoResultadoBuscaAvancada = new Array<TextoPagina>();
        } else {
            this.marcacoesCaneta = buscaSalva.marcacoesCaneta.slice();
            this.comentariosComentado = buscaSalva.comentariosComentado;
            this.comentarioBuscar = buscaSalva.comentarioBuscar;
            this.mnemonicoBuscar = buscaSalva.mnemonicoBuscar;
            this.mnemonicosOpcao = buscaSalva.mnemonicosOpcao;
            this.buscarTodosDocumentos = buscaSalva.buscarTodosDocumentos;
            this.idPagina = buscaSalva.idPagina;
            this.provascaiuEmProva = buscaSalva.provascaiuEmProva;
            this.textoBuscar = buscaSalva.textoBuscar;
            this.provaBuscar = buscaSalva.provaBuscar;
            this.estadoJanelaBusca = buscaSalva.estadoJanelaBusca;
            this.matchsResultadoBusca = buscaSalva.matchsResultadoBusca;
            this.matchResultadoBuscaFoco = buscaSalva.matchResultadoBuscaFoco;
        }
    }

    public static fromBuscaSalva(buscaSalva: BuscaSalva): BuscaPanelParameters {
        const params = new BuscaPanelParameters();

        params.marcacoesCaneta = buscaSalva.marcacoesCaneta.slice();
        params.comentariosComentado = buscaSalva.comentariosComentado;
        params.comentarioBuscar = buscaSalva.comentarioBuscar;
        params.mnemonicosOpcao = buscaSalva.mnemonicosOpcao;
        params.mnemonicoBuscar = buscaSalva.mnemonicoBuscar;
        params.buscarTodosDocumentos = buscaSalva.buscarTodosDocumentos;
        params.idPagina = buscaSalva.idPagina;
        params.provascaiuEmProva = buscaSalva.provascaiuEmProva;
        params.textoBuscar = buscaSalva.textoBuscar;
        params.provaBuscar = buscaSalva.provaBuscar;
        params.estadoJanelaBusca = buscaSalva.estadoJanelaBusca;
        params.matchsResultadoBusca = buscaSalva.matchsResultadoBusca;
        params.matchResultadoBuscaFoco = buscaSalva.matchResultadoBuscaFoco;

        return params;
    }

    static possuiCriteriosMarcacao(params: BuscaPanelParameters): boolean {
        if (
            params.marcacoesCaneta.filter(marcacao => marcacao.ativo === true).length === 0 && 
            params.provascaiuEmProva.ativo === false && 
            params.provaBuscar === null && 
            params.comentariosComentado.ativo === false && 
            params.comentarioBuscar.length === 0 && 
            params.comentarioBuscar === '' &&
            params.mnemonicosOpcao.ativo === false &&
            params.mnemonicoBuscar.length == 0 &&
            params.mnemonicoBuscar === ''
        ) {
            return false;
        } else {
            return true;
        }
    }
}

export enum estadoJanelaBusca {
    maximizado = 0,
    resultadoBuscaSimples = 1,
    resultadoBuscaAvancada = 2
}

