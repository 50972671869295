import { Intervalo } from '../components/leitor-content-pagina/pagina.parameters';
import { EntitiesHelper } from '../helpers/entities.helper';
import { TextoPagina } from './pagina/TextoPagina';

export class MatchBuscaTexto extends Intervalo {
    textoItemId: string;
    tituloLei: string;
    caminhoItem: string;
    idLei: string;
    descricaoLei: string;
    id: string;
    idGuiaAtiva: string;

    constructor() {
        super();
        this.id = EntitiesHelper.generateGuid();
    }
}

export class MatchBuscaTextoResultadoBuscaAvancada extends MatchBuscaTexto {
    public textoItem: TextoPagina;

    constructor(match: MatchBuscaTexto) {
        super();
        this.ate = match.ate;
        this.caminhoItem = match.caminhoItem;
        this.cor = match.cor;
        this.de = match.de;
        this.descricaoLei = match.descricaoLei;
        this.id = match.id;
        this.idGuiaAtiva = match.idGuiaAtiva;
        this.idLei = match.idLei;
        this.textoItemId = match.textoItemId;
        this.tipo = match.tipo;
        this.tituloLei = match.tituloLei;
    }
}
