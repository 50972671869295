import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { ConfiguracoesUsuario } from "../../models/usuario/ConfiguracoesUsuario";
import { UserdataDTO } from "../../models/UserdataSync";
import { SignalrService, OperacaoSignalR, EnumTipoObjeto } from "../signalr.service";

@Injectable()
export class UsuarioPreferenciasService {
    private readonly key = 'config';

    public $Configuracoes: Observable<ConfiguracoesUsuario>;
    private _Configuracoes = new BehaviorSubject<ConfiguracoesUsuario>(this.CarregarConfiguracoes());

    constructor(
        private signalrService: SignalrService
    ) {
        this.$Configuracoes = this._Configuracoes.asObservable();
        signalrService.$Mensagem.subscribe(msg => this.onMessageReceived(msg));
    }

    private onMessageReceived(msg: OperacaoSignalR): void {
        if (msg && msg.tipoObjeto === EnumTipoObjeto.Preferencias) {
            this.setConfiguracoes(msg.dados, false);
        }
    }

    private CarregarConfiguracoes(): ConfiguracoesUsuario {
        let retorno = null;

        let json = localStorage.getItem(this.key);
        if (json)
            retorno = JSON.parse(json);

        return retorno;
    }

    public get Configuracoes(): ConfiguracoesUsuario {
        return this._Configuracoes.value;
    }

    private setConfiguracoes(value: ConfiguracoesUsuario, sync) {
        let json = JSON.stringify(value);
        localStorage.setItem(this.key, json);

        this._Configuracoes.next(value);

        if (sync) {
            const mensagem = new OperacaoSignalR();
            mensagem.dados = value;
            this.signalrService.enviarMensagem(mensagem, EnumTipoObjeto.Preferencias);
        }
    }

    public alterarConfiguracoes(config: ConfiguracoesUsuario, audit = true): void {
        if (audit)
            config.dataUltimaAlteracao = new Date();

        this.setConfiguracoes(config, true);
    }

    public fromNuvem(userdata: UserdataDTO): Promise<void> {
        return new Promise((onsuccess, onerror) => {
            const model = ConfiguracoesUsuario.fromModel(userdata);
            this.setConfiguracoes(model, false);

            onsuccess();
        });
    }
}