import { TextoPagina } from './TextoPagina';
import { EstatisticasLeitura, Tempo } from '../usuario/EstatisticasLeitura';
import { BuscaPanelParameters } from '../../components/leitor-content-panelbusca/busca-panel.parameters';
import { ProgressoLeitura } from '../../services/conteudo.service';

export class Conteudo {
    public idLei: string;
    public idGuia: string;
    public linhas: TextoPagina[];
    public estatisticas: EstatisticasLeitura;
    public urlFonteLei: string;
    public tipoDocumento: number;

    public busca: BuscaPanelParameters;
    public progressoLeitura: ProgressoLeitura;
    public tituloGuia: string;
    novaGuia: boolean;

    constructor() {
        this.linhas = new Array<TextoPagina>();
    }

    public static clone(cont: Conteudo): Conteudo {
        const retorno = new Conteudo();

        retorno.idLei = cont.idLei;
        retorno.linhas = cont.linhas;
        retorno.estatisticas = cont.estatisticas;
        retorno.urlFonteLei = cont.urlFonteLei;
        retorno.tipoDocumento = cont.tipoDocumento;

        return retorno;
    }

    static getIdLei(conteudo: Conteudo): string {
        if (conteudo.busca && conteudo.busca.buscarTodosDocumentos) {
            const busca = conteudo.busca;
            if (busca.resultadosBuscaWeb && busca.resultadosBuscaWeb.resultadoAtual) {
                return busca.resultadosBuscaWeb.resultadoAtual.id;
            }
        }
        
        return conteudo.idLei;
    }

    static setIdLei(conteudo: Conteudo, idLei: string) {
        conteudo.idLei = idLei;
    }
}
