import { Component, OnDestroy, Input } from '@angular/core';
import { KeyValue } from '@angular/common';
import { Subscription } from 'rxjs';
import { NumberHelper } from '../../../helpers/numbers.helper';
import { UsuarioGuiasService } from '../../../services/data-services/usuario.guias.service';
import { ConteudoService } from '../../../services/conteudo.service';
import { BuscaRapidaService } from './busca.rapida.service';

@Component({
  selector: 'form-busca-artigo-documento-atual',
  templateUrl: './form-busca-artigo-documento-atual.component.html',
  styleUrls: ['./form-busca-artigo-documento-atual.component.scss']
})
export class FormBuscaArtigoDocumentoAtualComponent implements OnDestroy {
  private subscriptions: Subscription[] = []
  private numeroArtigo = '';

  @Input() prefixoBusca: string;
  @Input() tooltipBusca: string;

  indice: KeyValue<string, number>[];
  found: boolean;

  constructor(
    public buscaRapidaService: BuscaRapidaService,
    private guiaService: UsuarioGuiasService,
    private conteudoService: ConteudoService,
  ) {
    this.subscriptions.push(this.guiaService.$GuiaAtiva.subscribe(() => this.NumeroArtigo = ''))
    this.subscriptions.push(this.conteudoService.IndiceLei.subscribe(i => this.indice = i))
  }

  get NumeroArtigo(): string {
    return this.numeroArtigo
  }

  set NumeroArtigo(value: string) {
    this.numeroArtigo = value.toUpperCase()
    this.txtBuscaChanged(this.numeroArtigo)
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions = []
  }

  txtBuscaChanged(txt: string): void {
    if (!txt || txt.length <= 0)
      return

    const num = NumberHelper.isNumber(txt) ? parseInt(txt) : NumberHelper.unRomanize(txt)
    this.buscaRapidaService.buscar(num)
  }

  validate(event: KeyboardEvent) {
    let char = String.fromCharCode(event.charCode)
    if (!char)
      return false

    const acceptedChars = [
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'I', 'V', 'X', 'L', 'C', 'D', 'M'
    ]

    char = char.toUpperCase()
    if (acceptedChars.indexOf(char) === -1)
      return false

    const newText = this.NumeroArtigo + char
    return NumberHelper.isValidNumberOrRoman(newText)
  }
}
