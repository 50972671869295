import { Component, OnInit, OnDestroy } from '@angular/core';
import { PaineisService } from '../leitor-content-tabcontent/paineis.service';
import { Subscription } from 'rxjs';
import { LeiLookup } from '../../models/lei/lei.lookup';
import { PopupsService } from '../popups/popups.service';
import { ConfiguracoesUsuario } from '../../models/usuario/ConfiguracoesUsuario';
import { UsuarioPreferenciasService } from '../../services/data-services/usuario.preferencias.service';
import { UsuarioGuiasService } from '../../services/data-services/usuario.guias.service';
import { Guia } from '../../models/Guia';
import { CheckForUpdateService } from '../../services/checkForUpdateService';
import { StatusService } from '../../services/status.service';
import { DataControleRepositorio } from '../../repositorios/dataControle.repositorio';
import { ErrorHandlerService } from '../../services/errorHandler.service';
import { ResizedEvent } from 'angular-resize-event';
import { ListArticleService } from "../popups/list-articles/list-article.service";
import { EstatisticasLeitura } from "../../models/usuario/EstatisticasLeitura";
import { AuthService } from '../../modules/shared/services/auth.service';


@Component({
  selector: '[app-navbar]',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  atualizacaoDisponivel: boolean;
  quantidadeItensRevisao = 0;
  subscriptions: Subscription[] = [];

  public model = new NavbarModel();
  public small_content_popup = false;
  public show_popup = false;
  public show_menu = false;
  public apiData;
  public studyPlanData:any = [];
  public planName = 'OAB Fase 1';
  public endDate = ((new Date().getDate() <= 9) ? '0' + new Date().getDate()  : new Date().getDate())  + '/' + ((new Date().getMonth() <= 9) ? '0' + (new Date().getMonth() + 1)  : (new Date().getMonth() + 1)) + '/' + new Date().getFullYear();
  public documentlist:any =[];
  public calculateResultChild;
  public calculateResultParent;
  public totalMarcaLinhas;
  public totalLinhas;
  public totaisPalavrasParent;
  public calculateResultParentHr;
  public calculateResultChildHr;  
  public proResult:any = [];
  guiaAtiva: Guia;

  deferredPrompt: any;
  exibirInstalacao = false;

  addToHomeScreen() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // hide our user interface that shows our A2HS button
          this.exibirInstalacao = false;
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

  constructor(
    public auth: AuthService,
    private paineisService: PaineisService,
    private popupsService: PopupsService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    public checkForUpdateService: CheckForUpdateService,
    public statusService: StatusService,
    public dataControleRepositorio: DataControleRepositorio,
    public ListArticleService: ListArticleService,
    private usuarioGuiasService: UsuarioGuiasService,
    private errorService: ErrorHandlerService
  ) { }

  atualizarApp() {
    this.checkForUpdateService.updateApplication()
  }

  onResized(event: ResizedEvent) {
    if(window.innerWidth > 780) {
      this.show_menu = true;
    } else {
      this.show_menu = false;
    }
  }

  ngAfterViewInit(): void {
    document.onclick = (args: any) : void => {
      if(args.target.tagName === 'P' || args.target.tagName === 'DIV' || args.target.tagName === 'SECTION') {
        this.btnClickPopup('false');
        this.showMenu('false');
      }
    }
  }

  ngOnInit() {
    
    this.subscriptions.push(this.usuarioGuiasService.$GuiaAtiva.subscribe(g => this.guiaAtiva = g))
    this.proResult = [];
    this.studyPlanData=this.ListArticleService.getProgressBarData().then(
     listData => { this.studyPlanData = listData;
        this.planName = this.studyPlanData.estudoNomedoplano;
        this.endDate = this.studyPlanData.dadosFinais;
        this.totalLinhas= this.studyPlanData.totalLinhas;
        this.totalMarcaLinhas= this.studyPlanData.totalMarcaLinhas;
        this.totaisPalavrasParent= this.studyPlanData.totaisPalavras;
        this.calculateResultParent = EstatisticasLeitura.CalcularProgresso( this.totalLinhas,this.totalMarcaLinhas);
        const configuracoes = this.usuarioPreferenciasService.Configuracoes;
        const palavrasMinuto = configuracoes.palavrasMinuto;        
        this.calculateResultParentHr= EstatisticasLeitura.CalcularTempoRestante( palavrasMinuto,this.calculateResultParent, this.totaisPalavrasParent);
        this.calculateResultParent = Math.floor(this.calculateResultParent);
        this.documentlist = this.studyPlanData.documentoProgressoRelatorios;
        var d_list = this.documentlist;
        var total_mis = 0;
        var total_sec = 0;
        if(d_list.length > 0 ){
          d_list.forEach((e,i) => {
            this.calculateResultChild = EstatisticasLeitura.CalcularProgresso(d_list[i].totalLinhas,d_list[i].totalMarcaLinhas);
            this.calculateResultChildHr = EstatisticasLeitura.CalcularTempoRestante( palavrasMinuto,this.calculateResultChild,d_list[i].totaisPalavras);
            this.proResult.push({"cal_res_child": Math.floor(this.calculateResultChild),"time_child":this.calculateResultChildHr,"d_list":d_list[i]});
            total_mis = total_mis + this.calculateResultChildHr['minutos'];
            total_mis = total_mis + (this.calculateResultChildHr['horas'] * 60);
            total_sec = total_sec + this.calculateResultChildHr['segundos'];
          });
        }
        var num = total_mis;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        this.calculateResultParentHr = {"horas" : rhours ,"minutos": rminutes, "segundos" : 0};
      });
    if(window.innerWidth > 780) {
      this.show_menu = true;
    } else {
      this.show_menu = false;
    }
    this.subscriptions.push(this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
      this.model = NavbarModel.fromUserData(config);
    }));

    // usado para teste
    // this.atualizacaoDisponivel = true;

    window.addEventListener('beforeinstallprompt', function (e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.exibirInstalacao = true;
    });
  }

  ngOnDestroy(): void {
    if (this.subscriptions)
      this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  logoff() {
    this.auth.logoff();
  }

  lnkPreferencias_click(event: Event) {
    event.preventDefault();
    this.popupsService.abrirPopupPreferencias();
  }

  public lnkPerfil_click(e: Event): void {
    e.preventDefault();
    this.popupsService.abrirPopupPerfil();
  }

  NovidadesClick() {
    this.paineisService.abrirPainelNovidades();
  }
  showPopup() {
    this.small_content_popup = !this.small_content_popup;
  }
  btnClickPopup(is_open = '') {
    if(is_open != '') {
      this.show_popup = false;
    } else {
      this.show_popup = !this.show_popup;
    }

  }
  showMenu(is_open = '') {
    if(is_open != '') {
      this.show_menu = false;
    } else {
      this.show_menu = !this.show_menu;
    }
  }
  callApi(){
    this.proResult.splice(0, this.proResult.length);
    this.studyPlanData=this.ListArticleService.getProgressBarData().then(
    listData => { this.studyPlanData = listData;
      this.planName = this.studyPlanData.estudoNomedoplano;
      this.endDate = this.studyPlanData.dadosFinais;
      this.totalLinhas = this.studyPlanData.totalLinhas;
      this.totalMarcaLinhas = this.studyPlanData.totalMarcaLinhas;
      this.totaisPalavrasParent = this.studyPlanData.totaisPalavras;
      this.calculateResultParent = EstatisticasLeitura.CalcularProgresso( this.totalLinhas,this.totalMarcaLinhas);
      const configuracoes = this.usuarioPreferenciasService.Configuracoes;
      const palavrasMinuto = configuracoes.palavrasMinuto;

      // this.calculateResultParent = Math.floor(this.calculateResultParent);

      this.calculateResultParentHr = EstatisticasLeitura.CalcularTempoRestante( palavrasMinuto,this.calculateResultParent, this.totaisPalavrasParent);         

      this.documentlist = this.studyPlanData.documentoProgressoRelatorios;
      var d_list = this.documentlist;
      var total_mis = 0;
      var total_sec = 0;
      if(d_list.length > 0 ){
        d_list.forEach((e,i) => {
          this.calculateResultChild = EstatisticasLeitura.CalcularProgresso(d_list[i].totalLinhas,d_list[i].totalMarcaLinhas);
          this.calculateResultChildHr = EstatisticasLeitura.CalcularTempoRestante( palavrasMinuto,this.calculateResultChild,d_list[i].totaisPalavras);
          total_mis = total_mis + this.calculateResultChildHr['minutos'];
          total_mis = total_mis + (this.calculateResultChildHr['horas'] * 60);
          total_sec = total_sec + this.calculateResultChildHr['segundos'];
          this.proResult.push({"cal_res_child": Math.floor(this.calculateResultChild),"time_child":this.calculateResultChildHr,"d_list":d_list[i]});
        });
        console.log(this.proResult);
      }
      var num = total_mis;
      var hours = (num / 60);
      var rhours = Math.floor(hours);
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      this.calculateResultParentHr = {"horas" : rhours ,"minutos": rminutes, "segundos" : 0};      
    });

  }

  public selecionar(item){
    var temp = {'id' : item.idLei, 'titulo' : item.titulo};
    this.usuarioGuiasService.alterarConteudoGuiaTemp('', temp);
    if(!window.location.href.includes('/leitor')) {
      setTimeout(function() {
        window.location.href = 'leitor';  
      }, 100);
      
    }
    
  }

}

export class NavbarModel {
  public user_nome: string;
  public user_foto: string;

  public static fromUserData(user: ConfiguracoesUsuario) {
    const ret = new NavbarModel();

    if (user) {
      ret.user_nome = user.nome;
      ret.user_foto = user.foto;
    }

    return ret;
  }
}
