import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { NovaGuiaService, ModosOrdenacao } from './nova-guia.service';
import { MatDialog } from '@angular/material/dialog';
import { Guia } from '../../../models/Guia';
import { StatusService } from '../../../services/status.service';
import { ConfiguracoesUsuario } from '../../../models/usuario/ConfiguracoesUsuario';
import { LeiLookup } from '../../../models/lei/lei.lookup';
import { UsuarioEstatisticasService } from '../../../services/data-services/usuario.estatisticas.service';
import { UsuarioGuiasService } from '../../../services/data-services/usuario.guias.service';
import { ItemLookupOrdenacaoNovaGuia } from '../../../models/lei/item.lookup.ordenacao.nova.guia';
import { Conteudo } from '../../../models/pagina/conteudo';
import { BuscaService } from '../../../services/busca.service';
import { BuscaPanelParameters } from '../../leitor-content-panelbusca/busca-panel.parameters';

@Component({
  selector: '[nova-guia]',
  templateUrl: './nova-guia.component.html',
  styleUrls: ['./nova-guia.component.scss']
})
export class NovaGuiaComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  linhasLoading: number;
  configuracoes: ConfiguracoesUsuario
  guiaAtiva: Guia;
  opcoesModoOrdenacao = ModosOrdenacao

  listaLeis: Observable<LeiLookup[]>
  filtrarFavoritos: Observable<boolean>;
  modoOrdenacao: Observable<ItemLookupOrdenacaoNovaGuia>;
  painelBuscaAberto: boolean;
  carregando: boolean;

  txtBusca: string
  modoOffline: boolean;

  constructor(
    private novaGuiaService: NovaGuiaService,
    private statusService: StatusService,
    private usuarioEstatisticasService: UsuarioEstatisticasService,
    private usuarioGuiasService: UsuarioGuiasService,
    private buscaService: BuscaService,
    public dialog: MatDialog,
  ) { }

  public ngOnInit(): void {
    this.listaLeis = this.novaGuiaService.Leis    
    this.filtrarFavoritos = this.novaGuiaService.FiltrarFavoritos
    this.modoOrdenacao = this.novaGuiaService.ModoOrdenacao

    this.subscriptions.push(this.novaGuiaService.EventosLoading.subscribe(c => { if (!c){ return; } this.carregando =  c.length > 0 }))
    this.subscriptions.push(this.usuarioGuiasService.$GuiaAtiva.subscribe(g => this.guiaAtiva = g))
    this.subscriptions.push(this.novaGuiaService.TextoBusca.subscribe(txt => this.txtBusca = txt))
    this.subscriptions.push(this.statusService.$AppOffline.subscribe(offline => {
      this.modoOffline = offline
    }))

    setTimeout(() => {
      const div = document.querySelector('.background-animation')
      if (div) {
        this.linhasLoading = Math.floor(div.clientHeight / 57)
      }
    }, 100);

    this.novaGuiaService.carregarLeis(false)
  }

  conteudo_changed(c: Conteudo): void {
    this.painelBuscaAberto = (c && c.busca) ? true : false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    const taskname = 'carregar-menu';
    this.statusService.ocultarMensagemProgresso(taskname);
  }

  public txtBusca_Change(): void {
    this.novaGuiaService.alterarTextoBusca(this.txtBusca);
  }

  public toggleFiltroFavoritos() {
    this.novaGuiaService.favoritos_toggle();
  }

  public alterarModoOrdenacao(novoModo: ItemLookupOrdenacaoNovaGuia): void {
    this.novaGuiaService.alterarModoOrdenacao(novoModo);
  }

  public abrirBusca(): void {
    if (!this.painelBuscaAberto) {
      this.buscaService.abrirPainelBusca(null);
      const params = new BuscaPanelParameters();
      params.textoBuscar = this.txtBusca;
      params.buscarTodosDocumentos = false;
      // if (params.textoBuscar) {
      //   this.buscaService.buscar(params);
      // }
    }
  }

  public selecionar(item: LeiLookup): void {
    console.log(this.guiaAtiva.id);
    console.log(item);
    this.usuarioGuiasService.alterarConteudoGuia(this.guiaAtiva.id, item);
  }

  public async favoritoAlterardo(item: LeiLookup) {
    const estatisticas = await this.usuarioEstatisticasService.marcarFavorito(item.id)
    item.favorita = estatisticas.favorito
  }
}
