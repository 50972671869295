import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { MaskHelper } from '../../../../helpers/mask.helper';
import { DadosPessoaisServidorModel, SolicitacaoAlteracaoDados, DadosCartaoCredito } from '../perfil.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-form-pagamento',
  templateUrl: './form-pagamento.component.html',
  styleUrls: ['./form-pagamento.component.scss']
})
export class FormPagamentoComponent implements OnInit, OnDestroy {
  private headers = new HttpHeaders({
    'content-type': 'application/json'
  });

  @Input() public dadosServidor: DadosPessoaisServidorModel;

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  private s_user: Subscription;

  public bandeiraCartao: string;
  public finalCartao: string;

  public alterar: boolean
  public model: PagamentoModel;
  public bandeiras = BandeiraCartao.listarBandeiras();

  public maskMesAno = MaskHelper.mesAno;

  constructor(private httpClient: HttpClient) { }

  public ngOnInit(): void {
    this.carregarModel();
  }

  private carregarModel(carregarDadosCartao = true) {
    if (carregarDadosCartao) {
      this.bandeiraCartao = this.dadosServidor.resumoCartao.bandeiraCartao;
      this.finalCartao = this.dadosServidor.resumoCartao.finalCartao;
    }

    this.model = new PagamentoModel();
    this.alterar = false;
  }

  public ngOnDestroy(): void {
  }

  public abrir(): void {
    this.carregarModel();
  }

  public fechar(): void { }

  public cancelar(): void {
    this.carregarModel();
  }

  public salvar(): void {
    this.onsaving.emit();

    const dadosCartao = DadosCartaoCredito.fromPagamentoModel(this.model);
    const solicitacao = new SolicitacaoAlteracaoDados();
    solicitacao.dadosCartaoCredito = dadosCartao;

    this.httpClient.post(AppConfig.apiEndpoint + '/DadosCadastroUsuario', solicitacao, { headers: this.headers }).toPromise().then((response: any) => {
      this.onsaved.emit('Sua nova forma de pagamento foi salva com sucesso! Ela será validada na próxima renovação da sua assinatura');

      this.bandeiraCartao = this.model.bandeira.descricao;
      this.finalCartao = this.model.numero;
      while (this.finalCartao.length > 4) {
        this.finalCartao = this.finalCartao.substring(1);
      }

      this.carregarModel(false);
    }).catch(err => {
      this.onsaved.emit(err);
      this.carregarModel();
    });
  }
}

export class PagamentoModel {
  public bandeira: BandeiraCartao;
  public numero: string;
  public codigo: string;
  public vencimento: string;
  public nomeCartao: string;

  public get valido(): boolean {
    return (
      (this.bandeira) &&
      (this.numero && this.numero.length > 0) &&
      (this.codigo && this.codigo.length > 0) &&
      (this.vencimento && this.vencimento.length > 0) &&
      (this.nomeCartao && this.nomeCartao.length > 0)
    ) ? true : false;
  }
}

export class BandeiraCartao {
  public id: number;
  public descricao: string;

  public static listarBandeiras(): BandeiraCartao[] {
    return [
      { id: 1, descricao: 'VISA' },
      { id: 2, descricao: 'AMEX' },
      { id: 3, descricao: 'MASTERCARD' },
      { id: 4, descricao: 'ELO' },
      { id: 5, descricao: 'HIPERCARD' },
      { id: 6, descricao: 'DINERS' }
    ];
  }
}
