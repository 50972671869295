import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConteudoService } from '../../services/conteudo.service';
import { Conteudo } from '../../models/pagina/conteudo';
import { Subscription, Observable } from 'rxjs';

@Component({
  selector: '[leitor-content-tabcontent]',
  templateUrl: './leitor-content-tabcontent.component.html',
  styleUrls: ['./leitor-content-tabcontent.component.css']
})
export class LeitorContentTabcontentComponent implements OnInit, OnDestroy {
  public mostrarNovaGuia: boolean
  public mostrarPainelBusca: boolean
  public mostrarResultadoBuscaAvancada: boolean

  private subscriptions: Subscription[] = []

  constructor(
    private conteudoService: ConteudoService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.conteudoService.Conteudo.subscribe(conteudo => this.conteudo_changed(conteudo)))
  }

  conteudo_changed(conteudo: Conteudo): void {
    if (conteudo) {
      this.mostrarNovaGuia = conteudo.novaGuia
      this.mostrarPainelBusca = conteudo.busca ? true : false
      this.mostrarResultadoBuscaAvancada = conteudo.busca && !conteudo.busca.buscaSemResultado && conteudo.busca.buscarTodosDocumentos
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
    this.subscriptions = []
  }
}
