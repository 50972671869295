import { ErrorLogParameters } from "./errorLogParameters";

export class ErrorLog {

    constructor() {
        this.parametrosAdicionais = new Array<ErrorLogParameters>();
    }

    public clientId: string;
    public mensagem: string;
    public stackTrace: string;
    public emailUsuario: string;
    public dataHora: Date;
    public parametrosAdicionais: Array<ErrorLogParameters>;
    public sincronizadoWebApi: boolean;
    public sincronizadoGoogleAnalytics: boolean;
}
