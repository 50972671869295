import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { MaskHelper } from '../../../../helpers/mask.helper';
import { WebserviceHelper } from '../../../../helpers/webservice.helper';
import { DadosPessoaisServidorModel, DadosPessoais, SolicitacaoAlteracaoDados } from '../perfil.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-form-dados-pessoais',
  templateUrl: './form-dados-pessoais.component.html',
  styleUrls: ['./form-dados-pessoais.component.scss']
})
export class FormDadosPessoaisComponent implements OnInit, OnDestroy {
  private headers = new HttpHeaders({
    'content-type': 'application/json'
  });

  @Input() public dadosServidor: DadosPessoaisServidorModel;

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  public model: DadosPessoaisModel;

  constructor(
    private httpClient: HttpClient
  ) { }

  public ngOnInit(): void {
    this.carregarModel();
  }

  public ngOnDestroy(): void {
  }

  public abrir(): void {
    this.carregarModel();
  }

  public fechar(): void { }

  public cancelar(): void {
    this.carregarModel();
  }

  public salvar(): void {
    this.onsaving.emit();

    const dadosPessoais = DadosPessoais.fromDadosPessoaisModel(this.model);
    const solicitacao = new SolicitacaoAlteracaoDados();
    solicitacao.dadosPessoais = dadosPessoais;

    this.httpClient.post(AppConfig.apiEndpoint + '/DadosCadastroUsuario', solicitacao, { headers: this.headers }).toPromise().then(response => {
      this.onsaved.emit('Seus dados pessoais foram atualizados com sucesso!');
    }).catch(err => {
      this.onsaved.emit(err);
      this.carregarModel();
    });
  }

  private carregarModel(): void {
    this.model = new DadosPessoaisModel(this.dadosServidor.dadosPessoais);
  }

  cep_blur() {
    WebserviceHelper.consultaCep(this.model.cep).then(result => {
      if (result) {
        this.model.cep = result.cep;
        this.model.logradouro = result.logradouro;
        this.model.bairro = result.bairro;
        this.model.cidade = result.cidade;
        this.model.estado = result.estado;
        this.model.pais = 'BR';
      }
    });
  }
}

export class DadosPessoaisModel {
  private cpf_original: string;
  private telefone_original: string;
  private cep_original: string;
  private logradouro_original: string;
  private numero_original: string;
  private bairro_original: string;
  private cidade_original: string;
  private estado_original: string;
  private pais_original: string;
  private complemento_original: string;
  
  constructor(dados: DadosPessoais) {
    this.cpf_original = dados.cpf;
    this.cpf = this.cpf_original;
    
    this.telefone_original = dados.telefone;
    this.telefone = this.telefone_original;
    
    this.cep_original = dados.cep;
    this.cep = this.cep_original;
    
    this.logradouro_original = dados.logradouro;
    this.logradouro = this.logradouro_original;
    
    this.numero_original = dados.numero;
    this.numero = this.numero_original;
    
    this.complemento_original = dados.complemento;
    this.complemento = this.complemento_original;
    
    this.bairro_original = dados.bairro;
    this.bairro = this.bairro_original;
    
    this.cidade_original = dados.cidade;
    this.cidade = this.cidade_original;
    
    this.estado_original = dados.estado;
    this.estado = this.estado_original;
    
    this.pais_original = dados.pais;
    this.pais = this.pais_original;
  }
  
  public cpf: string;
  public telefone: string;
  public cep: string;
  public logradouro: string;
  public numero: string;
  public complemento: string;
  public bairro: string;
  public cidade: string;
  public estado: string;
  public pais: string;

  public maskCelular = MaskHelper.celular;
  public maskMesAno = MaskHelper.mesAno;

  public get alterado(): boolean {
    return (
      (this.cpf !== this.cpf_original) ||
      (this.telefone !== this.telefone_original) ||
      (this.cep !== this.cep_original) ||
      (this.logradouro !== this.logradouro_original) ||
      (this.numero !== this.numero_original) ||
      (this.complemento !== this.complemento_original) ||
      (this.bairro !== this.bairro_original) ||
      (this.cidade !== this.cidade_original) ||
      (this.estado !== this.estado_original) ||
      (this.pais !== this.pais_original)
    ) ? true : false;
  }

  public get valido(): boolean {
    return (
      (this.cpf && this.cpf.length > 0) &&
      (this.telefone && this.telefone.length > 0) &&
      (this.cep && this.cep.length > 0) &&
      (this.logradouro && this.logradouro.length > 0) &&
      (this.numero && this.numero.length > 0) &&
      (this.bairro && this.bairro.length > 0) &&
      (this.cidade && this.cidade.length > 0) &&
      (this.estado && this.estado.length > 0) &&
      (this.pais && this.pais.length > 0)
    ) ? true : false;
  }
}