import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../helpers/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AuthService } from './services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './guards/auth.guard';
import { AssinaturaService } from './services/assinatura.service';
import { BandeirasCartaoComponent } from './components/bandeiras-cartao/bandeiras-cartao.component';
import { TextMaskModule } from 'angular2-text-mask';
import { IndicadorTrialComponent } from './components/indicador-trial/indicador-trial.component';

@NgModule({
  declarations: [
    BandeirasCartaoComponent,
    IndicadorTrialComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    CommonModule,
    MaterialModule,
    FontAwesomeModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    TextMaskModule,
    BandeirasCartaoComponent,
    IndicadorTrialComponent,
  ],
  providers: [
    AuthService,
    AssinaturaService,
    AuthGuard
  ]
})
export class SharedModule { }
