import { Injectable } from "@angular/core";
import { ErrorLog } from "../models/error/errorLog";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { promise } from "protractor";
import { EntitiesHelper } from "../helpers/entities.helper";
import { request } from "http";
import { ok } from "assert";
import { exception } from "console";
import { DbConnection } from "../helpers/storage.helper";
import { Observable, BehaviorSubject } from "rxjs";

const ERROR_LOG_STORE_NAME = 'errorLog'

@Injectable()
export class ErrorLogRepositorio {

    public $RepositorioOk: Observable<boolean>;
    private _repositorioOk= new BehaviorSubject<boolean>(null);

    constructor(
        private dbService: NgxIndexedDBService,
    ) {
        this.$RepositorioOk = this._repositorioOk.asObservable();

        this.checkDb();
    }

    private checkDb() {
        const ret = false;

        var req = window.indexedDB.open('svdm');
        req.onsuccess = (e) => {
            try {
                const db = (e.target as IDBOpenDBRequest).result;
                let store = db.transaction(ERROR_LOG_STORE_NAME);
                if (store) {
                    this._repositorioOk.next(true);
                }
            } catch (error) {
                this._repositorioOk.next(false);
            }
        }
        req.onerror = () => { this._repositorioOk.next(false); };
    }


    /**Retorna */
    public async salvar(value: ErrorLog): Promise<string> {
        if (!this._repositorioOk.value) {
            throw new Error('Não foi possível se conectar ao indexedDB');
        }

        try {
            const id = EntitiesHelper.generateGuid();
            value.clientId = id;
            (await this.dbService.add(ERROR_LOG_STORE_NAME, value));

            return id;
        }
        //Não realizar nada pois é ja é trativa de erro
        catch (error) {
            console.log("Ocorreu um problema ao salvar um log de erro. Detalhes: " + JSON.stringify(error));
        }
    }

    public async atualizar(value: ErrorLog) {
        if (!this._repositorioOk.value) {
            throw new Error('Não foi possível se conectar ao indexedDB');
        }

        try {
            await this.dbService.update(ERROR_LOG_STORE_NAME, value);
        }
        //Não realizar nada pois é ja é trativa de erro
        catch (error) {
            console.log("Ocorreu um problma ao atualizar um log de erro. Detalhes:" + JSON.stringify(error));
        }
    }

    public async excluir(id: number): Promise<void> {
        if (!this._repositorioOk.value) {
            throw new Error('Não foi possível se conectar ao indexedDB');
        }

        try {
            await this.dbService.delete(ERROR_LOG_STORE_NAME, id);

        } catch (error) {
            console.log("Ocorreu um problma ao excluir um log de erro. Detalhes:" + JSON.stringify(error));
        }

    }

    public async buscarTodos() {
        if (!this._repositorioOk.value) {
            throw new Error('Não foi possível se conectar ao indexedDB');
        }

        const a = await this.dbService.getAll<ErrorLog>(ERROR_LOG_STORE_NAME);
        return a;
    }

    public async excluirTodos() {
        if (!this._repositorioOk.value) {
            throw new Error('Não foi possível se conectar ao indexedDB');
        }

        this.dbService.clear(ERROR_LOG_STORE_NAME);
    }
}