import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { PlaceholderPaineisService, PainelMarcacoesProvaParameters } from '../placeholder-paineis.service';
import { ParametrosCaneta, ProvaDatasource, ItemProvaDatasource } from '../../../../models/UserData';
import { Marcacao } from '../../../../models/Marcacao';
import { HoverService } from '../../../../services/hover.service';
import { TagConteudo } from '../../../../interfaces/TagConteudo';
import { TipoSelecao } from '../../../../services/selection.service';
import { Subscription } from 'rxjs';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { UsuarioMarcacoesService } from '../../../../services/data-services/usuario.marcacoes.service';
import { UsuarioProvasService } from '../../../../services/data-services/usuario.provas.service';

@Component({
  selector: 'app-painel-marcacoes-prova',
  templateUrl: './painel-marcacoes-prova.component.html',
  styleUrls: [
    '../placeholder-paineis.style.css',
    './painel-marcacoes-prova.component.css'
  ]
})
export class PainelMarcacoesProvaComponent implements OnInit, OnDestroy {
  private s_prefs: Subscription;
  private s_painelMarcacoesProvaParametersObservable: Subscription;
  public carregando: boolean;

  public parameters: PainelMarcacoesProvaParameters;
  public paleta: ParametrosCaneta;
  public tags: ProvaDatasource;
  public focusBanca: boolean;
  public focusInstituicao: boolean;
  public focusAno: boolean;
  public focusTipo: boolean;
  public focusCargo: boolean;

  @ViewChild('instituicaoElement')
  set textComentario(value: ElementRef) {
    if (value) {
      this.focusInstituicao = true;
    }
  }

  constructor(
    private placeholderPaineisService: PlaceholderPaineisService,
    private hoverService: HoverService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioMarcacoesService: UsuarioMarcacoesService,
    private usuarioProvasService: UsuarioProvasService
  ) { }

  ngOnInit() {
    this.s_prefs = this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
      this.paleta = config.parametrosCaneta;
    });

    this.s_painelMarcacoesProvaParametersObservable = this.placeholderPaineisService.painelMarcacoesProvaParametersObservable.subscribe(p => {
      this.parameters = p;
      this.destacar();
    });

    this.carregarTags();
  }

  private carregarTags(): void {
    this.carregando = true;
    this.usuarioProvasService.carregarDatasource().then(ds => {
      this.tags = ds;
      this.carregando = false;
    });
  }

  ngOnDestroy(): void {
    this.s_painelMarcacoesProvaParametersObservable.unsubscribe();
    this.s_prefs.unsubscribe();
  }

  private destacar(): void {
    if (this.parameters) {
      const tags = new Array<TagConteudo>();
      if (this.parameters.marcacaoAtiva) {
        tags.push(new TagConteudo(this.parameters.marcacaoAtiva, null));
      } else {
        this.parameters.listaMarcacoes.forEach(m => {
          tags.push(new TagConteudo(m, null));
        });
      }

      if (tags.length > 0) {
        this.hoverService.destacar(tags);
      }
    }
  }

  public fecharPainel(): void {
    this.placeholderPaineisService.fecharPainelMarcacoesProva();
  }

  public voltar(): void {
    this.placeholderPaineisService.fecharDetalhePainelMarcacoesProva();
  }

  public editar(marcacao: Marcacao): void {
    this.placeholderPaineisService.abrirDetalhePainelMarcacoesProva(marcacao);
  }

  public mouseEnter(marcacao): void {
    const tags = new Array<TagConteudo>();
    tags.push(new TagConteudo(marcacao, null));

    if (tags.length > 0) {
      this.hoverService.destacar(tags);
    }
  }

  public mouseLeave(): void {
    this.destacar();
  }

  public dec(e: Event, marcacao: Marcacao): void {
    e.preventDefault();
    e.stopPropagation();

    marcacao.cont--;
    if (marcacao.cont === 0) {
      this.remover(e, marcacao);
    } else {
      this.usuarioMarcacoesService.atualizar(marcacao);
    }
  }

  public inc(e: Event, marcacao: Marcacao): void {
    e.preventDefault();
    e.stopPropagation();

    marcacao.cont++;
    this.usuarioMarcacoesService.atualizar(marcacao);
  }

  public remover(e: Event, marcacao: Marcacao): void {
    e.preventDefault();
    e.stopPropagation();

    const index = this.parameters.listaMarcacoes.findIndex(c => c.id === marcacao.id);
    this.parameters.listaMarcacoes.splice(index, 1);

    this.usuarioMarcacoesService.remover(marcacao).then(() => {
      if (this.parameters.listaMarcacoes.length === 0) {
        this.placeholderPaineisService.fecharPainelMarcacoesProva();
      }
    });
  }

  public instituicaoSelected(item: ItemProvaDatasource) {
    this.usuarioProvasService.incluirInstituicao(item);
    if (this.tags.instituicoes.indexOf(item) === -1)
      this.tags.instituicoes.push(item);

    this.parameters.marcacaoAtiva.dados.instituicao = item;
    this.usuarioMarcacoesService.atualizar(this.parameters.marcacaoAtiva);
    this.focusBanca = true;
  }

  public bancaSelected(item: ItemProvaDatasource) {
    this.usuarioProvasService.incluirBanca(item);
    if (this.tags.bancas.indexOf(item) === -1)
      this.tags.bancas.push(item);

    this.parameters.marcacaoAtiva.dados.banca = item;
    this.usuarioMarcacoesService.atualizar(this.parameters.marcacaoAtiva);
    this.focusAno = true;
  }

  public anoSelected(item: ItemProvaDatasource) {
    this.usuarioProvasService.incluirAno(item);
    if (this.tags.anos.indexOf(item) === -1)
      this.tags.anos.push(item);

    this.parameters.marcacaoAtiva.dados.ano = item;
    this.usuarioMarcacoesService.atualizar(this.parameters.marcacaoAtiva);
    this.focusTipo = true;
  }

  public tipoSelected(item: ItemProvaDatasource) {
    this.usuarioProvasService.incluirTipo(item);
    if (this.tags.tipos.indexOf(item) === -1)
      this.tags.tipos.push(item);

    this.parameters.marcacaoAtiva.dados.tipo = item;
    this.usuarioMarcacoesService.atualizar(this.parameters.marcacaoAtiva);
    this.focusCargo = true;
  }

  public cargoSelected(item: ItemProvaDatasource) {
    this.usuarioProvasService.incluirCargo(item);
    if (this.tags.cargos.indexOf(item) === -1)
      this.tags.cargos.push(item);

    this.parameters.marcacaoAtiva.dados.cargo = item;
    this.usuarioMarcacoesService.atualizar(this.parameters.marcacaoAtiva);
  }

  public getDescriptor(marcacao: Marcacao): string {
    const instituicao = (marcacao.dados.instituicao) ? marcacao.dados.instituicao.descricao : '';
    const banca = (marcacao.dados.banca) ? marcacao.dados.banca.descricao : '';
    const ano = (marcacao.dados.ano) ? marcacao.dados.ano.descricao : '';
    const tipo = (marcacao.dados.tipo) ? marcacao.dados.tipo.descricao : '';
    const cargo = (marcacao.dados.cargo) ? marcacao.dados.cargo.descricao : '';

    const labelarray = new Array(instituicao, banca, ano, tipo, cargo).filter(String);
    const label = labelarray.join(' - ');

    return label;
  }

  get titlebarColor(): string {
    // return this.parameters.tipoMarcacao === TipoSelecao.Prova ? this.paleta.corTagProva : this.paleta.corTagErrouProva;
    return this.parameters.tipoMarcacao === TipoSelecao.Prova ? this.paleta.corTagProva : this.paleta.corTagProva;
  }

  get titlebarText(): string {
    return this.parameters.tipoMarcacao === TipoSelecao.Prova ? 'Caiu em prova' : 'Errei na prova';
  }

  limparFoco(value: string) {
    switch (value) {
      case 'focusInstituicao':
        this.focusInstituicao = false;
        break;
      case 'focusBanca':
        this.focusBanca = false;
        break;
      case 'focusAno':
        this.focusAno = false;
        break;
      case 'focusTipo':
        this.focusTipo = false;
        break;
      case 'focusCargo':
        this.focusCargo = false;
        break;
    }
  }
}
