
import { throwError as observableThrowError, Observable, BehaviorSubject } from 'rxjs';

import { switchMap, take, filter, catchError } from 'rxjs/operators';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UsuarioPreferenciasService } from '../services/data-services/usuario.preferencias.service';
import { AuthService } from '../modules/shared/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private refreshTokenSubject = new BehaviorSubject<any>(null);

    constructor(
        public auth: AuthService,
        public userDataService: UsuarioPreferenciasService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* if (
            request.url.indexOf('svm-log-api/api') > 0
            || (request.url.indexOf('api.supervademecum.com.br') === -1 && request.url.indexOf('localhost') === -1)
        ) {
            return next.handle(request);
        } */
        if (
            request.url.indexOf('svm-log-api/api') > 0 
            || (request.url.indexOf('pruralapps.siteproofs.net') === -1 && request.url.indexOf('api.supervademecum.com.br') === -1 && request.url.indexOf('localhost') === -1)
        ) {
            return next.handle(request);
        }

        return next.handle(this.addAuthenticationToken(request)).pipe(catchError(error => {
            if (request.url.includes('refresh-token') || request.url.includes('login')) {
                if (request.url.includes('refresh-token')) {
                    this.auth.logoff();
                }

                return observableThrowError(error);
            }

            if (error.status !== 401) {
                return observableThrowError(error);
            }

            if (this.refreshTokenInProgress) {
                return this.refreshTokenSubject.pipe(
                    filter(r => r != null),
                    take(1),
                    switchMap(() => next.handle(this.addAuthenticationToken(request))));
            } else {
                this.refreshTokenInProgress = true;
                this.refreshTokenSubject.next(null);

                const data = this.userDataService.Configuracoes;
                return this.auth.refreshToken(data.email, data.authToken).pipe(switchMap((token: string) => {
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(token);

                    return next.handle(this.addAuthenticationToken(request));
                }), catchError((err: any) => {
                    this.refreshTokenInProgress = false;
                    return observableThrowError(error);
                }));
            }
        }));
    }

    addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        const token = this.auth.accessToken;

        if (!token) {
            return request;
        }

        const ret = request.clone({ setHeaders: { Authorization: 'bearer ' + token.token } });
        return ret;
    }
}