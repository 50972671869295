import { Guia } from '../../models/Guia';
import { Selection } from '../../services/selection.service';
import { Comentario } from '../../models/Comentario';
import { Marcacao } from '../../models/Marcacao';
import { PreferenciasUsuario } from '../../models/UserData';

export class PaginaParameters {
    idGuiaAtiva: string;
    guiaAtiva: Guia;
    selection: Selection;
    preferencias: PreferenciasUsuario;

    constructor() {
        this.preferencias = new PreferenciasUsuario();
    }
}

export class RangeMarcaTexto {
    de: number;
    ate: number;
    cor: string;
    dataOrdem: Date;
    intervalos: Array<Intervalo>;

    copiar(): RangeMarcaTexto {
        const novo = new RangeMarcaTexto();

        novo.de = this.de;
        novo.ate = this.ate;
        novo.cor = this.cor;
        novo.dataOrdem = this.dataOrdem;

        return novo;
    }
}

export class RangeMarcacao {
    de: number;
    ate: number;
    comentario: Comentario;
    marcacao: Marcacao;
    dataOrdem: Date;
    intervalos: Array<Intervalo>;

    copiar(): RangeMarcacao {
        const novo = new RangeMarcacao();

        novo.de = this.de;
        novo.ate = this.ate;
        novo.comentario = this.comentario;
        novo.marcacao = this.marcacao;
        novo.dataOrdem = this.dataOrdem;

        return novo;
    }

    constructor() {
        this.intervalos = new Array<Intervalo>();
    }
}

export class Intervalo {
    de: number;
    ate: number;
    cor: string;
    tipo: string;
}
