import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ItemProvaDatasource } from '../../../../../models/UserData';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-tag-picker',
  templateUrl: './tag-picker.component.html',
  styleUrls: ['./tag-picker.component.css']
})
export class TagPickerComponent implements OnInit {
  @ViewChild('control') controleTexto: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  public searchText: string;
  @Input() nullText: string;
  @Input('options') _options: ItemProvaDatasource[];
  @Input() selected: ItemProvaDatasource;
  @Input() set focus(value: boolean) {
    if (value) {
      const el = <HTMLInputElement>this.controleTexto.nativeElement;
      setTimeout(() => {
        el.focus();
      });
    }
  }
  @Output() onSelect: EventEmitter<ItemProvaDatasource> = new EventEmitter();
  @Output() onFocused: EventEmitter<boolean> = new EventEmitter();

  constructor(private render: Renderer2) { }

  ngOnInit() {
    this.searchText = this.selected ? this.selected.descricao : null;
  }

  public include(e: Event): void {
    if (!this.canInclude) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }

    const item = new ItemProvaDatasource();
    item.descricao = this.searchText.trim();

    this.autocomplete.closePanel();

    this._options.push(item);
    this.select(item);

  }

  public select(item: ItemProvaDatasource): void {
    this.selected = item;
    this.searchText = item.descricao;
    this.onSelect.emit(this.selected);
  }

  get label(): string {
    return this.nullText;
  }

  get canInclude(): boolean {
    return this.options.length === 0 && (this.searchText && this.searchText.trim().length > 0);
  }

  get options(): ItemProvaDatasource[] {
    if (this.searchText && this.searchText.trim().length > 0) {
      return this._options.filter(o => o.descricao.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    } else {
      return this._options;
    }
  }
}
