import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: '[chip-prova]',
  templateUrl: './chip-prova.component.html',
  styleUrls: ['./chip-prova.component.scss']
})
export class ChipProvaComponent implements OnInit {
  @Input()
  prova: any;

  constructor() { }

  ngOnInit() {
  }

}
