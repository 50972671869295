import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LeiRepositorio } from "../../../repositorios/lei.repositorio";

import {  CompressionHelper ,CompressedResult } from "../../../helpers/compression.helper";
// import { LeiLookup } from "/angular/SuperVadeMecumClient/src/app/models/Lei/lei.lookup";


@Injectable({
  providedIn: 'root'
})
export class ListArticleService {
  private result:any;
  private readingData:any;
  private planData:any = [];
  private ProgressbarData:any = [];

  constructor(private httpClient : HttpClient,
  private leiRepositorio: LeiRepositorio  ) { }

  public async getlist(selectedId:string){
     this.result = await this.leiRepositorio.Getlist(selectedId);
     return this.result;
  }
  public async getReadingModel(){
      this.readingData = await this.leiRepositorio.GetReadingSpeedlist();
      return this.readingData;
  }
  public async getStudyPlans(){
    this.planData = await this.leiRepositorio.GetStudyPlanData();
    return this.planData;
  }

  public async saveStudyPlans(sendData){
    this.planData = await this.leiRepositorio.SaveStudyPlanData(sendData);
    return this.planData;
  }
  public async getProgressBarData(){
    this.ProgressbarData = await this.leiRepositorio.GetProgressBarData();
    return this.ProgressbarData;
  }

  public async getReadCount(sendData){
    this.planData = await this.leiRepositorio.getReadCount(sendData);
    return this.planData;
  }

}
