import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { TrechoTexto } from '../../../../../models/pagina/TrechoTexto';
import { TextoPagina } from '../../../../../models/pagina/TextoPagina';
import { PlaceholderPaineisService } from '../../../placeholder-paineis/placeholder-paineis.service';
import { SelectionService, ModoSelecao } from '../../../../../services/selection.service';
import { UsuarioGrifosService } from '../../../../../services/data-services/usuario.grifos.service';

@Component({
  selector: 'app-trecho-texto',
  templateUrl: './trecho-texto.component.html',
  styleUrls: ['./trecho-texto.component.css']
})
export class TrechoTextoComponent implements OnInit {
  @Input()
  public trechoTexto: TrechoTexto;

  @Input()
  public linha: TextoPagina;

  public contemTabela: boolean;
  public tabela: Tabela;
  constructor(
    private placeholderPaineisService: PlaceholderPaineisService,
    private usuarioGrifosService: UsuarioGrifosService,
    private selectionService: SelectionService
  ) { }

  ngOnInit() { this.GerarLinhas(); }

  processClick(e: Event) {
    e.stopPropagation();

    if (this.selectionService.selection.modo === ModoSelecao.Borracha && this.trechoTexto.grifo) {
      this.usuarioGrifosService.remover(this.trechoTexto.grifo);
    }

    if (!this.trechoTexto.marcacao && !this.trechoTexto.comentario) { return; }

    if (this.trechoTexto.marcacao) {
      document.getElementById('prov-sup-input-' + this.trechoTexto.marcacao.id).focus();
      // this.placeholderPaineisService.abrirPainelMarcacoesProva(TipoSelecao.Prova, this.linha, this.linha.marcacoesProva, this.trechoTexto.marcacao);
    } else if (this.trechoTexto.comentario && !this.trechoTexto.comentario.mnemonico) {
      document.getElementById('coment-sup-input-' + this.trechoTexto.comentario.id).focus();
      // this.placeholderPaineisService.abrirPainelComentarios(this.linha, this.linha.comentarios, this.trechoTexto.comentario);
    } else {
      document.getElementById('mne-sup-input-' + this.trechoTexto.comentario.id).focus();
    }
  }


  public quebrarLinhasTabela(texto: string): Array<string> {
    const colunas = texto.split('⊞');
    return colunas;
  }

  public quebrarColunasLinhasTabela(texto: string): Array<string> {
    const linhas = texto.split('⠇');
    return linhas;
  }

  public GerarLinhas() {
    this.contemTabela = this.linha.tipoTexto.indexOf('texto-linhatabela') !== -1;

    if (!this.contemTabela) {
      return;
    }

    this.tabela = new Tabela();
    this.quebrarLinhasTabela(this.trechoTexto.texto).forEach(linha => {

      const colunas = this.quebrarColunasLinhasTabela(linha);
      const linhaTabela = new LinhaTabela();
      colunas.forEach(c => {
        const coluna = new Coluna();
        coluna.texto = c;
        coluna.width = (100 / colunas.length).toString() + '%';

        linhaTabela.colunas.push(coluna);
      });

      this.tabela.linhas.push(linhaTabela);
    });
  }

  // public ajustarTabela() {
  //   if (!this.conteiner) {
  //     console.log(this.conteiner);
  //     return;
  //   }
  //   console.log(this.conteiner.nativeElement);

  //   const width = <HTMLCollectionOf<HTMLDivElement>>(document.getElementsByClassName('tbl-container'));


  //   // use whatever width calculation you'd like...
  //   const targetWidth = 100 / visibleDivs.length;

  //   // for (let index = 0; index < visibleDivs.length; index++) {
  //   //   for (let j = 0; j < visibleDivs[index].children.length; j++) {
  //   //     visibleDivs[index].children[j]. = targetWidth;
  //   //   }
  //   // }

  //   // apply the width to the divs
  //   // console.log(visibleDivs);
  //   // visibleDivs.width(targetWidth);
  // }

}

class Tabela {
  public linhas: Array<LinhaTabela>;
  constructor() {
    this.linhas = new Array<LinhaTabela>();
  }
}

class LinhaTabela {
  public colunas: Array<Coluna>;
  constructor() {
    this.colunas = new Array<Coluna>();
  }
}

class Coluna {
  public texto: string;
  public width: string;
}
