export class CadastroUsuarioPlanoRequest {
    public tipoCadastro: string
    public tipoPlano: string
    public idConvite: string

    public nome: string
    public email: string
    public telefone: string
    public senha: string

    public idPlano: number
    public cpf: string
    public dataNascimento: string
    public meioPagamento: number
    public numeroCartao: string
    public nomeTitularCartao: string
    public vencimentoCartao: string
    public codigoSegurancaCartao: string
    public cep: string
    public logradouro: string
    public numeroLogradouro: string
    public complemento: string
    public bairro: string
    public cidade: string
    public uf: string
    public siglaPais: string

    public deAcordoNewsletter: boolean
    public deAcordoPoliticaPrivacidade: boolean
    public deAcordoTermoCompra: boolean
}