import { Component, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { PopupsService } from "../../../popups/popups.service";
import { ConteudoService } from "../../../../services/conteudo.service";
import { Subscription } from "rxjs";
import { Conteudo } from "../../../../models/pagina/conteudo";

@Component({
    selector: 'app-cabecalho',
    templateUrl: './cabecalho.component.html',
    styleUrls: ['./cabecalho.component.scss']
})
export class CabecalhoComponent implements OnDestroy {

    
    @Input() indexLinhaAtual: number;
    @Input() tituloAtual: string;
    
    @Output() focoChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() exibirRevogadosChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public exibirRevogados: boolean;
    public carregandoConteudo: boolean;
    public prefixoBusca: string;
    public tooltipBusca: string;
    public totalLinhas: number;
    public urlFonteLei: string;

    private subscriptions = new Array<Subscription>()
    constructor(
        private popupsServices: PopupsService,
        private conteudoService: ConteudoService
    ) {
        this.subscriptions.push(this.conteudoService.Conteudo.subscribe(c => this.conteudo_subscribe(c)));
        this.subscriptions.push(this.conteudoService.Carregando.subscribe(c => this.carregandoConteudo = c));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(x => x.unsubscribe());
    }

    public conteudo_subscribe(c: Conteudo){ 
        if (!c) { return; } 
        
        this.exibirRevogados = this.conteudoService.getExibirRevogadosGuiaAtual();
        this.exibirRevogadosChange.emit(this.exibirRevogados);

        switch (c.tipoDocumento) {
            case 0:
              this.prefixoBusca = "Art. nº";
              this.tooltipBusca = "Buscar pelo número do artigo";
              break;
            case 1:
              this.prefixoBusca = "Súm. nº";
              this.tooltipBusca = "Buscar pelo número da súmula";
              break;
            case 2:
              this.prefixoBusca = "Art. nº";
              this.tooltipBusca = "Buscar pelo número do artigo";
              break;
            default:
              this.prefixoBusca = "";
              this.tooltipBusca = "";
              break;
        }

        this.totalLinhas = c.linhas.length;
        this.urlFonteLei = c.urlFonteLei;

    }

    public get tituloAtualWrapped(): string {
        if (!this.tituloAtual) {
            return '';
        }

        return this.tituloAtual.length > 75 ? this.tituloAtual.substring(0, 75) + '...' : this.tituloAtual;
    }

    public alterarExibicaoRevogados() {
        this.exibirRevogados = !(this.exibirRevogados);
        this.conteudoService.alterarExibicaoRevogadosGuiaAtual(this.exibirRevogados);
        this.exibirRevogadosChange.emit(this.exibirRevogados);
    }

    public abrirIndiceSistematico() {
        this.popupsServices.abrirPopupIndiceSistematico();
    }

}