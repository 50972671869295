import { BaseObj } from "../UserdataSync";
import { Lei } from "../Lei";
import { EncryptionHelper } from "../../helpers/encryption.helper";
import { AppConfig } from "../../app.config";
import { LeiInfo } from "./lei.info";
import { LeiConteudo } from "./lei.conteudo";

export class LeiStorage extends BaseObj {
    descricao: string;
    dados: string;
    quantidadeItens: number;
    quantidadePalavras: number;
    dataHoraUltimaModificacaoTextoLei: Date;
    dataHoraUltimaModificacaoOficial: Date;
    tipoDocumento: number;
    disponivel: boolean

    static Load(info: LeiInfo, conteudo: LeiConteudo): LeiStorage {
        return {
            id: info.id,
            descricao: info.descricao,
            quantidadeItens: info.quantidadeItens,
            quantidadePalavras: info.quantidadePalavras,
            dataHoraUltimaModificacaoTextoLei: info.dataHoraUltimaModificacaoTextoLei,
            dataHoraUltimaModificacaoOficial: info.dataHoraUltimaModificacaoOficial,
            tipoDocumento: info.tipoDocumento,
            disponivel: info.disponivel,
            dados: (conteudo) ? conteudo.dados : null,
            dataHoraModificacao: null,
            removido: null
        }
    }

    public static ToLei(ls: LeiStorage): Lei {
        return this.leiDecrypt(ls);
    }

    public static FromLei(lei: Lei): LeiStorage {
        const ls = this.leiEncrypt(lei);

        ls.dataHoraUltimaModificacaoOficial = lei.dataHoraUltimaModificacaoOficial
        ls.quantidadePalavras = lei.quantidadePalavras;
        ls.quantidadeItens = lei.quantidadeItens;

        return ls;
    }

    private static leiEncrypt(lei: Lei): LeiStorage {
        const saveObj = new LeiStorage();

        saveObj.id = lei.id;
        saveObj.descricao = lei.descricao;
        saveObj.dataHoraUltimaModificacaoTextoLei = lei.dataHoraUltimaModificacao;
        saveObj.dataHoraUltimaModificacaoOficial = lei.dataHoraUltimaModificacaoOficial;
        saveObj.tipoDocumento = lei.tipoDocumento;
        saveObj.dados = EncryptionHelper.Encrypt(JSON.stringify(lei), AppConfig.key);

        return saveObj;
    }

    private static leiDecrypt(encLei: LeiStorage): Lei {
        const lei = JSON.parse(EncryptionHelper.Decrypt(encLei.dados, AppConfig.key));
        return lei;
    }
}