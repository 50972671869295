import { Routes, RouterModule } from '@angular/router';
import { LeitorComponent } from '../components/leitor/leitor.component';
import { StudyPlanComponent } from '../components/leitor/study-plan/study-plan.component';
import { AuthGuard, AuthGuardParams } from '../modules/shared/guards/auth.guard';
import { BrowserCompatibleGuard, BrowserNotCompatibleGuard } from '../modules/shared/guards/browser.compatibility.guards';
import { DownloadNotRequiredGuard, DownloadRequiredGuard } from '../modules/shared/guards/downloader.guards';
import { DownloaderComponent } from '../pages/downloader/downloader.component';
import { IncompatibleBrowserComponent } from '../pages/incompatible-browser/incompatible-browser.component';
import { LogoffComponent } from '../pages/logoff/logoff.component';

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('../modules/auth/auth.module').then(m => m.AuthModule),
        canActivate: [BrowserNotCompatibleGuard]
    },
    {
        path: 'leitor', component: LeitorComponent,
        canActivate: [AuthGuard, DownloadNotRequiredGuard, BrowserNotCompatibleGuard],
        data: { 'authGuardParams': AuthGuardParams.loggedInAndNotFirstAccess() }
    },
    {
        path: 'downloader',
        component: DownloaderComponent,
        canActivate: [AuthGuard, DownloadRequiredGuard, BrowserNotCompatibleGuard],
        data: { 'authGuardParams': AuthGuardParams.loggedInAndNotFirstAccess() }
    },
    {
        path: 'compatibility-error',
        component: IncompatibleBrowserComponent,
        canActivate: [AuthGuard, BrowserCompatibleGuard]
    },
    {
        path: 'logoff',
        component: LogoffComponent,
        canActivate: [BrowserNotCompatibleGuard],
        data: { 'authGuardParams': AuthGuardParams.loggedInAndNotFirstAccess() }
    },
    { 
        path: 'study-plan',
        component: StudyPlanComponent,
        canActivate: [AuthGuard, DownloadNotRequiredGuard, BrowserNotCompatibleGuard], 
        data: { 'authGuardParams': AuthGuardParams.loggedInAndNotFirstAccess() } 
    },
    {
        path: '**',
        redirectTo: 'leitor'
    }
];

export const routing = RouterModule.forRoot(appRoutes);
