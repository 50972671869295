import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BuscaService } from '../../../../services/busca.service';
import { BuscaPanelParameters } from '../../../leitor-content-panelbusca/busca-panel.parameters';
import { OpcaoToolbar } from '../../../leitor-toolbar/opcaoToolbar';
import { TipoSelecao } from '../../../../services/selection.service';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { UsuarioAnotacoesService } from '../../../../services/data-services/usuario.anotacoes.service';
import { UsuarioGuiasService } from '../../../../services/data-services/usuario.guias.service';
import { Guia } from '../../../../models/Guia';
import { LeiRepositorio } from '../../../../repositorios/lei.repositorio';
import { Grifo } from '../../../../models/Grifo';
import { Item } from '../../../../models/Item';
import { Lei } from '../../../../models/Lei';
import { StatusService } from '../../../../services/status.service';

@Component({
  selector: 'app-info-lei',
  templateUrl: './info-lei.component.html',
  styleUrls: ['./info-lei.component.scss']
})
export class InfoLeiComponent implements OnInit {
  public carregando: boolean;
  private guiaAtiva: Guia;

  lei: Lei;

  constructor(
    public dialogRef: MatDialogRef<InfoLeiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InfoLeiModel,
    private usuarioGuiasService: UsuarioGuiasService,
    private buscaService: BuscaService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioAnotacoesService: UsuarioAnotacoesService,
    private leiRepositorio: LeiRepositorio,
    private statusService: StatusService
  ) { }

  ngOnInit() {
    this.usuarioGuiasService.$GuiaAtiva.subscribe(g => this.guiaAtiva = g);
    this.carregar();
  }

  public btnVoltar_click() {
    this.dialogRef.close();
  }

  public async btnAbrir_click() {
    this.dialogRef.close();
    await this.carregarConteudo()
  }

  public carregarConteudo(): Promise<any> {
    return new Promise(async resolve => {
      const lei = await this.leiRepositorio.carregarItemLookup(this.data.idLei)
      await this.usuarioGuiasService.alterarConteudoGuia(this.guiaAtiva.id, lei);

      resolve()
    });
  }

  public btnMarcacoesProva_click() {
    if (this.data.qtdMarcacoes === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.provascaiuEmProva.ativo = true;

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public btnComentarios_click() {
    if (this.data.qtdComentarios === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.comentariosComentado.ativo = true;

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }


  public btnMnemonicos_click() {
    if (this.data.qtdMnemonicos === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.mnemonicosOpcao.ativo = true;

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public async btnMarcacoesCaneta1_click() {
    if (this.data.qtdGrifo1 === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(this.usuarioPreferenciasService.Configuracoes.parametrosCaneta)
      .filter(marcacao => marcacao.tipo === TipoSelecao.Caneta1);

    p.marcacoesCaneta.forEach(m => {
      m.ativo = true;
    });

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public async btnMarcacoesCaneta2_click() {
    if (this.data.qtdGrifo2 === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(this.usuarioPreferenciasService.Configuracoes.parametrosCaneta)
      .filter(marcacao => marcacao.tipo === TipoSelecao.Caneta2);

    p.marcacoesCaneta.forEach(m => {
      m.ativo = true;
    });

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public async btnMarcacoesCaneta3_click() {
    if (this.data.qtdGrifo3 === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(this.usuarioPreferenciasService.Configuracoes.parametrosCaneta)
      .filter(marcacao => marcacao.tipo === TipoSelecao.Caneta3);

    p.marcacoesCaneta.forEach(m => {
      m.ativo = true;
    });

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public async btnMarcacoesCaneta4_click() {
    if (this.data.qtdGrifo4 === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(this.usuarioPreferenciasService.Configuracoes.parametrosCaneta)
      .filter(marcacao => marcacao.tipo === TipoSelecao.Caneta4);

    p.marcacoesCaneta.forEach(m => {
      m.ativo = true;
    });

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  public async btnMarcacoesCaneta5_click() {
    if (this.data.qtdGrifo5 === 0)
      return;

    const p = new BuscaPanelParameters();

    p.buscarTodosDocumentos = false;
    p.marcacoesCaneta = OpcaoToolbar.CarregarItensMenu(this.usuarioPreferenciasService.Configuracoes.parametrosCaneta)
      .filter(marcacao => marcacao.tipo === TipoSelecao.Caneta5);

    p.marcacoesCaneta.forEach(m => {
      m.ativo = true;
    });

    this.carregarConteudo().then(() => {
      this.buscaService.buscar(p);
      this.dialogRef.close();
    });
  }

  private async carregar() {
    this.statusService.mostrarMensagemProgresso('Carregando resumo da lei', 'resumo-lei')
    this.carregando = true;

    const idLei = this.data.idLei;
    const prefs = this.usuarioPreferenciasService.Configuracoes;

    this.lei = await this.leiRepositorio.carregarLei(idLei)
    const anotacoes = await this.usuarioAnotacoesService.BuscarAnotacoesApontamentosPorLei(idLei);

    this.data.qtdMarcacoes = anotacoes.marcacoes.filter(m => m.range.idItens.filter(id => !this.checarLinhaRevogada(id.idItem)).length > 0).length;
    this.data.qtdComentarios = anotacoes.comentarios.filter(x => !x.mnemonico && x.range.idItens.filter(id => !this.checarLinhaRevogada(id.idItem)).length > 0).length;
    this.data.qtdMnemonicos = anotacoes.comentarios.filter(x => x.mnemonico && x.range.idItens.filter(id => !this.checarLinhaRevogada(id.idItem)).length > 0).length;

    this.data.corTagProva = prefs.parametrosCaneta.corTagProva;
    this.data.corTagComentario = prefs.parametrosCaneta.corTagComentario;
    this.data.corTagMnemonico = prefs.parametrosCaneta.corTagMnemonico;

    this.data.qtdGrifo1 = anotacoes.grifos.filter(g => g.tipo === TipoSelecao.Caneta1 && !this.checarLinhaRevogada(g.idItem)).length;
    this.data.qtdGrifo2 = anotacoes.grifos.filter(g => g.tipo === TipoSelecao.Caneta2 && !this.checarLinhaRevogada(g.idItem)).length;
    this.data.qtdGrifo3 = anotacoes.grifos.filter(g => g.tipo === TipoSelecao.Caneta3 && !this.checarLinhaRevogada(g.idItem)).length;
    this.data.qtdGrifo4 = anotacoes.grifos.filter(g => g.tipo === TipoSelecao.Caneta4 && !this.checarLinhaRevogada(g.idItem)).length;
    this.data.qtdGrifo5 = anotacoes.grifos.filter(g => g.tipo === TipoSelecao.Caneta5 && !this.checarLinhaRevogada(g.idItem)).length;

    this.data.corCaneta1 = prefs.parametrosCaneta.corCaneta1;
    this.data.nomeCaneta1 = prefs.parametrosCaneta.descricaoCaneta1;

    this.data.corCaneta2 = prefs.parametrosCaneta.corCaneta2;
    this.data.nomeCaneta2 = prefs.parametrosCaneta.descricaoCaneta2;

    this.data.corCaneta3 = prefs.parametrosCaneta.corCaneta3;
    this.data.nomeCaneta3 = prefs.parametrosCaneta.descricaoCaneta3;

    this.data.corCaneta4 = prefs.parametrosCaneta.corCaneta4;
    this.data.nomeCaneta4 = prefs.parametrosCaneta.descricaoCaneta4;

    this.data.corCaneta5 = prefs.parametrosCaneta.corCaneta5;
    this.data.nomeCaneta5 = prefs.parametrosCaneta.descricaoCaneta5;

    this.carregando = false;
    this.statusService.ocultarMensagemProgresso('resumo-lei')
  }

  // Retorna true caso:
  // - Não encontre uma linha para o ID informado
  // - Não encontre uma versão para a linha do ID informado
  // - A versão atual da linha esteja revogada
  private checarLinhaRevogada(idLinhaVerificar: string) {
    const iLinha = this.lei.itens.findIndex(i => i.id === idLinhaVerificar)
    if (iLinha === -1)
      return true

    const iVersao = this.lei.itens[iLinha].versoes.length - 1
    if (iVersao === -1)
      return true

    return this.lei.itens[iLinha].versoes[iVersao].revogado
  }
}

export class InfoLeiModel {
  public idLei: string;
  public nomeLei: string;
  public resumo: string;

  public qtdMarcacoes: number;
  public qtdComentarios: number;
  public qtdMnemonicos: number;

  public qtdGrifo1: number;
  public qtdGrifo2: number;
  public qtdGrifo3: number;
  public qtdGrifo4: number;
  public qtdGrifo5: number;

  public qtdPalavras: number;
  public progressoLido: number;
  public tempoRestante: string;
  public tempoTotal: string;
  public tempoTimer: string;

  public dataAtualizacao: string;

  public corCaneta1: string;
  public nomeCaneta1: string;

  public corCaneta2: string;
  public nomeCaneta2: string;

  public corCaneta3: string;
  public nomeCaneta3: string;

  public corCaneta4: string;
  public nomeCaneta4: string;

  public corCaneta5: string;
  public nomeCaneta5: string;

  public corTagProva: string;
  public corTagComentario: string;
  public corTagMnemonico: string;
}