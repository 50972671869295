import { Injectable } from "@angular/core";
import { LocalDataService } from "./data-services/local.data.service";
import { DataControleRepositorio } from "../repositorios/dataControle.repositorio";
import { StatusService } from "./status.service";
import { EnumTipoDataControle } from "../models/dataControle";

@Injectable()
export class LeiService {
    constructor(
        private localDataService: LocalDataService,
        private dataControleRepositorio: DataControleRepositorio,
        private statusService: StatusService
    ) { }

    verificarAtualizacoesModoOffline(): Promise<boolean> {
        // console.log("Lei service - verificar atualizações modo offline");
        return new Promise(async resolve => {
            const localData = this.localDataService.carregarConfiguracoes()

            if (!this.statusService.isAppOnline || !localData || !localData.ModoOffline){
                // console.log("Lei service - downloader não será iniciado. detalhes: App online? " + this.statusService.isAppOnline)
                // console.log("modo offline ativo? " + (!localData || !localData.ModoOffline ? false : true));
                resolve(false)
            }
                
            else {
                const dataControle = await this.dataControleRepositorio.buscar(EnumTipoDataControle.DataVerificacaoModoOffline)
                if (dataControle && dataControle.data && dataControle.data.toDateString() === new Date().toDateString()){
                    // console.log("Lei service - downloader não será iniciado. detalhes: Atualização já realizada hoje, dataControle: " + dataControle.data);
                    resolve(false)
                }

                resolve(true)
            }
        })
    }
}