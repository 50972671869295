import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { TimerService, Apontamento } from '../../../services/timer.service';
import { DetalheTimer } from './detalhe-timer.model';
import { EntitiesHelper } from '../../../helpers/entities.helper';
import { TimerFunctions } from '../timer.functions';
import { StringHelper } from '../../../helpers/string.helper';
import { LeiRepositorio } from '../../../repositorios/lei.repositorio';

@Injectable()
export class PainelLateralService {
    private apontamentos: Apontamento[];

    public $dataSelecionada: Observable<Date>;
    private dataSelecionada: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date());

    public $mostrarScheduler: Observable<boolean>;
    private mostrarScheduler: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public $mostrarPainel: Observable<boolean>;
    private mostrarPainel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public $tempoTotalDia: Observable<string>;
    private tempoTotalDia: BehaviorSubject<string> = new BehaviorSubject<string>('00:00:00');

    public $apontamentosDia: Observable<DetalheTimer[]>;
    private apontamentosDia: BehaviorSubject<DetalheTimer[]> = new BehaviorSubject<DetalheTimer[]>([]);
    busy: boolean;

    constructor(
        private timerService: TimerService,
        private leiRepositorio: LeiRepositorio
    ) {
        this.$dataSelecionada = this.dataSelecionada.asObservable();
        this.$mostrarScheduler = this.mostrarScheduler.asObservable();
        this.$mostrarPainel = this.mostrarPainel.asObservable();
        this.$tempoTotalDia = this.tempoTotalDia.asObservable();
        this.$apontamentosDia = this.apontamentosDia.asObservable();

        this.timerService.$apontamentos.subscribe(ap => this.apontamentos_change(ap));
        this.$mostrarPainel.subscribe(m => this.visibilidadePainel_changed(m));

        setInterval(() => this.atualizar(true), 1000);
    }

    public alterarData(data: Date): void {
        const d = new Date(data.getFullYear(), data.getMonth(), data.getDate());
        this.dataSelecionada.next(d);
        this.atualizar();
    }

    public toggleScheduler(): void {
        const visible = this.mostrarScheduler.getValue();
        this.mostrarScheduler.next(!visible);
    }

    public togglePainel(): void {
        const visible = this.mostrarPainel.getValue();
        this.mostrarPainel.next(!visible);
    }

    public apontamentos_change(ap: Apontamento[]): void {
        if (!EntitiesHelper.equals(this.apontamentos, ap)) {
            this.apontamentos = EntitiesHelper.Copy(ap);
        }
    }

    visibilidadePainel_changed(m: boolean): void {
        if (m)
            this.atualizar();
    }

    atualizar(interval = false) {
        if (!this.mostrarPainel.value && this.busy)
            return;

        this.busy = true;

        const task = this.carregarApontamentos(interval);
        if (task) {
            task.then(result => {
                this.calcularTempo(result);
                this.busy = false;
            });
        }
    }

    private carregarApontamentos(interval: boolean): Promise<DetalheTimer[]> {
        if (!this.apontamentos)
            return;

        const d = this.dataSelecionada.value;

        const dia = d.getDate();
        const mes = d.getMonth() + 1;
        const ano = d.getFullYear();

        return new Promise((onsuccess) => {
            const apDia = new Array<DetalheTimer>();
            const promises = new Array<Promise<void>>();

            if (!interval) {
                this.apontamentos.filter(ap => ap.ano === ano && ap.mes === mes && ap.dia === dia).forEach(ap => {
                    promises.push(this.leiRepositorio.carregarItemLookup(ap.idLei).then(lei => {
                        apDia.push(DetalheTimer.fromApontamento(ap, lei.titulo));
                    }));
                });

                Promise.all(promises).then(() => {
                    apDia.sort((a1, a2) => StringHelper.AlphabeticnaturalSort(a1.descricao, a2.descricao));
                    onsuccess(apDia);
                });
            } else {
                this.apontamentos.filter(ap => ap.ano === ano && ap.mes === mes && ap.dia === dia).forEach(ap => {
                    const apExistente = this.apontamentosDia.value.find(a => a.idLei === ap.idLei);
                    if (apExistente)
                        apDia.push(DetalheTimer.fromApontamento(ap, apExistente.descricao));
                });

                onsuccess(apDia);
            }

        });
    }

    private calcularTempo(apontamentos) {
        let tempoTotal = 0;

        apontamentos.forEach(ap => {
            tempoTotal += ap.segundos;
        });

        this.apontamentosDia.next(apontamentos);
        this.tempoTotalDia.next(TimerFunctions.toTimeString(tempoTotal));
    }
}
