import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { ValidatorsHelper } from '../../../../helpers/validators.helper';

@Component({
  selector: 'app-form-alterar-senha',
  templateUrl: './form-alterar-senha.component.html',
  styleUrls: ['./form-alterar-senha.component.scss']
})
export class FormAlterarSenhaComponent implements OnInit, OnDestroy {
  private headers = new HttpHeaders({
    'content-type': 'application/json'
  });

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();
  @Output() confirmarAlteracoes = new EventEmitter<boolean>(false);

  public model: AlterarSenhaModel;

  // public novaSenhaFormControl = new FormControl('', Validators.compose([Validators.minLength(6), Validators.maxLength(12)]))
  frmGroup = new FormGroup({
    senhaAtual: new FormControl('', Validators.required)
    ,senha: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)]))
    ,confirmaSenha: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(12)]))
  }, {
    validators: ValidatorsHelper.MatchPassword
  })

  constructor(
    private httpClient: HttpClient
  ) { }

  public ngOnInit(): void {
    this.carregarModel();
  }

  public carregarModel(): void {
    this.model = new AlterarSenhaModel();
  }

  public ngOnDestroy(): void {
    this.model = null;
  }

  public abrir(): void {
    this.carregarModel();
  }

  public fechar(): void {
  }

  public cancelar(): void {
    this.carregarModel();
  }

  public salvar(): void {
    this.onsaving.emit();

    this.model.senhaAntiga = this.frmGroup.controls['senhaAtual'].value;
    this.model.senhaNova = this.frmGroup.controls['senha'].value;
    this.model.confereSenha = this.frmGroup.controls['confirmaSenha'].value;

    this.httpClient.post(AppConfig.apiEndpoint + '/auth/alterar-senha', this.model, { headers: this.headers }).toPromise().then(() => {
      this.onsaved.emit('Sua senha foi alterada com sucesso');
      this.carregarModel();
    }).catch(err => {
      this.onsaved.emit(err);
      this.carregarModel();
      throw err;
    });
  }


}

export class AlterarSenhaModel {
  public senhaAntiga: string;
  public senhaNova: string;
  public confereSenha: string;

  public get valido(): boolean {
    return (
      (this.senhaAntiga && this.senhaAntiga.length > 0) &&
      (this.senhaNova && this.senhaNova.length > 0) &&
      (this.senhaNova === this.confereSenha)
    ) ? true : false;
  }
}