import locale from '@angular/common/locales/pt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LeitorComponent } from './components/leitor/leitor.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { IndicatorsComponent } from './components/indicators/indicators.component';
import { SynchronizationService } from './services/synchronization.service';
import { IndicatorsService } from './services/indicators.service';
import { LeitorToolbarComponent } from './components/leitor-toolbar/leitor-toolbar.component';
import { LeitorContentComponent } from './components/leitor-content/leitor-content.component';
import { LeitorStatusbarComponent } from './components/leitor-statusbar/leitor-statusbar.component';
import { LeitorContentTabcontentComponent } from './components/leitor-content-tabcontent/leitor-content-tabcontent.component';
import { SelectionService } from './services/selection.service';
import { PaineisService } from './components/leitor-content-tabcontent/paineis.service';
import { LeitorContentPanelBuscaComponent } from './components/leitor-content-panelbusca/leitor-content-panelbusca.component';
import { StatusService } from './services/status.service';
import { BuscaService } from './services/busca.service';
import { BarraNavegacaoComponent } from './components/leitor/barra-navegacao/barra-navegacao.component';
import { ConteudoService } from './services/conteudo.service';
import { PaginaComponent } from './components/leitor/pagina/pagina.component';
import { LinhaComponent } from './components/leitor/pagina/linha/linha.component';
import { TrechoTextoComponent } from './components/leitor/pagina/linha/trecho-texto/trecho-texto.component';
import { HoverService } from './services/hover.service';
import { PlaceholderPaineisComponent } from './components/leitor/placeholder-paineis/placeholder-paineis.component';
import { PlaceholderPaineisService } from './components/leitor/placeholder-paineis/placeholder-paineis.service';
import { PainelComentariosComponent } from './components/leitor/placeholder-paineis/painel-comentarios/painel-comentarios.component';
import { PainelMarcacoesProvaComponent } from './components/leitor/placeholder-paineis/painel-marcacoes-prova/painel-marcacoes-prova.component';
import { TagPickerComponent } from './components/leitor/placeholder-paineis/painel-marcacoes-prova/tag-picker/tag-picker.component';
import { InfoPanelComponent } from './components/leitor-statusbar/info-panel/info-panel.component';
import { IndicadorLeituraComponent } from './components/leitor-statusbar/indicador-leitura/indicador-leitura.component';
import { TimerComponent } from './components/navbar/timer/timer.component';
import { SplashComponent } from './components/splash/splash.component';
import { SplashService } from './components/splash/splash.service';
import { ResetSenhaComponent } from './components/auth/reset-senha/reset-senha.component';
import { NovidadesComponent } from './components/novidades/novidades.component';
import { NovidadesService } from './components/novidades/novidades.service';
import { TimerService } from './services/timer.service';
import { NgxSocialLoginModule } from 'ngx-social-login';
import { NgxMaskModule } from 'ngx-mask';
import { NovaGuiaComponent } from './components/leitor/nova-guia/nova-guia.component';
import { BtnToolbarComponent } from './components/leitor-toolbar/btn-toolbar/btn-toolbar.component';
import { PainelLateralComponent } from './components/timer/painel-lateral/painel-lateral.component';
import { SchedulerComponent } from './components/timer/scheduler/scheduler.component';
import { PainelLateralService } from './components/timer/painel-lateral/painel-lateral.service';
import { LeitorContentPanelBuscaAvancadaComponent } from './components/leitor-content-panelbuscaavancada/leitor-content-panelbuscaavancada.component';
import { UiService } from './services/ui.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PaginadorComponent } from './components/paginador/paginador.component';
import { UiScrollModule } from 'ngx-ui-scroll';
import { LeitorContentPanelSpinnerComponent } from './components/leitor-content-panelspinner/leitor-content-panelspinner.component';
import { ItensVerificarService } from './services/storage-services/itens.verificar.service';
import { ShortcutsService } from './services/shortcuts.service';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ItemMenuNovaGuiaComponent } from './components/leitor/nova-guia/item-menu-nova-guia/item-menu-nova-guia.component';
import { PrimeiroLoginComponent } from './components/popups/primeiro-login/primeiro-login.component';
import { PopupsComponent } from './components/popups/popups.component';
import { PopupsService } from './components/popups/popups.service';
import { PerfilComponent } from './components/popups/perfil/perfil.component';
import { FormIdentificacaoComponent } from './components/popups/perfil/form-identificacao/form-identificacao.component';
import { FormDadosPessoaisComponent } from './components/popups/perfil/form-dados-pessoais/form-dados-pessoais.component';
import { FormAlterarSenhaComponent } from './components/popups/perfil/form-alterar-senha/form-alterar-senha.component';
import { FormPagamentoComponent } from './components/popups/perfil/form-pagamento/form-pagamento.component';
import { PreferenciasComponent } from './components/popups/preferencias/preferencias.component';
import { SimNaoDialogComponent } from './components/dialogs/sim-nao-dialog/sim-nao-dialog.component';
import { ColorPickerComponent } from './components/dialogs/color-picker/color-picker.component';
import { FormCancelamentoComponent } from './components/popups/perfil/form-cancelamento/form-cancelamento.component';
import { FormIndicacoesComponent } from './components/popups/perfil/form-indicacoes/form-indicacoes.component';
import { DatePipe, CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { NovaGuiaService } from './components/leitor/nova-guia/nova-guia.service';
import { InfoLeiComponent } from './components/leitor/nova-guia/info-lei/info-lei.component';
import { LeitorContentPanelBuscaProgressoComponent } from './components/leitor-content-panelbuscaprogresso/leitor-content-panelbuscaprogresso.component';
import { UsuarioGuiasService } from './services/data-services/usuario.guias.service';
import { UsuarioPreferenciasService } from './services/data-services/usuario.preferencias.service';
import { ErroPopUpComponent } from './components/popups/mensagem/erro-popup.component';
import { UsuarioEstatisticasService } from './services/data-services/usuario.estatisticas.service';
import { UsuarioMarcacoesService } from './services/data-services/usuario.marcacoes.service';
import { UsuarioComentariosService } from './services/data-services/usuario.comentarios.service';
import { UsuarioGrifosService } from './services/data-services/usuario.grifos.service';
import { UsuarioAnotacoesService } from './services/data-services/usuario.anotacoes.service';
import { UsuarioApontamentosService } from './services/data-services/usuario.apontamentos.service';
import { UsuarioProvasService } from './services/data-services/usuario.provas.service';
import { DialogoComponent } from './components/popups/dialogo/dialogo.component';
import { DialogoService } from './components/popups/dialogo/dialogo.service';
import { SignalrService } from './services/signalr.service';
import { DadosService } from './services/dados.service';
import { ViewLabComponent } from './components/view-lab/view-lab.component';
import { MaterialModule } from './helpers/material.module';
import { routing } from './helpers/app.routing';
import { PopupCarregamentoComponent } from './components/popups/popup-carregamento/popup-carregamento.component';
import { MdePopoverModule } from '@material-extended/mde';
import { MarcacaoSuperiorComponent, ResizableDivDirective, AutoGrowDivDirective as AutoGrowDivDirective, AutoWidhtDirective } from './components/leitor/pagina/marcacao-superior/marcacao-superior.component';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { LoggingService } from './services/logging.service';
import { AbaLeitorComponent } from './components/leitor/barra-navegacao/aba-leitor/aba-leitor.component';
import { BackgroundContrastDirective } from './directives/background-contrast.directive';
import { ChipProvaComponent } from './components/leitor/pagina/marcacao-superior/chip-prova/chip-prova.component';
import { FeedbackComponent } from './components/popups/feedback/feedback.component';
import { MaskDateDirective } from './directives/mask-date.directive';
import { TextFormatingService } from './services/ui/textFormating.service';
import { ControleFonteComponent } from './controls/leitor/controle-fonte/controle-fonte.component';
import { LocalDataService } from './services/data-services/local.data.service';
import { DownloaderComponent } from './pages/downloader/downloader.component';
import { DownloaderService } from './pages/downloader/downloader.service';
import { IncompatibleBrowserComponent } from './pages/incompatible-browser/incompatible-browser.component';
import { CheckForUpdateService } from './services/checkForUpdateService';
import { LOCALE_ID, NgModule, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { dbConfig } from './idb.config';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeiRepositorio } from './repositorios/lei.repositorio';
import { EstatisticasRepositorio } from './repositorios/estatisticas.repositorio';
import { DataControleRepositorio } from './repositorios/dataControle.repositorio';
import { LeiService } from './services/lei.service';
import { LogoffComponent } from './pages/logoff/logoff.component';
import { FormBuscaArtigoDocumentoAtualComponent } from './controls/busca/form-busca-artigo-documento-atual/form-busca-artigo-documento-atual.component';
import { SortByPipe } from './pipes/sort.by.type.pipe';
import { EditorComentarioComponent } from './components/leitor/editors/editor-comentario/editor-comentario.component';
import { CabecalhoComponent } from './components/leitor/pagina/cabecalho/cabecalho.component';
import { IndiceSistematicoComponent } from './components/popups/indice-sistematico/indice-sistematico.component';
import { ErrorHandlerService } from './services/errorHandler.service';
import { ErrorLogRepositorio } from './repositorios/errorLog.repositorio';
import { LawArticleComponent } from './components/leitor/law-article/law-article.component';
import { StudyPlanComponent } from './components/leitor/study-plan/study-plan.component';
import { ListArticlesComponent } from './components/popups/list-articles/list-articles.component';
import { ReadingSpeedComponent } from './components/popups/reading-speed/reading-speed.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { SuccessDialogComponent } from './components/dialogs/success-dialog/success-dialog.component';
import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(locale);

@NgModule({
  declarations: [
    AppComponent,    
    LeitorComponent,
    NavbarComponent,
    IndicatorsComponent,
    LeitorToolbarComponent,
    LeitorContentComponent,
    LeitorStatusbarComponent,
    LeitorContentTabcontentComponent,
    BarraNavegacaoComponent,
    LeitorContentPanelBuscaComponent,
    PaginaComponent,
    LinhaComponent,
    TrechoTextoComponent,
    PlaceholderPaineisComponent,
    PainelComentariosComponent,
    PainelMarcacoesProvaComponent,
    TagPickerComponent,
    InfoPanelComponent,
    PaginadorComponent,
    IndicadorLeituraComponent,
    TimerComponent,
    SplashComponent,
    ResetSenhaComponent,
    NovidadesComponent,
    NovaGuiaComponent,
    BtnToolbarComponent,
    PainelLateralComponent,
    SchedulerComponent,
    LeitorContentPanelBuscaAvancadaComponent,
    LeitorContentPanelSpinnerComponent,
    ItemMenuNovaGuiaComponent,
    PopupsComponent,
    PrimeiroLoginComponent,
    PerfilComponent,
    FormIdentificacaoComponent,
    FormDadosPessoaisComponent,
    FormAlterarSenhaComponent,
    FormPagamentoComponent,
    FormCancelamentoComponent,
    FormIndicacoesComponent,
    PreferenciasComponent,
    SimNaoDialogComponent,
    ColorPickerComponent,
    InfoLeiComponent,
    LeitorContentPanelBuscaProgressoComponent,
    ErroPopUpComponent,
    DialogoComponent,
    ViewLabComponent,
    PopupCarregamentoComponent,
    MarcacaoSuperiorComponent,
    FeedbackComponent,
    ResizableDivDirective,
    AutoGrowDivDirective,
    AutoWidhtDirective,
    AbaLeitorComponent,
    BackgroundContrastDirective,
    ChipProvaComponent,
    MaskDateDirective,
    ControleFonteComponent,
    DownloaderComponent,
    IncompatibleBrowserComponent,
    LogoffComponent,
    FormBuscaArtigoDocumentoAtualComponent,
    SortByPipe,
    EditorComentarioComponent,
    CabecalhoComponent,
    IndiceSistematicoComponent,
    SortByPipe,
    LawArticleComponent,
    StudyPlanComponent,
    ListArticlesComponent,
    ReadingSpeedComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SuccessDialogComponent,
   
  ],
  entryComponents: [
    SimNaoDialogComponent,
    ColorPickerComponent,
    InfoLeiComponent,
    LeitorContentPanelBuscaProgressoComponent,
    ErroPopUpComponent,
    DialogoComponent,
    PopupCarregamentoComponent,
    ListArticlesComponent,
    ReadingSpeedComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SuccessDialogComponent,
  ],
  imports: [
    SharedModule,
    CoreModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatFormFieldModule,
    MatSelectModule,   
    AngularResizedEventModule,
    MatDialogModule,
    HttpClientModule,
    MaterialModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    routing,
    UiScrollModule,
    VirtualScrollerModule,
    MdePopoverModule,
    NgxSocialLoginModule.init({
      google: {
        client_id: '700120466349-eftprtv7cthgi8mimc409sb25c6t0035.apps.googleusercontent.com'
      },
      facebook: {
        initOptions: {
          appId: '298495030720106'
        },
        loginOptions: {
          scope: 'email'
        }
      }
    }),
    NgxMaskModule.forRoot({}),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    NgxIndexedDBModule.forRoot(dbConfig)
  ],
  providers: [
    // Repositórios
    LeiRepositorio,
    EstatisticasRepositorio,
    DataControleRepositorio,
    ErrorLogRepositorio,
    // Serviços
    LeiService,
    BuscaService,
    ConteudoService,
    DadosService,
    DatePipe,
    DialogoService,
    DownloaderService,
    HoverService,
    IndicatorsService,
    ItensVerificarService,
    LeiService,
    LocalDataService,
    LoggingService,
    NovaGuiaService,
    NovaGuiaService,
    NovidadesService,
    PaineisService,
    PainelLateralService,
    PlaceholderPaineisService,
    PopupsService,
    SelectionService,
    ShortcutsService,
    SignalrService,
    SplashService,
    StatusService,
    SynchronizationService,
    TextFormatingService,
    TimerService,
    UiService,
    UsuarioGuiasService,
    UsuarioPreferenciasService,
    UsuarioEstatisticasService,
    UsuarioMarcacoesService,
    UsuarioComentariosService,
    UsuarioGrifosService,
    UsuarioAnotacoesService,
    UsuarioApontamentosService,
    UsuarioProvasService,
    ErrorHandlerService,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    // { provide: ErrorHandler, useClass: ErrorHandlerService},
    CheckForUpdateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
