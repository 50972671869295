import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MaskHelper } from '../../../../helpers/mask.helper';
import { WebserviceHelper } from '../../../../helpers/webservice.helper';
import { StatusService } from '../../../../services/status.service';
import { CadastroUsuarioPlanoRequest } from '../../../auth/models/CadastroUsuarioPlanoRequest.model';
import { ItemLookupPlano } from '../../../auth/models/item-lookup-plano.model';
import { DetalhesAssinaturaResponse } from '../../arguments/assinatura/DetalhesAssinaturaResponse';
import { AssinaturaService } from '../../services/assinatura.service';
import { FormControlValidators } from '../../validators/FormControlValidators';

@Component({
  selector: '[indicador-trial]',
  templateUrl: './indicador-trial.component.html',
  styleUrls: ['./indicador-trial.component.scss']
})
export class IndicadorTrialComponent implements OnInit {
  masks = MaskHelper

  showModal = false
  plano: DetalhesAssinaturaResponse
  diasRestantesAvaliacao: number
  percentualIndicador: number
  strDiasRestantes: string
  formStep: number = 1

  applySubscriptionForm: FormGroup

  planos: ItemLookupPlano[]
  ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO']
  bandeirasCartao = [
    { id: 1, descricao: 'VISA' },
    { id: 2, descricao: 'AMEX' },
    { id: 3, descricao: 'MASTERCARD' },
    { id: 4, descricao: 'ELO' },
    { id: 5, descricao: 'HIPERCARD' },
    { id: 6, descricao: 'DINERS' }
  ]

  timeout: any;

  constructor(
    private assinaturaService: AssinaturaService,
    private statusService: StatusService,
    private matSnackBar: MatSnackBar,
    formBuilder: FormBuilder
  ) {
    this.applySubscriptionForm = formBuilder.group({
      dadosPessoais: formBuilder.group({
        telefone: ['', [Validators.required]],
        cep: ['', [FormControlValidators.cep]],
        logradouro: ['', [Validators.required]],
        numeroLogradouro: ['', [Validators.required]],
        complemento: [''],
        bairro: ['', [Validators.required]],
        cidade: ['', [Validators.required]],
        uf: ['', [Validators.required]],
        siglaPais: ['BR', [Validators.required]]
      }),
      pagamento: formBuilder.group({
        cpf: ['', [FormControlValidators.cpf]],
        dataNascimento: ['', [FormControlValidators.diaMesAno]],
        idPlano: ['', [Validators.required]],
        numeroCartao: ['', [Validators.required]],
        meioPagamento: ['', [Validators.required]],
        nomeTitularCartao: ['', [Validators.required]],
        vencimentoCartao: ['', [Validators.required]],
        codigoSegurancaCartao: ['', [Validators.required]],
        deAcordoTermoCompra: [false, [Validators.requiredTrue]]
      })
    })
  }

  async ngOnInit() {
    this.carregarAssinatura()
    this.planos = await this.assinaturaService.listarPlanos(false)
  }

  async carregarAssinatura() {
    clearTimeout(this.timeout)
    this.plano = await this.assinaturaService.carregarPlanoAtual()

    this.diasRestantesAvaliacao = Math.floor(this.plano.diasRestantesAvaliacao)
    this.strDiasRestantes = (this.diasRestantesAvaliacao == 1) ? 'dia' : 'dias'
    this.percentualIndicador = 100 - ((this.plano.diasTesteUtilizados / this.plano.tempoAvaliacaoGratuita) * 100)

    if (this.diasRestantesAvaliacao < 0)
      this.diasRestantesAvaliacao = 0

    if (this.plano.trialGratuito && this.diasRestantesAvaliacao <= 0)
      this.showModal = true

    this.timeout = setTimeout(() => {
      this.carregarAssinatura()
    }, 30 * 60 * 1000);
  }

  indicador_clicked() {
    this.showModal = true
  }

  modal_clicked(event: MouseEvent) {
    this.fecharPopup()
  }

  dialog_clicked(event: MouseEvent) {
    event.stopImmediatePropagation()
  }

  async form_submit(event: Event) {
    event.preventDefault();

    if (!this.applySubscriptionForm.valid)
      return

    const taskname = 'login-succeded';
    try {
      this.statusService.mostrarMensagemProgresso('Realizando seu cadastro...', taskname);

      let payload = <CadastroUsuarioPlanoRequest>Object.assign(new CadastroUsuarioPlanoRequest(), this.applySubscriptionForm.controls['dadosPessoais'].value)
      payload = <CadastroUsuarioPlanoRequest>Object.assign(payload, this.applySubscriptionForm.controls['pagamento'].value)

      await this.assinaturaService.assinarPlano(payload)
      await this.carregarAssinatura();

      this.formNext()
    } catch (ex) {
      this.matSnackBar.open(ex.error, null, { duration: 5000 })
    } finally {
      this.statusService.ocultarMensagemProgresso(taskname);
    }
  }

  async buscarCep() {
    const group = <FormGroup>this.applySubscriptionForm.controls['dadosPessoais']
    let cep = <string>group.controls['cep'].value
    cep = cep.replace('.', '').replace('-', '')

    const endereco = await WebserviceHelper.consultaCep(cep)
    if (endereco) {
      group.patchValue({
        logradouro: endereco.logradouro,
        bairro: endereco.bairro,
        cidade: endereco.cidade,
        uf: endereco.estado
      })
    }
  }

  formNext() {
    this.formStep += 1
  }

  formPrevious() {
    this.formStep -= 1
  }

  fecharPopup() {
    if (!this.plano.trialGratuito || this.diasRestantesAvaliacao > 0)
      this.showModal = false
  }
}
