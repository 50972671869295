import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export class Icon {
    description: string;
    path: string;

    static Register(id: string, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        const icon = Icons.find(i => i.description === id);
        iconRegistry.addSvgIcon(icon.description, sanitizer.bypassSecurityTrustResourceUrl(icon.path));
    }
}

export const Icons: Icon[] = [
    { description: 'close', path: 'assets/ui/icons/close_filled.svg' },
    { description: 'increase-font', path: 'assets/ui/icons/increase-font.svg' },
    { description: 'decrease-font', path: 'assets/ui/icons/decrease-font.svg' },
    { description: 'underline', path: 'assets/ui/icons/underline.svg' },
    { description: 'smile', path: 'assets/ui/icons/ic_smile.svg' },
    { description: 'sad', path: 'assets/ui/icons/ic_smile_sad.svg' },
    { description: 'ok', path: 'assets/ui/icons/ic_smile_ok.svg' },
    { description: 'happy', path: 'assets/ui/icons/ic_smile_happy.svg' },
    { description: 'pendente', path: 'assets/ui/icons/cloud_waiting.svg' },
    { description: 'offline', path: 'assets/ui/icons/cloud_cross.svg' },
    { description: 'download', path: 'assets/ui/icons/download.svg' },
    { description: 'update-lei', path: 'assets/ui/icons/update-lei.svg' },
    { description: 'lei-ok', path: 'assets/ui/icons/lei-ok.svg' },
    { description: 'timer-parado', path: 'assets/ui/icons/timer-parado.svg' },
    { description: 'timer-ativo', path: 'assets/ui/icons/timer-ativo.svg' },
    { description: 'facebook-f', path: 'assets/icons/facebook/icons8-f-do-facebook-32.svg' },
    { description: 'instagram', path: 'assets/icons/instagram/icons8-instagram-32.svg' },
]