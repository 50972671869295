import { Component, OnInit } from '@angular/core';
import { StorageHelper } from '../../helpers/storage.helper';
import { StatusService } from '../../services/status.service';
import { LocalDataService } from '../../services/data-services/local.data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logoff',
  templateUrl: './logoff.component.html',
  styleUrls: ['./logoff.component.scss']
})
export class LogoffComponent implements OnInit {

  constructor(
    private statusService: StatusService,
    private localdataService: LocalDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  async ngOnInit() {
    localStorage.clear()
    await StorageHelper.deletarDBs()
    this.router.navigate(['/'])

    // Guilherme - Ajustei os métodos de limpeza internamente no storage helper para que ele delete as bases com dados do usuário e então recrie as tabelas necessárias
    // console.log("logoff");
    // const limparDados = this.activatedRoute.snapshot.queryParams["clear"];
    // if (limparDados) {
    //   this.logoffCompleto();
    // } else {
    //   this.logoffSimples();
    // }

  }

  private logoffCompleto() {

    setTimeout(() => {
      try {
        window.stop();
        this.statusService.mostrarMensagemProgresso('Fazendo logoff', 'logoff');

        //Limpar caches
        caches.keys().then(function (names) {
          for (let name of names)
            caches.delete(name);
        });

        //Limpar local storage
        this.localdataService.limparDadosNaoPermanentes();

        //Deletar bancos
        StorageHelper.deletarDBs().then(() => {

          // DB SERVICE DELETE DATABASE NÃO FUNCIONA CASO SCHEMA DO DATABASE ESTEJA DIFERENTE, NÃO UTILIZAR
          //await this.dbService.deleteDatabase();

          //Deletar banco SVM
          var req = indexedDB.deleteDatabase('svdm');
          req.onsuccess = function (event) {
            window.location.href = 'https://www.supervademecum.com.br';
          }
          req.onerror = function (event) {
            window.location.reload(true);
          }
          req.onblocked = function (event) {
            console.warn("banco svdm bloqueado... aguardando finalização de requisições anteriores");
          }
        });

      } catch (error) {
        console.error("Erro ao deletar dados do navegador, entre em contato com o suporte em www.supervademecum.com.br")
      }
    });

  }

  private logoffSimples() {

    setTimeout(async () => {
      try {

        window.stop();
        this.statusService.mostrarMensagemProgresso('Fazendo logoff', 'logoff');

        //Limpar caches
        localStorage.removeItem('usertoken');
        caches.keys().then(function (names) {
          for (let name of names)
            caches.delete(name);
        });

        //Direcionar para pag supervademecum
        setTimeout(() => {
          window.location.href = 'https://www.supervademecum.com.br';
        }, 2);
      } catch {
        window.location.href = 'https://www.supervademecum.com.br';
      }
    });

  }

}
