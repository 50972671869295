export class StringHelper {

    // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463
    // https://stackoverflow.com/questions/4374822/remove-all-special-characters-with-regexp
    public static RemoverAcentosCaracteresEspeciais(texto: string): string {

        // remover acentos
        texto = this.RemoverAcentos(texto);

        // remover carateres especiais
        texto = texto.replace(/[^\w\s]/gi, '');

        return texto;
    }

    public static RemoverAcentos(texto: string): string {

        // remover acentos
        texto = texto.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');

        return texto;
    }

    public static AlphabeticnaturalSort(a1: string, a2: string): number {

        a1 = !a1 ? '' : a1;
        a2 = !a2 ? '' : a2;

        // 'Natural sort' para organizar numeros corretamente
        let a: any, b: any, aX: number, bX: number, i = 0, n: number, L;
        const rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
        if (a1 === a2) { return 0; }
        a = a1.toLowerCase().match(rx);
        b = a2.toLowerCase().match(rx);
        L = a.length;
        while (i < L) {
            if (!b[i]) { return 1; }
            aX = a[i],
                bX = b[i++];
            if (aX !== bX) {
                n = aX - bX;
                if (!isNaN(n)) { return n; }
                return aX > bX ? 1 : -1;
            }
        }
        return b[i] ? -1 : 0;

    }
}
