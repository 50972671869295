import { Component, OnInit, OnDestroy } from '@angular/core';
import { SplashService } from './splash.service';
import { SynchronizationService, StatusSincronizacao } from '../../services/synchronization.service';
import { trigger, transition, style, animate, keyframes, state } from '@angular/animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.css'],
  animations: [
    trigger('msgCarregamento', [
      transition("* <=> *", [
        style({ opacity: 0 }),
        animate(1000)
      ])
    ])
  ]
})
export class SplashComponent implements OnInit, OnDestroy {
  private interval: any;

  private subscriptions: Subscription[] = [];

  public visible = false;
  public message: string = null;
  public progress: number = null;
  public origem: string;

  public mensagemAtual: string;

  public mensagensCarregamento: string[];

  undetermined: boolean;

  constructor(
    private service: SplashService
  ) { }

  ngOnInit() {

    this.subscriptions.push(this.service.message.subscribe(v => this.message = v));
    this.subscriptions.push(this.service.progress.subscribe(v => this.progress = v));

    this.subscriptions.push(this.service.params.subscribe(p => {
      this.visible = p !== null;

      if (p) {
        this.mensagensCarregamento = p.mensagens;
        this.origem = p.origemSplash;

        this.interval = setInterval(() => {
          const iAtual = this.mensagensCarregamento.indexOf(this.mensagemAtual);
          let iProximo = (iAtual === -1) ? 0 : iAtual + 1;
          iProximo = ((iProximo + 1) > this.mensagensCarregamento.length) ? 0 : iProximo;

          this.mensagemAtual = this.mensagensCarregamento[iProximo];
        }, 5000);

        this.mensagemAtual = this.mensagensCarregamento[0];
      } else {
        clearInterval(this.interval);
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions)
      this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  public processarStatusSincronizacao(status: StatusSincronizacao): void {
    if (!this.visible || !status) { return; }

    this.message = status.mensagem;
    this.progress = status.progresso;
    this.undetermined = status.progressoIndeterminado;
  }

  cancelar() {
    this.service.cancelarOperacaoEmProcesso();
  }
}
