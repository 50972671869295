import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable()
export class LocalDataService {
    private readonly KEY = 'LocalData'

    LocalSettings: Observable<LocalSettings>
    private localSettings = new BehaviorSubject<LocalSettings>(this.carregarConfiguracoes())

    constructor() {
        this.LocalSettings = this.localSettings.asObservable()
    }

    carregarConfiguracoes(): LocalSettings {
        const data = localStorage.getItem(this.KEY)
        if (!data)
            return new LocalSettings()

        const json = atob(data)
        return <LocalSettings>JSON.parse(json)
    }

    salvarConfiguracoes(config: LocalSettings) {
        const json = JSON.stringify(config)
        const data = btoa(json)

        localStorage.setItem(this.KEY, data)
    }

    get modoOffline(): boolean {
        const data = this.carregarConfiguracoes()
        if (!data || !data.ModoOffline)
            return false
            
        return data.ModoOffline
    }

    limparDadosNaoPermanentes(){
        const modoOfflineConfig = this.carregarConfiguracoes();
        localStorage.clear();
        this.salvarConfiguracoes(modoOfflineConfig);
    }
}

export class LocalSettings {
    ModoOffline: boolean
}