import { Component, OnInit,  OnDestroy } from '@angular/core';
import { PlaceholderPaineisService, PainelComentariosParameters } from '../placeholder-paineis.service';
import { ParametrosCaneta } from '../../../../models/UserData';
import { Comentario } from '../../../../models/Comentario';
import { TagConteudo } from '../../../../interfaces/TagConteudo';
import { HoverService } from '../../../../services/hover.service';
import { Subscription } from 'rxjs';
import { UsuarioPreferenciasService } from '../../../../services/data-services/usuario.preferencias.service';
import { UsuarioComentariosService } from '../../../../services/data-services/usuario.comentarios.service';

@Component({
  selector: 'app-painel-comentarios',
  templateUrl: './painel-comentarios.component.html',
  styleUrls: [
    './painel-comentarios.component.css',
    '../placeholder-paineis.style.css'
  ]
})
export class PainelComentariosComponent implements OnInit, OnDestroy {
  private s_user: Subscription;
  private s_painelComentariosParametersObservable: Subscription;

  public parameters: PainelComentariosParameters;
  public paleta: ParametrosCaneta;

  constructor(
    private placeholderPaineisService: PlaceholderPaineisService,
    private hoverService: HoverService,
    private usuarioPreferenciasService: UsuarioPreferenciasService,
    private usuarioComentariosService: UsuarioComentariosService
  ) { }

  ngOnInit() {
    this.s_user = this.usuarioPreferenciasService.$Configuracoes.subscribe(c => {
      if (c && this.paleta !== c.parametrosCaneta) {
        this.paleta = c.parametrosCaneta;
      }
    });

    this.s_painelComentariosParametersObservable = this.placeholderPaineisService.painelComentariosParametersObservable.subscribe(p => {
      this.parameters = p;
      this.destacar();
    });

  }

  ngOnDestroy(): void {
    this.s_painelComentariosParametersObservable.unsubscribe();
    this.s_user.unsubscribe();
  }

  private destacar(): void {
    if (this.parameters) {
      const tags = new Array<TagConteudo>();
      if (this.parameters.comentarioAtivo) {
        tags.push(new TagConteudo(null, this.parameters.comentarioAtivo));
      } else {
        this.parameters.listaComentarios.forEach(m => {
          tags.push(new TagConteudo(null, m));
        });
      }

      if (tags.length > 0) {
        this.hoverService.destacar(tags);
      }
    }
  }

  public fecharPainel(): void {
    this.placeholderPaineisService.fecharPainelComentarios();
  }

  public voltar(): void {
    this.salvar();
    this.parameters.comentarioAtivo = null;
  }

  public editar(comentario: Comentario): void {
    this.parameters.comentarioAtivo = comentario;
  }

  public mouseEnter(comentario): void {
    const tags = new Array<TagConteudo>();
    tags.push(new TagConteudo(null, comentario));

    if (tags.length > 0) {
      this.hoverService.destacar(tags);
    }
  }

  public mouseLeave(): void {
    this.destacar();
  }

  public remover(e: Event, comentario: Comentario): void {
    e.preventDefault();
    e.stopPropagation();

    const index = this.parameters.listaComentarios.findIndex(c => c.id === comentario.id);
    this.parameters.listaComentarios.splice(index, 1);

    this.usuarioComentariosService.remover(comentario).then(() => {
      if (this.parameters.listaComentarios.length === 0) {
        this.placeholderPaineisService.fecharPainelComentarios();
      }
    });
  }

  public toggleMnemonico(): void {
    this.parameters.comentarioAtivo.mnemonico = !this.parameters.comentarioAtivo.mnemonico;
    this.salvar();
  }

  public salvar() {
    if (this.parameters.comentarioAtivo.texto === '') {
      return;
    }

    if (this.parameters.listaComentarios.findIndex(c => c.id === this.parameters.comentarioAtivo.id) === -1)
      this.parameters.listaComentarios.push(this.parameters.comentarioAtivo);

    this.usuarioComentariosService.atualizar(this.parameters.comentarioAtivo);
  }

  get titlebarColor(): string {
    return this.paleta.corTagComentario;
  }

  get titlebarText(): string {
    return 'Comentários';
  }

  get btnMnemonicoText(): string {
    return this.parameters.comentarioAtivo.mnemonico ? 'Mnemônico' : 'Comentário';
  }

  get btnMnemonicoClass(): string {
    return this.parameters.comentarioAtivo.mnemonico ? 'btn-danger' : 'btn-primary';
  }
}
