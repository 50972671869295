export class BuscaRequest {
    texto: string;
    pagina: number;

    buscarMarcacoes: boolean;
    idInstituicaoMarcacao: string;
    idBancaMarcacao: string;
    idAnoMarcacao: string;
    idTipoMarcacao: string;
    idCargoMarcacao: string;

    buscarComentarios: boolean;
    textoComentario: string;

    buscarMnemonicos: boolean;
    textoMnemonico: string;

    buscarCaneta1: boolean;
    buscarCaneta2: boolean;
    buscarCaneta3: boolean;
    buscarCaneta4: boolean;
    buscarCaneta5: boolean;
}