export class TimerFunctions {
    public static toTimeString(tempoSegundos: number): string {
        let strTempo = '00:00:00'
        
        if (tempoSegundos) {
            const s = tempoSegundos;

            let horas = `${Math.floor(s / 60 / 60)}`;
            let minutos = `${Math.floor((s / 60) % 60)}`;
            let segundos = `${Math.floor(s % 60)}`;

            horas = (horas.length === 1) ? `0${horas}` : horas;
            minutos = (minutos.length === 1) ? `0${minutos}` : minutos;
            segundos = (segundos.length === 1) ? `0${segundos}` : segundos;

            strTempo = `${horas}:${minutos}:${segundos}`;
        }

        return strTempo;
    }
}