import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MatStepper } from '@angular/material/stepper';
import { AppConfig } from '../../../app.config';
import { StatusService } from '../../../services/status.service';
@Component({
  selector: 'app-reset-senha',
  templateUrl: './reset-senha.component.html',
  styleUrls: ['./reset-senha.component.css']
})
export class ResetSenhaComponent implements OnInit {
  public form: ResetSenhaForm = new ResetSenhaForm();
  public params: ResetSenhaParameters = new ResetSenhaParameters();

  public formEmail: FormGroup;
  public formToken: FormGroup;
  public formSenha: FormGroup;
  selectedTabIndex = 0

  @Output()
  voltar: EventEmitter<any> = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private statusService: StatusService
  ) { }

  ngOnInit() {
    this.formEmail = this.formBuilder.group({
      email: ['', Validators.required],
      emailOk: [false, Validators.requiredTrue],
      progress: [false],
      erro: [null]
    });

    this.formToken = this.formBuilder.group({
      token: ['', Validators.required],
      tokenOk: [false, Validators.requiredTrue],
      progress: [false],
      erro: [null]
    });

    this.formSenha = this.formBuilder.group({
      senha: ['', Validators.required],
      confirmarSenha: ['', Validators.required],
      progress: [false],
      erro: [null]
    });
  }

  public solicitarTokenEmail(stepper: MatStepper): void {
    this.formEmail.controls['erro'].setValue(null);
    this.formEmail.controls['progress'].setValue(true);

    const headers = new HttpHeaders({
      'content-type': 'application/json'
    });

    const body = JSON.stringify(this.formEmail.controls['email'].value);
    this.httpClient.post(`${AppConfig.apiEndpoint}/auth/verify-email`, body, { headers: headers }).toPromise().then(result => {
      this.formEmail.controls['progress'].setValue(false);
      this.formEmail.controls['emailOk'].setValue(true);
      this.selectedTabIndex++
    }).catch(err => {
      const msgErro = (err) ? err.error : 'Usuário não encontrado';
      this.formEmail.controls['erro'].setValue(msgErro);
      this.formEmail.controls['progress'].setValue(false);
    });
  }

  public validarTokenEmail(stepper: MatStepper): void {
    this.formToken.controls['erro'].setValue(null);
    this.formToken.controls['progress'].setValue(true);

    const headers = new HttpHeaders({
      'content-type': 'application/json'
    });

    this.formToken.controls['token'].setValue(this.formToken.controls['token'].value.trim()); 
    const body = {
      email: this.formEmail.controls['email'].value,
      codigo: this.formToken.controls['token'].value
    };

    this.httpClient.post(AppConfig.apiEndpoint + '/auth/verify-code', JSON.stringify(body), { headers: headers }).toPromise().then(result => {
      this.formToken.controls['progress'].setValue(false);
      this.formToken.controls['tokenOk'].setValue(true);
      this.selectedTabIndex++
    }).catch(err => {
      const msgErro = (err) ? err.error : 'Usuário não encontrado';
      this.formToken.controls['erro'].setValue(msgErro);
      this.formToken.controls['progress'].setValue(false);
    });
  }

  public alterarSenha(stepper): void {
    this.formSenha.controls['erro'].setValue(null);
    const email = this.formEmail.controls['email'].value;
    const token = this.formToken.controls['token'].value;
    const senha = this.formSenha.controls['senha'].value;
    const confirmarSenha = this.formSenha.controls['confirmarSenha'].value;

    if (senha !== confirmarSenha) {
      this.formSenha.controls['erro'].setValue('As senhas informadas devem ser iguais');
      return;
    }

    const headers = new HttpHeaders({
      'content-type': 'application/json'
    });

    const body = {
      email: this.formEmail.controls['email'].value,
      codigo: this.formToken.controls['token'].value,
      senha: this.formSenha.controls['senha'].value,
      confirmaSenha: this.formSenha.controls['senha'].value
    };

    this.httpClient.post(AppConfig.apiEndpoint + '/auth/redefine-password', JSON.stringify(body), { headers: headers }).toPromise().then(result => {
      this.formSenha.controls['progress'].setValue(false);
      this.statusService.setMessage('Sua senha foi alterada com sucesso!');
      this.btnVoltar_click();
    }).catch(err => {
      const msgErro = (err) ? err.error : 'Usuário não encontrado';
      this.formSenha.controls['erro'].setValue(msgErro);
      this.formSenha.controls['progress'].setValue(false);
    });
  }

  public btnVoltar_click() {
    this.form = new ResetSenhaForm();
    this.params = new ResetSenhaParameters();
    this.voltar.emit();
  }
}

export class ResetSenhaForm {
  public email: string;
  public token: string;
  public password: string;
  public confirm: string;
}

export class ResetSenhaParameters {
  public erro: string;
  public mensagem: string;
}