import { Injectable } from '@angular/core';
import { Marcacao } from '../../../models/Marcacao';
import { Comentario } from '../../../models/Comentario';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { HoverService } from '../../../services/hover.service';
import { TipoSelecao } from '../../../services/selection.service';
import { TextoPagina } from '../../../models/pagina/TextoPagina';

@Injectable()
export class PlaceholderPaineisService {
    public painelMarcacoesProvaParametersObservable: Observable<PainelMarcacoesProvaParameters>;
    private painelMarcacoesProvaParametersSubject = new BehaviorSubject<PainelMarcacoesProvaParameters>(null);

    public painelComentariosParametersObservable: Observable<PainelComentariosParameters>;
    private painelComentariosParametersSubject = new BehaviorSubject<PainelComentariosParameters>(null);
    public constructor(
        private hoverService: HoverService
    ) {
        this.painelMarcacoesProvaParametersObservable = this.painelMarcacoesProvaParametersSubject.asObservable();
        this.painelComentariosParametersObservable = this.painelComentariosParametersSubject.asObservable();
    }

    public get visible(): boolean {
        return this.painelMarcacoesProvaParametersSubject.getValue() !== null || this.painelComentariosParametersSubject.getValue() !== null;
    }

    // public abrirPainelMarcacoesProva(tipoMarcacao: TipoSelecao, item: TextoPagina, listaMarcacoes: Marcacao[], marcacaoAtiva: Marcacao): void {
    //     this.painelMarcacoesProvaParametersSubject.next(new PainelMarcacoesProvaParameters(tipoMarcacao, item, listaMarcacoes, marcacaoAtiva));
    // }

    public abrirDetalhePainelMarcacoesProva(marcacao: Marcacao): void {
        const current = this.painelMarcacoesProvaParametersSubject.getValue();
        current.marcacaoAtiva = marcacao;

        this.painelMarcacoesProvaParametersSubject.next(current);
    }

    public fecharPainelMarcacoesProva(): void {
        this.painelMarcacoesProvaParametersSubject.next(null);
        this.hoverService.destacar(null);
    }

    public fecharDetalhePainelMarcacoesProva(): void {
        const current = this.painelMarcacoesProvaParametersSubject.getValue();
        current.marcacaoAtiva = null;

        this.painelMarcacoesProvaParametersSubject.next(current);
    }

    // public abrirPainelComentarios(item: TextoPagina, listaComentarios: Comentario[], comentarioAtivo: Comentario): void {
    //     this.painelComentariosParametersSubject.next(new PainelComentariosParameters(item, listaComentarios, comentarioAtivo));
    // }

    public fecharPainelComentarios(): void {
        this.painelComentariosParametersSubject.next(null);
        this.hoverService.destacar(null);
    }
}

export class PainelBaseParameters {
    public constructor() { }
}

export class PainelMarcacoesProvaParameters extends PainelBaseParameters {
    public item: TextoPagina;
    public listaMarcacoes: Marcacao[];
    public marcacaoAtiva: Marcacao;
    public tipoMarcacao: TipoSelecao;

    public constructor(tipoMarcacao: TipoSelecao, item: TextoPagina, listaMarcacoes: Marcacao[], marcacaoAtiva: Marcacao) {
        super();

        this.tipoMarcacao = tipoMarcacao;
        this.item = item;
        this.listaMarcacoes = listaMarcacoes;
        this.marcacaoAtiva = marcacaoAtiva;
    }
}

export class PainelComentariosParameters extends PainelBaseParameters {
    public item: TextoPagina;
    public listaComentarios: Comentario[];
    public comentarioAtivo: Comentario;

    public constructor(item: TextoPagina, listaComentarios: Comentario[], comentarioAtivo: Comentario) {
        super();

        this.item = item;
        this.listaComentarios = listaComentarios;
        this.comentarioAtivo = comentarioAtivo;
    }
}
