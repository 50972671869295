import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { timeout } from "rxjs/operators";
import { AppConfig } from "../app.config";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ConnectivityService {

    ConnectivityStatus: Observable<ConnectivityStatus>
    private connectivityStatus = new BehaviorSubject<ConnectivityStatus>(null)

    constructor(private httpClient: HttpClient) {
        this.ConnectivityStatus = this.connectivityStatus.asObservable()
        this.init()
    }

    private init() {
        setTimeout(() => this.testConnection());
        setInterval(() => this.testConnection(), 30000)
    }

    public async testConnection(): Promise<void> {
        const status: ConnectivityStatus = { online: false }

        try {
            await this.ping(1000)
            status.online = true
        } catch (error) {
            try {
                await this.ping(2000)
                status.online = true
            } catch (error) {
                try {
                    await this.ping(3000)
                    status.online = true
                } catch (error) {
                    status.online = false
                }
            }
        }

        this.connectivityStatus.next(status)
    }

    private ping(requestTimeout: number): Promise<number> {
        return new Promise((resolve, reject) => {
            const t1 = new Date().getTime()
            this.httpClient.get(`${AppConfig.apiEndpoint}/tests/ping`)
                .pipe(timeout(requestTimeout))
                .toPromise()
                .then(() => {
                    const t2 = new Date().getTime()
                    resolve(t2 - t1)
                })
                .catch(err => reject(err))
        })
    }
}

export class ConnectivityStatus {
    online: boolean
}