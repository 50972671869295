import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PopupsService, PopupType } from './popups.service';
import { Subscription } from 'rxjs';
import { SynchronizationService } from '../../services/synchronization.service';
import { MatDialog } from '@angular/material/dialog';
import { SimNaoDialogComponent } from '../dialogs/sim-nao-dialog/sim-nao-dialog.component';
import { PreferenciasComponent } from './preferencias/preferencias.component';

@Component({
  selector: 'app-popups',
  templateUrl: './popups.component.html',
  styleUrls: ['./popups.component.scss']
})
export class PopupsComponent implements OnInit, OnDestroy {
  @ViewChild(PreferenciasComponent) popupPreferecias: PreferenciasComponent;

  private s_popupType: Subscription;
  private s_canClose: Subscription;
  private s_visible: Subscription;

  public popupType: PopupType;
  public canClose: boolean;
  public visible: boolean;

  public confirmarAlteracoes: boolean;

  constructor(
    private popupService: PopupsService,
    private syncService: SynchronizationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.s_popupType = this.popupService.popupType.subscribe(pType => {
      this.popupType = pType;
    });

    this.s_visible = this.popupService.visible.subscribe(v => {
      this.visible = v;
    });

    this.s_canClose = this.popupService.canClose.subscribe(cClose => {
      this.canClose = cClose;
    });
  }

  ngOnDestroy(): void {
    this.s_popupType.unsubscribe();
    this.s_canClose.unsubscribe();
    this.s_visible.unsubscribe();
  }

  public confirmarAlteracoes_changed(c: boolean): void {
    this.confirmarAlteracoes = c;
  }

  public tryClose(evt: Event): void {
    const finalizar = () => {
      this.popupService.fecharPopups();
    };

    evt.stopPropagation();

    if (this.confirmarAlteracoes) {
      const dialogRef = this.dialog.open(SimNaoDialogComponent, { width: '250px', data: 'Você possui alterações que não foram salvas. Deseja salvar?' });
      dialogRef.afterClosed().subscribe(result => {
        switch (this.popupType) {
          case PopupType.Preferencias:
            this.popupPreferecias.tentarFechar(result).then(() => {
              finalizar();
            });
            break;
          default:
            finalizar();
            break;
        }
      })
    } else {
      finalizar();
    }
  }
}
