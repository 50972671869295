import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DadosPessoaisServidorModel } from '../perfil.component';
import { AppConfig } from '../../../../app.config';
import { ErrorHandlerService } from '../../../../services/errorHandler.service';
import { ErrorLogParameters } from '../../../../models/error/errorLogParameters';

@Component({
  selector: 'app-form-cancelamento',
  templateUrl: './form-cancelamento.component.html',
  styleUrls: ['./form-cancelamento.component.scss']
})
export class FormCancelamentoComponent implements OnInit {

  private headers = new HttpHeaders({
    'content-type': 'application/json'
  });

  @Output() public onsaving = new EventEmitter();
  @Output() public onsaved = new EventEmitter<string>();

  @Input() public dadosServidor: DadosPessoaisServidorModel;
  public model: FormCancelamentoModel;

  constructor(
    private httpClient: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  ngOnInit() {
    this.carregarModel();
  }

  public carregarModel(): void {
    this.model = null;

    const carregar = (): Promise<FormCancelamentoModel> => {
      return new Promise((onSuccess, onError) => {
        const model = new FormCancelamentoModel();

        model.dataProximoPagamento = this.dadosServidor.dadosPessoais.dataProximaCobranca;
        model.assinaturaCancelada = this.dadosServidor.dadosPessoais.planoCancelado;

        onSuccess(model);
      });
    };

    carregar().then(model => this.model = model);
  }

  public enviarMensagemSuporte(): void {
    this.onsaving.emit();

    this.httpClient.post(AppConfig.apiEndpoint + '/suporte/enviar-mensagem', `"${this.model.mensagemSuporte}"`, { headers: this.headers }).toPromise().then(res => {
      this.carregarModel();
      this.onsaved.emit('Sua mensagem foi enviada com sucesso!\nRetornaremos em breve, obrigado pelo contato!');
    }).catch(err => {
      this.onsaved.emit('Houve um erro ao tentar enviar sua mensagem, tente novamente mais tarde');

      let params = new Array<ErrorLogParameters>();
      params.push({name: "mensagem", value: this.model.mensagemSuporte});
      this.errorHandlerService.handleError(err, "erro em form-cancelamento.enviarMensagemSuporte", params);
    });
  }

  public solicitarCancelamento(): void {
    this.onsaving.emit();

    this.httpClient.post(AppConfig.apiEndpoint + '/auth/cancelar-assinatura', null, { headers: this.headers }).toPromise().then(res => {
      this.carregarModel();
      this.onsaved.emit('A renovação da sua assinatura foi cancelada com sucesso');
    }).catch(err => {
      this.onsaved.emit('Houve um erro ao tentar cancelar a sua assinatura, tente novamente mais tarde');
      throw err;
    });
  }

  public reativarAssinatura(): void {
    this.onsaving.emit();

    this.httpClient.post(AppConfig.apiEndpoint + '/auth/reativar-assinatura', null, { headers: this.headers }).toPromise().then(res => {
      this.carregarModel();
      this.onsaved.emit('A sua assinatura foi reativada com sucesso, obrigado!');
    }).catch(err => {
      this.onsaved.emit('Houve um erro ao tentar reativar a sua assinatura, tente novamente mais tarde');
      throw err;
    });

  }
}

export class FormCancelamentoModel {
  public mensagemSuporte: string;
  public flagCancelarAssinatura: boolean;
  public dataProximoPagamento: Date;
  public assinaturaCancelada: boolean;
}