import { Component, OnInit } from '@angular/core';
import { PerfilUsuario } from '../../../models/UserData';
import { UsuarioPreferenciasService } from '../../../services/data-services/usuario.preferencias.service';

export const ListaOpcoesModoUso: OpcaoModoUso[] = [
  { descricao: 'Estudante', tipo: PerfilUsuario.Estudante, dica: '' },
  { descricao: 'Concurseiro', tipo: PerfilUsuario.Concurseiro, dica: '' },
  { descricao: 'Advogado', tipo: PerfilUsuario.Advogado, dica: '' }
]

@Component({
  selector: 'app-primeiro-login',
  templateUrl: './primeiro-login.component.html',
  styleUrls: [
    '../popups-styles.scss',
    './primeiro-login.component.scss']
})
export class PrimeiroLoginComponent implements OnInit {
  public opcoesModoUso = ListaOpcoesModoUso;
  public opcaoSelecionada: OpcaoModoUso = null;
  public palavrasMinuto: number = 100;
  public mostrarMarcacoesProva: boolean = true;
  public mostrarComentarios: boolean = true;
  public mostrarMnemonicos: boolean = true;

  constructor(private usuarioPreferenciasService: UsuarioPreferenciasService) { }

  ngOnInit() {
  }

  public get mostrarBotaoAvancar(): boolean {
    return (this.opcaoSelecionada) ? true : false;
  }

  public async salvarPerfil(): Promise<void> {
    const prefs = this.usuarioPreferenciasService.Configuracoes;

    prefs.perfilUsuario = this.opcaoSelecionada.tipo;
    prefs.palavrasMinuto = this.palavrasMinuto;

    switch (this.opcaoSelecionada.tipo) {
      case PerfilUsuario.Estudante:
        prefs.parametrosCaneta.realcarMarcacoesProva = this.mostrarMarcacoesProva;
        prefs.parametrosCaneta.realcarComentarios = this.mostrarComentarios;
        prefs.parametrosCaneta.realcarMnemonicos = this.mostrarMnemonicos;
        break;
      case PerfilUsuario.Concurseiro:
        prefs.parametrosCaneta.realcarMarcacoesProva = this.mostrarMarcacoesProva;
        prefs.parametrosCaneta.realcarComentarios = this.mostrarComentarios;
        prefs.parametrosCaneta.realcarMnemonicos = this.mostrarMnemonicos;
        break;
      case PerfilUsuario.Advogado:
        break;
    }

    this.usuarioPreferenciasService.alterarConfiguracoes(prefs);
  }
}

export class OpcaoModoUso {
  descricao: string;
  tipo: PerfilUsuario;
  dica: string;
}