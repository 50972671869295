import { Component, OnInit ,Inject } from '@angular/core';
import { ListArticleService } from "./list-article.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-list-articles',
  templateUrl: './list-articles.component.html',
  styleUrls: ['./list-articles.component.scss']
})
export class ListArticlesComponent implements OnInit {
 public  list:any;
 public selectedId:any;
 public isSelected=true;
 public loading = true;
 public totalWordCount = 0;
 public totalLineCount = 0;
 public totalMarcaLinhas = 0;
 public tipoDocumento = 0;
 public PlanodeeStudoLeiItens =[];
 public subtitleIDs =[];
 public returnArray = {"totalWordCount" : this.totalWordCount, "PlanodeeStudoLeiItens": this.PlanodeeStudoLeiItens, "totalLineCount" : this.totalLineCount, 'totalMarcaLinhas' : this.totalMarcaLinhas, 'docType' : this.tipoDocumento}


  constructor(public dialogRef: MatDialogRef<ListArticleService>, private service:ListArticleService,
    @Inject(MAT_DIALOG_DATA) public data: [])
    {
       this.selectedId = data;
    }
  ngOnInit(): void {
    var selected_leiItens = [];
    this.list= this.service.getlist(this.selectedId).then(
      listData => {
        var total_sub_parts = 0;
        console.log(listData);
        if(listData.leiItens.length > 0) {
          listData.leiItens.forEach((ele, index) => {
            listData.leiItens[index]['checked'] = true;
            selected_leiItens.push({"IdLeiItens" : ele.id, 'TotaisPalavras' : ele.totaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.prefixo, 'artigoAte': ele.prefixo, 'totalMarcaLinhas' : ele.totalMarcaLinhas, "itemParentId" : ele.itemParentId});
            total_sub_parts = total_sub_parts + ele.totalLinhas;
            if(ele.leiItens.length > 0) {
              ele.leiItens.forEach((ele1, index1) => {
                listData.leiItens[index]['leiItens'][index1]['checked'] = true;
                selected_leiItens.push({"IdLeiItens" : ele1.id, 'TotaisPalavras' : ele1.totaisPalavras, 'totalLinhas' : ele1.totalLinhas, 'artigoDe': ele1.prefixo, 'artigoAte': ele1.prefixo, 'totalMarcaLinhas' : ele1.totalMarcaLinhas, "itemParentId" : ele1.itemParentId});                
                if(ele1.leiItens.length > 0) {
                  ele1.leiItens.forEach((ele2, index2) => {
                    listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked'] = true;
                    selected_leiItens.push({"IdLeiItens" : ele2.id, 'TotaisPalavras' : ele2.totaisPalavras, 'totalLinhas' : ele2.totalLinhas, 'artigoDe': ele2.prefixo, 'artigoAte': ele2.prefixo, 'totalMarcaLinhas' : ele2.totalMarcaLinhas, "itemParentId" : ele2.itemParentId});
                    
                  });
                }
              });
            }
          });
        }
        this.totalWordCount = listData.quantidadePalavras;
        // this.totalLineCount = listData.totalLinhas;
        this.totalLineCount = total_sub_parts;
        this.totalMarcaLinhas = listData.totalMarcaLinhas;
        this.PlanodeeStudoLeiItens = selected_leiItens;
        this.subtitleIDs = selected_leiItens;        
        this.list = listData;
        this.tipoDocumento = this.list.tipoDocumento;
        this.returnArray = {"totalWordCount" : this.totalWordCount, "PlanodeeStudoLeiItens": this.PlanodeeStudoLeiItens, "totalLineCount" : this.totalLineCount, 'totalMarcaLinhas' : this.totalMarcaLinhas, 'docType' : this.tipoDocumento}
        this.loading = false;
      },
       err => console.log(err),
    );
  }

  checkedEvnt(e) {
    var selected_leiItens = [];
    var listData = this.list;
    if(e.target.checked == true){      
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
    if(listData.leiItens.length > 0) {
      listData.leiItens.forEach((ele, index) => {
        listData.leiItens[index]['checked'] = this.isSelected;
        if(this.isSelected) {
          selected_leiItens.push({"IdLeiItens" : ele.id, 'TotaisPalavras' : ele.totaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.artigoDe, 'artigoAte': ele.artigoAte, 'totalMarcaLinhas' : ele.totalMarcaLinhas, "itemParentId" : ele.itemParentId});
        }
        if(ele.leiItens.length > 0) {
          ele.leiItens.forEach((ele1, index1) => {
            listData.leiItens[index]['leiItens'][index1]['checked'] = this.isSelected;
            if(this.isSelected) {
              selected_leiItens.push({"IdLeiItens" : ele1.id, 'TotaisPalavras' : ele1.totaisPalavras, 'totalLinhas' : ele1.totalLinhas, 'artigoDe': ele1.artigoDe, 'artigoAte': ele1.artigoAte, 'totalMarcaLinhas' : ele1.totalMarcaLinhas, "itemParentId" : ele1.itemParentId});
            }
            if(ele1.leiItens.length > 0) {
              ele1.leiItens.forEach((ele2, index2) => {
                listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked'] = this.isSelected;
                if(this.isSelected) {
                  selected_leiItens.push({"IdLeiItens" : ele2.id, 'TotaisPalavras' : ele2.totaisPalavras, 'totalLinhas' : ele2.totalLinhas, 'artigoDe': ele2.artigoDe, 'artigoAte': ele2.artigoAte, 'totalMarcaLinhas' : ele2.totalMarcaLinhas, "itemParentId" : ele2.itemParentId});
                }
              });
            }
          });
        }
      });
    }
    this.totalWordCount = listData.quantidadePalavras;
    this.totalLineCount = listData.totalLinhas;
    this.totalMarcaLinhas = listData.totalMarcaLinhas;
    this.PlanodeeStudoLeiItens = selected_leiItens;
    this.subtitleIDs = selected_leiItens;
    this.list = listData;
    this.returnArray = {"totalWordCount" : this.totalWordCount, "PlanodeeStudoLeiItens": this.PlanodeeStudoLeiItens, "totalLineCount" : this.totalLineCount, 'totalMarcaLinhas' : this.totalMarcaLinhas, 'docType' : this.tipoDocumento}
  }
  onChange(checked, item, parent_id, main_parent_id){
    var total_words = this.totalWordCount;
    var total_lines = this.totalLineCount;    
    var totalMarcaLinhas = this.totalMarcaLinhas;
    var selected_records = this.subtitleIDs;
    var PlanodeeStudoLeiItens = [];
    if(checked){
       selected_records.forEach((ele, index) => {
          if(ele.IdLeiItens === item || ele.IdLeiItens === parent_id && ele.IdLeiItens === main_parent_id) {
            if(ele.itemParentId != null) {
              total_words = total_words + ele.TotaisPalavras;
              total_lines = total_lines + ele.totalLinhas;
              totalMarcaLinhas = totalMarcaLinhas + ele.totalMarcaLinhas;
            }
            // PlanodeeStudoLeiItens.push({"IdLeiItens": ele.IdLeiItens, 'TotaisPalavras' : ele.TotaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.artigoDe, 'artigoAte': ele.artigoAte});
          }
       });
    } else {
      var i_cnt;
      var temp = [];
      var i = 0;   
      selected_records.forEach((ele, index) => {
          if(ele.IdLeiItens === item) {
            i_cnt = index;            
            if(ele.itemParentId != null) {
              total_words = total_words - ele.TotaisPalavras;
              total_lines = total_lines - ele.totalLinhas;
              totalMarcaLinhas = totalMarcaLinhas - ele.totalMarcaLinhas;
            }
          } else {
            /* if(ele.itemParentId != null) {
              total_words = total_words + ele.TotaisPalavras;
              total_lines = total_lines + ele.totalLinhas;
              totalMarcaLinhas = totalMarcaLinhas - ele.totalMarcaLinhas;
            } */
          }
      });
      // PlanodeeStudoLeiItens.forEach((ele, index) => {
      //     if(ele.IdLeiItens !== item && ele.IdLeiItens !== parent_id && ele.IdLeiItens !== main_parent_id) {
      //       temp[i] = ele;
      //       i++;
      //     }
      // });
      // PlanodeeStudoLeiItens = temp;
    }
    var listData = this.list;
    var all_flag_checked = 0;;
    if(listData.leiItens.length > 0) {
      listData.leiItens.forEach((ele, index) => {
        var sec_child = false;        
        if(ele.leiItens.length > 0) {
          ele.leiItens.forEach((ele1, index1) => {
            var third_child = false;
            if(ele1.leiItens.length > 0) {
              ele1.leiItens.forEach((ele2, index2) => {
                if(ele2.id == item || ele2.id == parent_id || ele2.id === main_parent_id  || ele1.itemParentId === item || ele2.itemParentId === item) {
                  if(checked) {
                    listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked'] = true;                    
                  } else {
                    listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked'] = false;
                    all_flag_checked = 1;
                  }
                }
                if(listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked']) {
                  third_child = true;
                }
              });
            }
            if(ele1.id == item || ele1.id == parent_id || ele1.id === main_parent_id || ele1.itemParentId === item) {
              if(checked) {
                listData.leiItens[index]['leiItens'][index1]['checked'] = true;
              } else {
                if(third_child) {
                  listData.leiItens[index]['leiItens'][index1]['checked'] = true;
                } else {
                  listData.leiItens[index]['leiItens'][index1]['checked'] = false;
                  all_flag_checked = 1;
                }
                
              }
            }
            if(listData.leiItens[index]['leiItens'][index1]['checked']) {
              sec_child = true;
            }
          });
        }
        if(ele.id == item || ele.id == parent_id || ele.id == main_parent_id) {
          if(checked) {
            listData.leiItens[index]['checked'] = true;            
          } else {
            if(sec_child) {
              listData.leiItens[index]['checked'] = true;            
            } else {
              listData.leiItens[index]['checked'] = false;
              all_flag_checked = 1;
            }
          }
        }
      });
      console.log(listData);
      listData.leiItens.forEach((ele, index) => {
        if(listData.leiItens[index]['checked']) {
          PlanodeeStudoLeiItens.push({"IdLeiItens" : ele.id, 'TotaisPalavras' : ele.totaisPalavras, 'totalLinhas' : ele.totalLinhas, 'artigoDe': ele.prefixo, 'artigoAte': ele.prefixo, 'totalMarcaLinhas' : ele.totalMarcaLinhas, "itemParentId" : ele.itemParentId});
        } else {
          all_flag_checked = 1;
        }

        if(ele.leiItens.length > 0) {
          ele.leiItens.forEach((ele1, index1) => {
            if(listData.leiItens[index]['leiItens'][index1]['checked']) {
              PlanodeeStudoLeiItens.push({"IdLeiItens" : ele1.id, 'TotaisPalavras' : ele1.totaisPalavras, 'totalLinhas' : ele1.totalLinhas, 'artigoDe': ele1.prefixo, 'artigoAte': ele1.prefixo, 'totalMarcaLinhas' : ele1.totalMarcaLinhas, "itemParentId" : ele1.itemParentId});
            } else {
              all_flag_checked = 1;
            }
            if(ele1.leiItens.length > 0) {
              ele1.leiItens.forEach((ele2, index2) => {
                if(listData.leiItens[index]['leiItens'][index1]['leiItens'][index2]['checked']) {
                  PlanodeeStudoLeiItens.push({"IdLeiItens" : ele2.id, 'TotaisPalavras' : ele2.totaisPalavras, 'totalLinhas' : ele2.totalLinhas, 'artigoDe': ele2.prefixo, 'artigoAte': ele2.prefixo, 'totalMarcaLinhas' : ele2.totalMarcaLinhas, "itemParentId" : ele2.itemParentId});
                } else {
                  all_flag_checked = 1;
                }
              });
            }
          });
        }
      });
    } 
    if(all_flag_checked == 1) {
      this.isSelected = false;
    } else {
      this.isSelected = true;
    }
    this.list = listData;
    this.totalWordCount = total_words;
    this.totalLineCount = total_lines;
    this.totalMarcaLinhas = totalMarcaLinhas;
    this.PlanodeeStudoLeiItens = PlanodeeStudoLeiItens;
    this.returnArray = {"totalWordCount" : this.totalWordCount, "PlanodeeStudoLeiItens": this.PlanodeeStudoLeiItens, "totalLineCount" : this.totalLineCount, 'totalMarcaLinhas' : this.totalMarcaLinhas, 'docType' : this.tipoDocumento}
  }
  CloseDialog() {
    this.dialogRef.close(this.returnArray);
  }
}
