import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-btn-toolbar',
  templateUrl: './btn-toolbar.component.html',
  styleUrls: ['./btn-toolbar.component.css']
})
export class BtnToolbarComponent implements OnInit {
  @Input()
  public texto: string;

  @Input()
  public icone: string;

  @Input()
  public cor: string;

  @Input()
  public ativo: boolean;

  constructor() { }

  ngOnInit() {
  }

  public get backgroundBotao(): string {
    return (this.ativo) ? this.cor : '#ffffff';
  }

  public get foregroundBotao(): string {
    return (this.ativo) ? '#ffffff' : this.cor;
  }

  public get backgroundLegenda(): string {
    return (this.ativo) ? '#ffffff' : this.cor;
  }

  public get foregroundLegenda(): string {
    return (this.ativo) ? this.cor : '#ffffff';
  }
}
