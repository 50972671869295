export class LeiDownload {
    id: string
    descricao: string
    DataHoraUltimaModificacao: Date
    baixar: boolean
    atualizar: boolean
    concluido: boolean
    baixando: boolean

    static novo(id, descricao, dataHoraUltimaModificacao, baixar): LeiDownload {
        const ret = new LeiDownload()

        ret.id = id
        ret.descricao = descricao
        ret.DataHoraUltimaModificacao = dataHoraUltimaModificacao
        ret.baixar = baixar

        return ret
    }
}