import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { Tema } from '../models/tema';
import { TemasApp } from '../constants/lista.temas';
import { UsuarioPreferenciasService } from './data-services/usuario.preferencias.service';

@Injectable()
export class UiService {
    public tema: Observable<Tema>;
    private _tema = new BehaviorSubject<Tema>(null);

    public larguraTela: Observable<number>;
    private _larguraTela = new BehaviorSubject<number>(this.obterLarguraTela());

    public alturaTela: Observable<number>;
    private _alturaTela = new BehaviorSubject<number>(this.obterAlturaTela());

    private s_prefs: Subscription;
    private idTema: number;

    constructor(
        private usuarioPreferenciasService: UsuarioPreferenciasService
    ) {
        this.larguraTela = this._larguraTela.asObservable();
        this.alturaTela = this._alturaTela.asObservable();
        this.tema = this._tema.asObservable();

        window.onresize = () => {
            this._larguraTela.next(this.obterLarguraTela());
            this._alturaTela.next(this.obterAlturaTela());
        };

        this.usuarioPreferenciasService.$Configuracoes.subscribe(config => {
            if (config && this.idTema !== config.idTema) {
                this.idTema = config.idTema;
                this.carregarTema();
            }
        });
    }

    private carregarTema(): void {
        this._tema.next(TemasApp[this.idTema]);
    }

    public AlterarTema(tema: Tema): void {
        // this.idTema = TemasApp.indexOf(tema);

        // let config = <ConfiguracoesUsuario>EntitiesHelper.Copy(this.usuarioPreferenciasService.Configuracoes);
        // config.idTema = this.idTema;

        // this.usuarioPreferenciasService.Configuracoes = config;
        this._tema.next(tema);
    }

    private obterLarguraTela(): number {
        return window.innerWidth;
    }

    private obterAlturaTela(): number {
        return window.innerHeight;
    }
}
