import { LeiStorage } from "./lei.storage"
import { EstatisticasLeitura } from "../usuario/EstatisticasLeitura"

export class LeiLookup {
    id: string
    titulo: string
    dataHoraUltimaModificacao: Date
    dataHoraUltimaModificacaoOficial: Date
    tipoDocumento: number;
    quantidadeItens: number
    quantidadePalavras: number
    baixada: boolean
    favorita: boolean
    progresso: number
    estatisticas: EstatisticasLeitura

    static fromLeiStorage(l: LeiStorage): LeiLookup {
        return {
            id: l.id,
            titulo: l.descricao,
            dataHoraUltimaModificacao: l.dataHoraUltimaModificacaoTextoLei,
            dataHoraUltimaModificacaoOficial: l.dataHoraUltimaModificacaoOficial,
            tipoDocumento: l.tipoDocumento,
            quantidadeItens: l.quantidadeItens,
            quantidadePalavras: l.quantidadePalavras,
            baixada: l.disponivel,
            favorita: false,
            progresso: null,
            estatisticas: null
        }
    }
}