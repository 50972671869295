export const environment = {
  production: true,
  serverUrl: 'https://dev-api.supervademecum.com.br',
  serverLogUrl: 'https://dev-log-api.supervademecum.com.br/api',

  firebase: {
    apiKey: "AIzaSyDMUbdrS1I2LJqOVemXn1J9iIhabjzOvbQ",
    authDomain: "super-vade-mecum-hml.firebaseapp.com",
    databaseURL: "https://super-vade-mecum-hml.firebaseio.com",
    projectId: "super-vade-mecum-hml",
    storageBucket: "super-vade-mecum-hml.appspot.com",
    messagingSenderId: "685396639133",
    appId: "1:685396639133:web:9d061a876dde582d7ac374",
    measurementId: "G-LNPZQ74XNX"
  }
};
